import {
  KontosKey,
  KontosKeyHelper,
  StorageKontosKey,
} from "@zkkontos/kontos-sdk";
import { KEYS } from "./keys";
import { set, get, remove, getV2 } from "./local";
import { FtAsset } from "src/type/zkkontos";
import { isSameKontosName, isSameName } from "src/utils/zkkontosHelper";
import { FtAssetType } from "@zkkontos/kontos-sdk/src/api/types";
import {
  DappHistoryItem,
  DappHistoryItemMetaData,
  DappHistoryItemVerifyContext,
  LocalDappHistory,
} from "src/type/dapp";
import { RecordOverviewDisplayProps } from "src/components/task-activity/TaskOrActivityOverviewItem";
import { millisecondsInAMonth } from "src/config";
import {
  isSameOrigin,
  isValidOrigin,
  normalizeKontosName,
  normalizeOrigin,
} from "src/utils/helper";
import { ReqDepositCallback } from "@/apis/event-bybit-api";

export const getStorageKontosValList = (): StorageKontosKey[] => {
  const data = get(KEYS.store_kontos_key) || [];
  return data;
};

export enum OnboardingType {
  Home = "home",
  Eb = "eb",
  Assets = "assets",
  Payment = "payment",
  AiScore = "aiScore",
  EbLeaderBoardRanking = "ebLeaderBoardRanking",
  Sell = "sell",
}

export interface OnboardingItem {
  type: OnboardingType;
  finished: boolean;
}

export type LocalRecordType = "task" | "activity";

export interface LocalRecord {
  accountName: string;
  record: RecordOverviewDisplayProps;
  expiredAt: number;
  type: LocalRecordType;
}

export type LocalConnectionDetail = {
  origin: string;
  createdAt: number; // Unix timestamp in milliseconds
  updatedAt: number; // Unix timestamp in milliseconds
};

export type LocalConnectionKey = string;

export type LocalConnection = {
  account: LocalConnectionKey;
  detail: LocalConnectionDetail;
};

export type LocalConnections = Record<
  LocalConnectionKey,
  LocalConnectionDetail[]
>;

export const isLocalConnection = (value: any): value is LocalConnections => {
  if (typeof value !== "object" || value === null) {
    return false;
  }

  return Object.values(value).every(
    (details) =>
      Array.isArray(details) &&
      details.every(
        (detail) =>
          typeof detail === "object" &&
          detail !== null &&
          typeof detail.origin === "string" &&
          typeof detail.timestamp === "number"
      )
  );
};

class LocalKeeper {
  constructor() {
    this.formatKontosKeys();
  }

  formatKontosKeys = () => {
    const storedKeys = get(KEYS.store_kontos_key);
    const keys: StorageKontosKey[] = Array.isArray(storedKeys)
      ? storedKeys
      : [];
    keys.forEach(
      (key) => (key.accountName = normalizeKontosName(key.accountName))
    );
    set(KEYS.store_kontos_key, keys);
  };

  updateKontosKey = async (key: KontosKey) => {
    key.accountName = normalizeKontosName(key.accountName);
    const storageKey = await KontosKeyHelper.toStorageKontosKey(key);
    const storeKontosVal = getStorageKontosValList();
    const marchIndex = storeKontosVal.findIndex(
      (item: StorageKontosKey) => item.accountName === storageKey.accountName
    );
    if (marchIndex !== -1) {
      storeKontosVal[marchIndex] = storageKey;
    } else {
      storeKontosVal.push(storageKey);
      set(KEYS.store_account_selected_index, storeKontosVal.length - 1);
    }
    set(KEYS.store_kontos_key, storeKontosVal);
  };

  updateAccountsRecovering = (arr: string[]) => {
    return set(KEYS.store_accounts_recovering, arr);
  };

  getAccountsRecovering = (): string[] => {
    const data = get(KEYS.store_accounts_recovering);
    return Array.isArray(data) ? data : [];
  };

  resetAccount = () => {
    Object.values(KEYS).map((item) => {
      return remove(item);
    });
  };

  getStorageKontosKeyWithCheck = (): StorageKontosKey | undefined => {
    let res: StorageKontosKey | undefined = undefined;

    const data = get(KEYS.store_kontos_key) as StorageKontosKey[];
    if (!data || data.length === 0) {
      return undefined;
    }

    let store_account_selected_index =
      typeof get(KEYS.store_account_selected_index) === "number"
        ? get(KEYS.store_account_selected_index)
        : 0;

    if (store_account_selected_index > data.length - 1) {
      set(KEYS.store_account_selected_index, 0);
      store_account_selected_index = 0;
    }
    if (store_account_selected_index < 0) {
      set(KEYS.store_account_selected_index, 0);
      store_account_selected_index = 0;
    }

    const selectedKontosKey = data[
      store_account_selected_index
    ] as StorageKontosKey;

    res = selectedKontosKey;

    const accountsRecovering = this.getAccountsRecovering();

    const recovering = accountsRecovering?.find((item) =>
      isSameKontosName(item, selectedKontosKey.accountName)
    );

    if (recovering) {
      const canBeUsedKontosKeys = data.filter((item) => {
        let flag = true;
        accountsRecovering?.forEach((subItem) => {
          if (isSameKontosName(subItem, item.accountName)) {
            flag = false;
          }
        });
        return flag;
      });
      if (canBeUsedKontosKeys?.length > 0) {
        set(KEYS.store_account_selected_index, 0);
        res = canBeUsedKontosKeys[0];
      }
    }

    return res;
  };

  getStorageKontosKey = (
    accountName?: string
  ): StorageKontosKey | undefined => {
    try {
      const data = Array.isArray(get(KEYS.store_kontos_key))
        ? (get(KEYS.store_kontos_key) as StorageKontosKey[])
        : [];

      if (data.length === 0) {
        return undefined;
      }

      if (typeof accountName === "string") {
        return data.find((item) =>
          isSameKontosName(item.accountName, accountName)
        );
      }

      let store_account_selected_index =
        get(KEYS.store_account_selected_index) || 0;

      store_account_selected_index = Math.min(
        store_account_selected_index,
        data.length - 1
      );

      return data[store_account_selected_index] as StorageKontosKey;
    } catch {
      return undefined;
    }
  };

  getAllStorageKontosKey = (): StorageKontosKey[] => {
    const data = get(KEYS.store_kontos_key) || [];
    return Array.isArray(data) ? data : [];
  };

  getAllAvailableStorageKeys = (): StorageKontosKey[] => {
    const recoverings = this.getAccountsRecovering();
    const keys = this.getAllStorageKontosKey();
    return keys.filter(
      (key) =>
        !recoverings.find((item) => isSameKontosName(item, key.accountName))
    );
  };

  getIndex = (): number | undefined => {
    return (get(KEYS.store_account_selected_index) as number) || undefined;
  };

  switchAccount = (index: number) => {
    set(KEYS.store_account_selected_index, index);
  };

  removeAccount = (name: string) => {
    const arr = (get(KEYS.store_kontos_key) as StorageKontosKey[]) || [];
    const currentIndex =
      (get(KEYS.store_account_selected_index) as number) || 0;
    if (arr) {
      const index = arr.findIndex((item) =>
        isSameKontosName(item.accountName, name)
      );
      const filteredArr = arr.filter(
        (item) => !isSameKontosName(item.accountName, name)
      );
      set(KEYS.store_kontos_key, filteredArr);
      if (currentIndex > index && currentIndex > 0 && index !== -1) {
        set(KEYS.store_account_selected_index, currentIndex - 1);
      } else if (currentIndex === index) {
        set(KEYS.store_account_selected_index, 0);
      }
    }
  };

  removeRecoveringAccount = (name: string) => {
    const arr = (get(KEYS.store_accounts_recovering) as string[]) || [];
    const newArr = arr.filter((item) => !isSameKontosName(item, name));
    set(KEYS.store_accounts_recovering, newArr);
  };

  updateUnlockAmount = (amount: number) => {
    return set(KEYS.store_unlock_amount, amount);
  };

  getUnlockAmount = (): number => {
    return (get(KEYS.store_unlock_amount) || 0) as number;
  };

  updateFtAssets = (assets: FtAsset[]) => {
    const data = {
      timestamp: new Date().getTime(),
      assets: assets,
    };
    return set(KEYS.store_ft_assets, data);
  };

  getFtAssets = (): {
    ftAssets: FtAsset[];
    timestamp: number;
  } => {
    const data = get(KEYS.store_ft_assets);
    if (data) {
      return {
        ftAssets: data.assets as FtAsset[],
        timestamp: data.timestamp as number,
      };
    }
    return {
      ftAssets: [],
      timestamp: 1670384199826,
    };
  };

  clearFtAssets = () => {
    return remove(KEYS.store_ft_assets);
  };

  updateAccountTotalUSDBalance = async (data: string | undefined) => {
    set(KEYS.store_account_total_USD_balance, data);
  };

  getAccountTotalUSDBalance = (): string | undefined => {
    const data = get(KEYS.store_account_total_USD_balance);
    if (!data) {
      return undefined;
    }
    return data as string;
  };

  updateAggregatedAssetList = async (data: FtAssetType[]) => {
    set(KEYS.store_aggregated_asset_list, data);
  };

  getLang = (): string => {
    return get(KEYS.store_lang) as string;
  };

  updateLang = (lang: string) => {
    set(KEYS.store_lang, lang);
  };

  getDappHistory = (account: string): DappHistoryItem[] => {
    const dappHistory =
      (get(KEYS.store_dapp_history) as LocalDappHistory) || {};
    const matchedAccount = Object.keys(dappHistory).find((storageAccount) =>
      isSameKontosName(storageAccount, account)
    );
    return matchedAccount ? dappHistory[matchedAccount].history : [];
  };

  addDappHistory = (
    account: string,
    metadata: DappHistoryItemMetaData,
    verifyContext: DappHistoryItemVerifyContext
  ) => {
    const newHistoryItem: DappHistoryItem = {
      metadata,
      verifyContext,
      timestamp: Date.now(),
    };
    const dappHistory =
      (get(KEYS.store_dapp_history) as LocalDappHistory) || {};
    if (!(account in dappHistory)) {
      dappHistory[account] = { history: [newHistoryItem] };
    } else {
      const existedItem = dappHistory[account].history.find(
        (item) => item.metadata.name === newHistoryItem.metadata.name
      );
      if (!existedItem) {
        dappHistory[account].history.push(newHistoryItem);
      }
    }
    set(KEYS.store_dapp_history, dappHistory);
  };

  removeDappHistory = (account: string, timestamp: number) => {
    const dappHistory =
      (get(KEYS.store_dapp_history) as LocalDappHistory) || {};
    if (account in dappHistory) {
      const filteredHistory = dappHistory[account].history.filter(
        (item) => item.timestamp !== timestamp
      );
      dappHistory[account].history = filteredHistory;
      set(KEYS.store_dapp_history, dappHistory);
    }
  };

  clearDappHistory = (account: string) => {
    const dappHistory =
      (get(KEYS.store_dapp_history) as LocalDappHistory) || {};
    if (account in dappHistory) {
      dappHistory[account].history = [];
      set(KEYS.store_dapp_history, dappHistory);
    }
  };

  setWalletConnectUser = (account: string) => {
    set(KEYS.store_wc_user, account);
  };

  getWalletConnectUser = (): string => {
    return get(KEYS.store_wc_user) || "";
  };

  startOnboarding = () => {
    const existedItems = this.getOnboardingStatus();
    const onboardings: OnboardingItem[] = Object.values(OnboardingType).map(
      (value) => {
        return { type: value, finished: false };
      }
    );

    const newItems = onboardings.filter(
      (item) =>
        !existedItems.some((existedItem) => existedItem.type === item.type)
    );

    const updatedItems = [...existedItems, ...newItems];
    set(KEYS.store_onboarding, updatedItems);
  };

  markOnboardingFinished = (type: OnboardingType) => {
    const arr: OnboardingItem[] = get(KEYS.store_onboarding) || [];
    if (arr.length === 0) {
      return;
    }
    const matchedOne = arr.find((item) => item.type === type);
    if (matchedOne) {
      matchedOne.finished = true;
    }
    set(KEYS.store_onboarding, arr);
  };

  getOnboardingStatus = (): OnboardingItem[] => {
    return get(KEYS.store_onboarding) || [];
  };

  // Local tasks
  checkLocalRecords = () => {
    const records = this.getLocalRecords();
    const filtered = records.filter((record) => record.expiredAt > Date.now());
    set(KEYS.store_tasks_and_txs, filtered);
  };

  removeLocalRecord = (ids: string[]) => {
    const records = this.getLocalRecords();
    const filtered = records.filter(
      (record) => !ids.includes(record.record.id)
    );
    set(KEYS.store_tasks_and_txs, filtered);
    return filtered;
  };

  saveRecord = (
    accountName: string,
    record: RecordOverviewDisplayProps,
    type: LocalRecordType
  ) => {
    const records = this.getLocalRecords();
    const saveObj: LocalRecord = {
      accountName,
      record,
      expiredAt: Date.now() + millisecondsInAMonth,
      type,
    };
    const existed = records.find(
      (record) => record.record.id === saveObj.record.id
    );
    if (!existed) {
      records.push(saveObj);
    }
    set(KEYS.store_tasks_and_txs, records);
  };

  getLocalRecords = (
    accountName?: string,
    type?: LocalRecordType
  ): LocalRecord[] => {
    const records = (get(KEYS.store_tasks_and_txs) || []) as LocalRecord[];
    return records.filter(
      (item) =>
        (!accountName || isSameName(item.accountName, accountName)) &&
        (!type || item.type === type)
    );
  };

  getDisableAiScore = () => {
    return true;
    // const disable = get(KEYS.store_disable_ai_score);
    // return disable as boolean;
  };

  setDisableAiScore = (disable: boolean) => {
    set(KEYS.store_disable_ai_score, disable);
  };

  getBybitTaskDepositCallbackParams = (): ReqDepositCallback[] => {
    try {
      const data = get(KEYS.store_bybit_task_deposit_callback_params);
      if (Array.isArray(data)) {
        return data as ReqDepositCallback[];
      }
      return [];
    } catch (e) {
      console.warn(e);
      return [];
    }
  };

  addBybitTaskDepositCallbackParams = (req: ReqDepositCallback) => {
    if (!req || !req.txHash) {
      console.warn("Invalid req object");
      return;
    }
    const data = this.getBybitTaskDepositCallbackParams();
    const newData = [
      ...data.filter(
        (item) => item?.txHash?.toLowerCase() !== req.txHash.toLowerCase()
      ),
      req,
    ];
    set(KEYS.store_bybit_task_deposit_callback_params, newData);
  };

  removeBybitTaskDepositCallbackParams = (txHash: string) => {
    if (!txHash) {
      console.warn("Invalid txHash");
      return;
    }
    const data = this.getBybitTaskDepositCallbackParams();
    if (!data) return;
    const newData = data.filter(
      (item) => item?.txHash?.toLowerCase() !== txHash.toLowerCase()
    );
    set(KEYS.store_bybit_task_deposit_callback_params, newData);
  };

  getConnections = (): LocalConnections | null => {
    const connections = getV2<LocalConnections>(KEYS.store_kontos_connections);
    return isLocalConnection(connections) ? connections : null;
  };

  setConnections = (connections: LocalConnections) => {
    if (!isLocalConnection(connections)) return;
    set(KEYS.store_kontos_connections, connections);
  };

  findConnection = (
    account: string,
    origin: string
  ): LocalConnection | null => {
    const connections = this.getConnections();
    if (!connections) return null;

    if (account in Object.keys(connections)) {
      const detail = connections[account].find((item) =>
        isSameOrigin(item.origin, origin)
      );
      if (detail !== undefined) {
        return {
          account,
          detail,
        };
      }
    }

    return null;
  };

  findConnectionIndex = (account: string, origin: string): number => {
    const connections = this.getConnections();
    if (!connections) return -1;

    if (account in Object.keys(connections)) {
      const index = connections[account].findIndex((item) =>
        isSameOrigin(item.origin, origin)
      );
      return index;
    }

    return -1;
  };

  findFirstConnectionByOrigin = (origin: string): LocalConnection | null => {
    const connections = this.getConnections();
    if (!connections) return null;

    for (let account in Object.keys(connections)) {
      const detail = connections[account].find((item) =>
        isSameOrigin(item.origin, origin)
      );
      if (detail !== undefined) {
        return {
          account,
          detail,
        };
      }
    }

    return null;
  };

  saveConnection = (account: string, origin: string): void => {
    if (!isValidOrigin(origin)) {
      return;
    }

    const connections = this.getConnections() || {};

    const existingIndex = this.findConnectionIndex(account, origin);

    const connection = {
      origin: normalizeOrigin(origin),
      createdAt:
        existingIndex === -1
          ? Date.now()
          : connections[account]?.[existingIndex]?.createdAt,
      updatedAt: Date.now(),
    };

    if (existingIndex === -1) {
      connections[account] = [...(connections[account] || []), connection];
    } else {
      connections[account][existingIndex] = connection;
    }

    this.setConnections(connections);
  };
}

const localKeeper = new LocalKeeper();
export default localKeeper;
