import {
  IReactionDisposer,
  makeAutoObservable,
  reaction,
  runInAction,
} from "mobx";
import KontosNumber from "src/utils/KontosNumber";
import { RootStore } from "../RootStore";
import { Chain, FtAsset } from "@/type/zkkontos";
import { DEFAULT_DECIMAL, DEFAULT_SLIPPAGE } from "src/config";
import * as TradeService from "src/service/trade-service";
import { RespTaskPayment } from "@zkkontos/kontos-sdk/src/api/paymentApi";
import {
  ChainIndex,
  NonChainIndex,
} from "@/components/selects/HorizontalScrollableElements";
import { isSameFtAsset } from "@/utils/zkkontosHelper";
import { findUserHolding } from "../storeHelper";
import { t } from "i18next";

export class SellStore {
  rootStore: RootStore;
  toSellFtAsset?: FtAsset;
  toSellFtAssetQuantity?: KontosNumber;
  toReceiveFtAsset?: FtAsset;
  receiver?: string;
  taskData?: RespTaskPayment;
  slippage: KontosNumber = DEFAULT_SLIPPAGE;
  chainsReactionDisposer?: IReactionDisposer;
  customPlan: FtAsset[] = [];
  enableCustomPlan: boolean = false;

  // Only when custom plan is enabled
  get selectedBalances(): string[] {
    return this.enableCustomPlan
      ? this.customPlan.map((item) => item.balance!.id)
      : this.rootStore.tradeStore.userHoldings
          .filter((item) => !isSameFtAsset(item, this.toReceiveFtAsset))
          .map((item) => item.balance!.id);
  }

  get selectedBalancesForApiCall(): string[] {
    return this.enableCustomPlan
      ? this.customPlan.map((item) => item.balance!.id)
      : [];
  }

  get fixedBalanceIds(): string[] {
    const ids = [];
    if (this.toSellFtAsset !== undefined)
      ids.push(findUserHolding(this.toSellFtAsset, true)?.balance?.id);
    if (this.toReceiveFtAsset !== undefined)
      ids.push(findUserHolding(this.toReceiveFtAsset, true)?.balance?.id);
    return ids.filter((item) => item !== undefined) as string[];
  }

  setCustomPlan = (balanceIds: string[]) => {
    this.customPlan = this.rootStore.tradeStore.userHoldings?.filter(
      (item) => item.balance && balanceIds.includes(item.balance.id)
    );
  };

  setEnableCustomPlan = (enableCustomPlan: boolean) => {
    this.enableCustomPlan = enableCustomPlan;
  };

  get sellChain(): Chain | undefined {
    return this.rootStore.chainStore.chains.find(
      (item) => item.chainIndex === this.toSellFtAsset?.chainIndex
    );
  }

  get receiveChain(): Chain | undefined {
    return this.rootStore.chainStore.chains.find(
      (item) => item.chainIndex === this.toReceiveFtAsset?.chainIndex
    );
  }

  get isCustomPlan(): boolean {
    return this.selectedBalances.length > 0;
  }

  get toReceiveFtAssetQuantity(): KontosNumber | undefined {
    // This val make senses only if to sell amount and taskData is set
    return this.toSellFtAssetQuantity && this.taskData
      ? new KontosNumber(
          this.taskData?.respSimulateSell?.respQuote?.assetAmount,
          DEFAULT_DECIMAL
        )
      : undefined;
  }

  get fee(): KontosNumber | undefined {
    return this.taskData
      ? new KontosNumber(this.taskData?.totalFeeInUsd, DEFAULT_DECIMAL)
      : undefined;
  }

  get toSellFtAssetValue(): KontosNumber | undefined {
    return this.toSellFtAsset && this.toSellFtAssetQuantity
      ? this.toSellFtAssetQuantity.multiply(
          this.toSellFtAsset.usdPrice,
          DEFAULT_DECIMAL
        )
      : undefined;
  }

  get isInputReady(): boolean {
    if (this.toSellFtAsset === undefined) return false;
    if (this.toSellFtAssetQuantity === undefined) return false;
    if (this.toReceiveFtAsset === undefined) return false;
    if (this.receiver === undefined) return false;
    return true;
  }

  // Input doesn't reach minimum requirement
  get isAmountTooLow(): boolean {
    if (this.isInputReady === false) return false;
    if (this.minRequiredAssetAmount === null) return false;
    if (this.toSellFtAssetQuantity === undefined) return false;
    return this.minRequiredAssetAmount.gt(this.toSellFtAssetQuantity);
  }

  // Input exceeds user's max holdings
  get isAmountTooHigh(): boolean {
    if (this.isInputReady === false) return false;
    if (this.toSellFtAssetMaxAvailable === undefined) return false;
    if (this.toSellFtAssetQuantity === undefined) return false;
    return this.toSellFtAssetMaxAvailable.lt(this.toSellFtAssetQuantity);
  }

  // Input doesn't reach minimum requirement and exceeds user's max holdings
  get isAmountInsufficient(): boolean {
    if (this.isInputReady === false) return false;
    if (this.minRequiredAssetAmount === null) return false;
    if (this.toSellFtAssetMaxAvailable === undefined) return false;
    return (
      this.minRequiredAssetAmount.gt(this.toSellFtAssetMaxAvailable) ||
      this.toSellFtAssetMaxAvailable.eq(0)
    );
  }

  get isAmountOutOfRange(): boolean {
    return (
      this.isAmountTooLow || this.isAmountTooHigh || this.isAmountInsufficient
    );
  }

  get minRequiredAssetAmount(): KontosNumber | null {
    return this.taskData && this.taskData.respSimulateSell
      ? new KontosNumber(
          this.taskData?.respSimulateSell?.minRequiredAssetAmount,
          DEFAULT_DECIMAL
        )
      : null;
  }

  get minRequiredAssetAmountProcessed(): KontosNumber | null {
    return this.minRequiredAssetAmount !== null
      ? this.minRequiredAssetAmount.multiply(1.05)
      : null;
  }

  get mayFail(): boolean {
    return this.taskData ? this.taskData.willRevert : false;
  }

  get orderPrice(): KontosNumber | undefined {
    return this.taskData && this.taskData.totalPaymentsInUsd !== "0"
      ? new KontosNumber(this.taskData.totalPaymentsInUsd, DEFAULT_DECIMAL)
      : undefined;
  }

  get toSellFtAssetBalance(): KontosNumber | undefined {
    return this.toSellFtAsset
      ? new KontosNumber(
          this.toSellFtAsset.balance?.balance || 0,
          DEFAULT_DECIMAL
        )
      : undefined;
  }

  get toSellFtAssetMaxAvailable(): KontosNumber | undefined {
    return this.toSellFtAssetBalance;
  }

  get toReceiveFtAssetBalance(): KontosNumber | undefined {
    return this.toReceiveFtAsset
      ? new KontosNumber(
          this.toReceiveFtAsset.balance?.balance,
          DEFAULT_DECIMAL
        )
      : undefined;
  }

  // get toReceiveFtAssetValue(): KontosNumber | undefined {
  //   return this.toReceiveFtAsset && this.toReceiveFtAssetQuantity
  //     ? this.toReceiveFtAssetQuantity.multiply(
  //         this.toReceiveFtAsset.usdPrice,
  //         DEFAULT_DECIMAL
  //       )
  //     : undefined;
  // }

  get toReceiveFtAssetValue(): KontosNumber | undefined {
    return this.toReceiveFtAsset && this.taskData
      ? new KontosNumber(
          this.taskData?.respSimulateSell?.respQuote?.usdAmount,
          DEFAULT_DECIMAL
        )
      : undefined;
  }

  get defaultChainIndexForSell(): ChainIndex {
    if (
      this.rootStore.chainStore.defaultOptForSell === NonChainIndex.MyAssets
    ) {
      if (this.rootStore.tradeStore.userHoldings.length === 0) {
        return NonChainIndex.All;
      }
      return NonChainIndex.MyAssets;
    }
    return this.rootStore.chainStore.defaultOptForSell;
  }

  get defaultChainIndexForReceive(): ChainIndex {
    if (
      this.rootStore.chainStore.defaultOptForSellReceive ===
        NonChainIndex.Favorites ||
      this.rootStore.chainStore.defaultOptForSellReceive ===
        NonChainIndex.MyAssets
    )
      return NonChainIndex.Recommend;
    return this.rootStore.chainStore.defaultOptForSellReceive;
  }

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.init();
    this.startTrackingAccountName();
    makeAutoObservable(this, {}, { autoBind: true });
  }

  resetSlippage = () => {
    this.slippage = DEFAULT_SLIPPAGE;
  };

  resetTaskData = () => {
    this.taskData = undefined;
  };

  resetInput = () => {
    this.toSellFtAssetQuantity = undefined;
    this.taskData = undefined;
  };

  resetPaymentPlan = () => {
    this.customPlan = [];
    this.enableCustomPlan = false;
  };

  reset = () => {
    this.setToSellFtAsset(undefined);
    this.setToReceiveFtAsset(undefined);
    this.resetTaskData();
    this.resetSlippage();
    this.resetInput();
    this.resetPaymentPlan();
    this.receiver = this.rootStore.tradeStore.accountName;
  };

  initToSellFtAsset = () => {
    // If there's no selling asset and user's logged in
    // We set user's holdings' first asset to sell asset
    if (
      !this.toSellFtAsset &&
      this.rootStore.tradeStore.accountName !== undefined
    ) {
      if (this.rootStore.chainStore.userHoldingsNoKontosChains.length > 0) {
        this.toSellFtAsset = this.rootStore.tradeStore.userHoldings.find(
          (item) =>
            !this.rootStore.chainStore.allowSellChains.some(
              (subItem) => subItem.chainIndex === item.chainIndex
            )
        );
      } else {
        this.toSellFtAsset = undefined;
      }
    }
  };

  init = () => {
    this.reset();
    this.initToSellFtAsset();
  };

  startTrackingAccountName = () => {
    reaction(
      () => this.rootStore.tradeStore.accountName,
      () => {
        this.init();
      }
    );
  };

  startTrackingChains = () => {
    this.chainsReactionDisposer = reaction(
      () => [this.rootStore.chainStore.allowSellChains],
      () => {
        this.initToSellFtAsset();
      }
    );
  };

  stopTrackingChains = () => {
    this.chainsReactionDisposer?.();
  };

  fetchAndSetTaskData = async (): Promise<RespTaskPayment | undefined> => {
    if (
      !this.rootStore.tradeStore.accountName ||
      !this.receiver ||
      !this.toSellFtAsset ||
      !this.sellChain ||
      !this.toSellFtAssetQuantity ||
      !this.toReceiveFtAsset
    ) {
      return undefined;
    }

    const taskData = await TradeService.fetchSellPaymentTask(
      this.rootStore.tradeStore.accountName,
      this.receiver,
      this.toSellFtAsset.chainIndex,
      this.toSellFtAsset.address,
      this.toSellFtAssetQuantity,
      this.toReceiveFtAsset.chainIndex,
      this.toReceiveFtAsset.address,
      this.slippage,
      this.selectedBalancesForApiCall
    );
    // Check input integrity
    if (
      this.toSellFtAsset &&
      this.toReceiveFtAsset &&
      this.toSellFtAssetQuantity
    ) {
      runInAction(() => {
        this.taskData = taskData;
      });
    } else {
      runInAction(() => {
        this.resetInput();
      });
    }

    // Special: check if msgs & payments exist when input value is between min & max
    // This is for backend error
    if (
      !!taskData.rawError ||
      ((!taskData?.msgs || !taskData?.paymentPlan) &&
        !!taskData?.respSimulateSell?.minRequiredAssetAmount &&
        this?.toSellFtAssetMaxAvailable &&
        this?.toSellFtAssetQuantity?.lte(this.toSellFtAssetMaxAvailable) &&
        this?.toSellFtAssetQuantity?.gte(
          taskData?.respSimulateSell?.minRequiredAssetAmount,
          DEFAULT_DECIMAL
        ))
    ) {
      runInAction(() => {
        this.resetInput();
      });
      throw new Error(
        !!taskData.rawError
          ? taskData.rawError
          : t("System error, please try again later")
      );
    }

    return taskData;
  };

  setToSellFtAsset = (asset?: FtAsset) => {
    this.toSellFtAsset = asset;
    if (!this.customPlan.some((item) => isSameFtAsset(item, asset))) {
      this.resetPaymentPlan();
    }
  };

  setToSellFtAssetQuantity = (quantity?: KontosNumber) => {
    this.toSellFtAssetQuantity = quantity;
  };

  setToReceiveFtAsset = (asset?: FtAsset) => {
    this.toReceiveFtAsset = asset;
    if (this.customPlan.some((item) => isSameFtAsset(item, asset))) {
      this.resetPaymentPlan();
    }
  };

  setSlippage = (slippage: KontosNumber) => {
    this.slippage = slippage;
  };

  setReceiver = (_receiver: string) => {
    this.receiver = _receiver;
  };
}
