import { observer } from "mobx-react";
import styled from "styled-components";
import { t } from "i18next";
import SheetUpHeader from "src/components/common/header/SheetUpHeader";
import React, { useRef, useState, useEffect, useCallback } from "react";
import AddSecurityEmail from "src/components/start/AddSecurityEmail";
import { Sheet } from "react-modal-sheet";
import { NoScrollerBarSheet } from "src/components/wrapper/ReactiveWrapper";
import SetUpThreshold from "src/components/start/SetUpThreshold";
import CreateSection from "src/components/start/CreateSection";
import WaitingGoogle from "src/components/start/WaitingGoogle";
import { useNavigate } from "react-router";
import AddNormal from "src/components/start/AddNormal";
import SecuritySettings from "src/components/start/SecuritySettings";
import { useSearchParams } from "react-router-dom";
import { SetUpGuardians } from "src/components/start/SetUpGuardians";
import SearchAccountModal from "src/pages/trade/SearchAccountModal";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GOOGLE_CLIENT_ID } from "src/config";
import SetUpReferrer from "src/components/start/SetUpReferrer";
import toast from "src/components/toast/Toast";
import { securityInfoText } from "src/components/start/SecuritySettings";
import Congratulations from "src/components/start/Congratulations";
import PinCode from "src/components/start/PinCode";
import { loadingStore } from "src/store/loadingStore";
import HeaderRightComponent from "src/components/start/HeaderRightComponent";
import SecuritySettingsInit from "src/components/start/SecuritySettingsInit";
import { useStores } from "src/hooks/useStore";
import tgManager from "src/store/managers/tgManager";
import { BottomSheet } from "src/components/bottom-sheet/BottomSheet";
import { ethers } from "ethers";
import { Email, getNoSuffixKontosName } from "src/utils/helper";

const Wrapper = styled.div`
  box-sizing: border-box;
  padding: 20px 20px;
  width: 100%;
  margin: 0 auto;
  min-height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
  overflow-x: hidden;
`;

export const statusRouterMap = {
  normal: "normal",
  waitingGoogle: "waitingGoogle",
  google: "google",
  addNormal: "addNormal",
  securitySettings: "securitySettings",
  securitySettingsInit: "securitySettingsInit",
  setUpGuardians: "setUpGuardians",
  setUpReferrer: "setUpReferrer",
  congratulations: "congratulations",
  pinCode: "pinCode",
};

const kontosNameValidatePageArr = [
  statusRouterMap.google,
  statusRouterMap.securitySettings,
  statusRouterMap.securitySettingsInit,
  statusRouterMap.setUpReferrer,
  statusRouterMap.setUpGuardians,
  statusRouterMap.congratulations,
];

// these pages need to validate the guardianArr and userEmailArr
const guardianAndEmailValidatePageArr = [statusRouterMap.setUpReferrer];

const withOutHeaderPageArr = [statusRouterMap.congratulations];

const searchAccountPopupTypeMap = {
  editGuardian: "editGuardian",
  addGuardian: "addGuardian",
};

export const kecca256String = (str: string): string => {
  return ethers.utils.keccak256(
    ethers.utils.toUtf8Bytes(str.trim().toLowerCase().toString())
  );
};

const guardianMinLength = 2;
interface ISearchParams {
  [key: string]: string;
}

const CreateAccount: React.FC = () => {
  const { userStore } = useStores();
  const titleMap = {
    [statusRouterMap.normal]: t("New Wallet"),
    [statusRouterMap.waitingGoogle]: t("New Wallet"),
    [statusRouterMap.google]: t("Add Security Email"),
    [statusRouterMap.addNormal]: t("New Wallet"),
    [statusRouterMap.securitySettings]: t("Security Settings"),
    [statusRouterMap.securitySettingsInit]: t("Security Settings"),
    [statusRouterMap.setUpGuardians]: t("Set up Guardians"),
    [statusRouterMap.setUpReferrer]: t("Your Referrer"),
  };

  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const domNode = wrapperRef.current as Element | undefined;
  const [showBottomPopup, setShowBottomPopup] = useState(false);
  const [currentThreshold, setCurrentThreshold] = useState(1);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [showPinCodeModal, setShowPinCodeModal] = useState(false);
  const [address, setAddress] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [userEmailArr, setUserEmailArr] = useState<Email[]>([]);
  const [guardianArr, setGuardianArr] = useState<string[]>([]);
  const [kontosName, setKontosName] = useState("");
  const [currentEditGuardianIndex, setCurrentEditGuardianIndex] = useState(-1);
  const [hasSeed, setHasSeed] = useState(false);
  const [currentSearchAccountPopupType, setCurrentSearchAccountPopupType] =
    useState("");
  const navigate = useNavigate();
  const [seed, setSeed] = useState("");
  const [securitySettingsType, setSecuritySettingsType] = useState("");
  const [securitySettingsInitType, setSecuritySettingsInitType] = useState("");
  const routeStatus =
    searchParams.get("type") ||
    (tgManager.isTg() ? statusRouterMap.addNormal : statusRouterMap.normal);
  const referralCode = searchParams.get("referralCode") || "";
  const [referrerName, setReferrerName] = useState(referralCode);

  const handleSetSearchParams = useCallback(
    (params: ISearchParams) => {
      const newSearchParams = new URLSearchParams(searchParams);

      Object.entries(params).forEach(([key, value]) => {
        if (value != null) {
          newSearchParams.set(key, value);
        }
      });

      if (referralCode) {
        newSearchParams.set("referralCode", referralCode);
      }

      setSearchParams(newSearchParams);
    },
    [searchParams, setSearchParams, referralCode]
  );

  const securityInfoKey: keyof typeof securityInfoText =
    guardianArr.length === 0 && userEmailArr.length <= 0
      ? "mustBeSet"
      : guardianArr.length > 0 || userEmailArr.length > 0
        ? "canBeSet"
        : "fullySet";
  const maxThreshold =
    routeStatus === statusRouterMap.setUpGuardians
      ? guardianArr.length < guardianMinLength
        ? guardianMinLength
        : guardianArr.length
      : userEmailArr.length;
  const minThreshold =
    routeStatus === statusRouterMap.setUpGuardians ? guardianMinLength : 1;
  const canConfirm =
    kontosName !== "" &&
    (userEmailArr.length > 0 || guardianArr.length >= guardianMinLength);

  useEffect(() => {
    // If PIN is already set, skip PIN setup
    const checkSeed = async () => {
      const storageKey = userStore.getStorageKontosKey();
      const seed = userStore.getPin();
      if (storageKey && seed) {
        setSeed(seed);
        setHasSeed(true);
      }
    };

    checkSeed();
  }, [userStore]);

  useEffect(() => {
    const resetStatus = () => {
      if (tgManager.isTg()) {
        handleSetSearchParams({
          type: statusRouterMap.addNormal,
        });
      } else {
        handleSetSearchParams({
          type: statusRouterMap.normal,
        });
      }
    };

    // check route is in kontosNameValidatePageArr
    if (kontosNameValidatePageArr.includes(routeStatus) && kontosName === "") {
      resetStatus();
    }
    // if canConfirm is false and route is in guardianValidatePageArr, set route to normal
    if (!canConfirm && guardianAndEmailValidatePageArr.includes(routeStatus)) {
      resetStatus();
    }
  }, [kontosName, routeStatus, handleSetSearchParams, canConfirm]);

  const handleCreateAccount = useCallback(
    async (
      seed: string,
      name: string,
      password: string,
      guardians: string[],
      actionThreshold = 0,
      inviter: string,
      accountType: number = 0,
      emails: string[] = []
    ) => {
      if (guardians.length === 0 && emails.length === 0) {
        toast({
          text: t("System Error"),
          type: "error",
        });
        return;
      }

      loadingStore.showLoading();
      try {
        const key = await userStore.newKontosKey(
          seed,
          getNoSuffixKontosName(name),
          password,
          guardians,
          actionThreshold,
          inviter,
          accountType,
          emails
        );
        await userStore.updateKey(key, seed);
        handleSetSearchParams({
          type: statusRouterMap.congratulations,
        });
      } catch (e) {
        console.warn("Failed to create Kontos client", e);
        if (e instanceof Error) {
          toast({ text: e.message, type: "error" });
        } else {
          toast({ text: t("Failed to create account."), type: "error" });
        }
      } finally {
        loadingStore.hideLoading();
      }
    },
    [handleSetSearchParams, userStore]
  );

  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <Wrapper ref={wrapperRef}>
        {!withOutHeaderPageArr.includes(routeStatus) && (
          <SheetUpHeader
            handleBack={() => {
              navigate(-1);
            }}
            headStyle={{ justifyContent: "center" }}
            title={titleMap[routeStatus]}
            padding="8px"
            rightComponent={
              <HeaderRightComponent type={"create"} kontosName={kontosName} />
            }
          />
        )}

        {routeStatus === statusRouterMap.normal && (
          <CreateSection
            setRouteStatus={(params) => {
              if (params === statusRouterMap.addNormal) {
                setUserEmailArr([]);
              } else if (
                params === statusRouterMap.waitingGoogle ||
                params === statusRouterMap.google
              ) {
                setGuardianArr([]);
              }
              handleSetSearchParams({
                type: params,
              });
            }}
            loginSuccess={(email) => {
              // login by google success and goto check kontos name
              if (email === "") return;
              setUserEmailArr([{ address: email, isVerified: true }]);
              setGuardianArr([]);
              handleSetSearchParams({
                type: statusRouterMap.addNormal,
              });
            }}
          />
        )}

        {routeStatus === statusRouterMap.waitingGoogle && <WaitingGoogle />}

        {routeStatus === statusRouterMap.google && (
          <AddSecurityEmail
            kontosName={kontosName}
            userEmailArr={userEmailArr}
            setUserEmailArr={setUserEmailArr}
            submitFunction={() => {
              if (currentThreshold > userEmailArr.length) {
                setCurrentThreshold(Math.max(userEmailArr.length, 1));
              }
              if (userEmailArr.length === 0) {
                toast({
                  text: t("Please add a Google account."),
                  type: "warning",
                });
                return;
              }
              setShowBottomPopup(true);
            }}
            domNode={domNode}
            parentRef={wrapperRef}
          />
        )}

        {routeStatus === statusRouterMap.addNormal && (
          <AddNormal
            kontosName={kontosName}
            userEmailArr={userEmailArr.map((email) => email.address)}
            setKontosName={(name) => setKontosName(name)}
            submitFunction={() => {
              let type;
              if (userEmailArr.length === 0) {
                setSecuritySettingsInitType(statusRouterMap.setUpGuardians);
                type = statusRouterMap.setUpGuardians;
              } else {
                setSecuritySettingsInitType(statusRouterMap.google);
                type = statusRouterMap.google;
              }
              if (hasSeed) {
                if (userEmailArr.length === 0) {
                  handleSetSearchParams({
                    type: statusRouterMap.securitySettingsInit,
                  });
                } else {
                  // TODO: Skip referrer
                  // handleSetSearchParams({
                  //   type: statusRouterMap.setUpReferrer,
                  // });
                  const emailHashArr = userEmailArr.map((email) => {
                    return kecca256String(email.address);
                  });
                  // if (
                  //   securitySettingsType !== statusRouterMap.google &&
                  //   securitySettingsType !== statusRouterMap.setUpGuardians
                  // ) {
                  //   console.log("securitySettingsType", securitySettingsType);
                  //   throw new Error("securitySettingsType is invalid");
                  // }
                  handleCreateAccount(
                    seed,
                    kontosName,
                    "",
                    type === statusRouterMap.setUpGuardians ? guardianArr : [],
                    currentThreshold,
                    referrerName,
                    0,
                    type === statusRouterMap.google ? emailHashArr : []
                  );
                }
              } else {
                setShowPinCodeModal(true);
              }
            }}
          />
        )}

        {routeStatus === statusRouterMap.securitySettingsInit && (
          <SecuritySettingsInit
            userEmailArr={userEmailArr.map((email) => email.address)}
            kontosName={kontosName}
            setRouteStatus={(params) => {
              handleSetSearchParams({
                type: params,
              });
              setCurrentThreshold(
                params === statusRouterMap.setUpGuardians ? 2 : 1
              );
            }}
            securityInfoKey={securityInfoKey}
            securitySettingsInitType={securitySettingsInitType}
          />
        )}

        {routeStatus === statusRouterMap.securitySettings && (
          <SecuritySettings
            userEmailArr={userEmailArr.map((email) => email.address)}
            guardianArr={guardianArr}
            kontosName={kontosName}
            setRouteStatus={(params) => {
              handleSetSearchParams({
                type: params,
              });
              setCurrentThreshold(
                params === statusRouterMap.setUpGuardians ? 2 : 1
              );
            }}
            submitFunction={async () => {
              // TODO: Skip referrer
              // handleSetSearchParams({
              //   type: statusRouterMap.setUpReferrer,
              // });
              const emailHashArr = userEmailArr.map((email) => {
                return kecca256String(email.address);
              });
              if (
                securitySettingsType !== statusRouterMap.google &&
                securitySettingsType !== statusRouterMap.setUpGuardians
              ) {
                throw new Error("securitySettingsType is invalid");
              }
              handleCreateAccount(
                seed,
                kontosName,
                "",
                securitySettingsType === statusRouterMap.setUpGuardians
                  ? guardianArr
                  : [],
                currentThreshold,
                referrerName,
                0,
                securitySettingsType === statusRouterMap.google
                  ? emailHashArr
                  : []
              );
            }}
            canConfirm={canConfirm}
            securityInfoKey={"fullySet"}
            currentThreshold={currentThreshold}
            securitySettingsType={securitySettingsType}
          />
        )}

        {routeStatus === statusRouterMap.setUpGuardians && (
          <SetUpGuardians
            minThreshold={minThreshold}
            currentThreshold={currentThreshold}
            kontosName={kontosName}
            guardianArr={guardianArr}
            maxThreshold={maxThreshold}
            submitFunction={() => {
              if (currentThreshold > guardianArr.length) {
                setCurrentThreshold(Math.max(guardianArr.length, 2));
              }
              setShowBottomPopup(true);
            }}
            deleteGuardian={(index) => {
              const newGuardianArr = [...guardianArr];
              newGuardianArr.splice(index, 1);
              setGuardianArr(newGuardianArr);
            }}
            editGuardian={(index) => {
              setCurrentEditGuardianIndex(index);
              setCurrentSearchAccountPopupType(
                searchAccountPopupTypeMap.editGuardian
              );
              setShowAddressModal(true);
            }}
            addGuardian={() => {
              setCurrentSearchAccountPopupType(
                searchAccountPopupTypeMap.addGuardian
              );
              setShowAddressModal(true);
            }}
          />
        )}

        {routeStatus === statusRouterMap.setUpReferrer && (
          <SetUpReferrer
            submitFunction={async () => {
              const emailHashArr = userEmailArr.map((email) => {
                return kecca256String(email.address);
              });
              if (
                securitySettingsType !== statusRouterMap.google &&
                securitySettingsType !== statusRouterMap.setUpGuardians
              ) {
                throw new Error("securitySettingsType is invalid");
              }
              await handleCreateAccount(
                seed,
                kontosName,
                "",
                securitySettingsType === statusRouterMap.setUpGuardians
                  ? guardianArr
                  : [],
                currentThreshold,
                referrerName,
                0,
                securitySettingsType === statusRouterMap.google
                  ? emailHashArr
                  : []
              );
            }}
            referrerName={referrerName}
            setReferrerName={(name) => setReferrerName(name)}
          />
        )}

        {routeStatus === statusRouterMap.congratulations && (
          <Congratulations congratulationType={"created"} />
        )}

        {/* sheet */}
        <NoScrollerBarSheet
          isOpen={showBottomPopup}
          snapPoints={[330]}
          onClose={() => setShowBottomPopup(false)}
          mountPoint={domNode}
        >
          <Sheet.Container>
            <Sheet.Header />
            <Sheet.Content>
              <SetUpThreshold
                submit={() => {
                  setShowBottomPopup(false);
                  const currentRouteType = searchParams.get("type");
                  if (!currentRouteType) {
                    throw new Error("currentRouteType is empty");
                  }
                  setSecuritySettingsType(currentRouteType);
                  handleSetSearchParams({
                    type: statusRouterMap.securitySettings,
                  });
                }}
                cancel={() => {
                  setShowBottomPopup(false);
                }}
                minThreshold={minThreshold}
                maxThreshold={maxThreshold}
                currentThreshold={currentThreshold}
                setCurrentThreshold={(num) => {
                  if (num > maxThreshold) {
                    setCurrentThreshold(maxThreshold);
                    return;
                  }
                  if (num < minThreshold) {
                    setCurrentThreshold(minThreshold);
                    return;
                  }
                  setCurrentThreshold(num);
                }}
              />
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop onTap={() => setShowBottomPopup(false)} />
        </NoScrollerBarSheet>

        <BottomSheet
          isOpen={showAddressModal}
          onClose={() => setShowAddressModal(false)}
          mountPoint={domNode}
        >
          <SearchAccountModal
            rawName={kontosName}
            rawAddress={address}
            onConfirm={(address) => {
              setAddress(address);
              if (!address.endsWith(".os")) {
                address = address + ".os";
              }
              switch (currentSearchAccountPopupType) {
                case searchAccountPopupTypeMap.addGuardian:
                  if (guardianArr.includes(address)) return;
                  setGuardianArr([...guardianArr, address]);
                  break;
                case searchAccountPopupTypeMap.editGuardian:
                  const newGuardianArr = [...guardianArr];
                  newGuardianArr.splice(currentEditGuardianIndex, 1, address);
                  setGuardianArr(newGuardianArr);
                  break;
                default:
                  break;
              }
              setShowAddressModal(false);
            }}
            onCancel={() => setShowAddressModal(false)}
          />
        </BottomSheet>

        <BottomSheet
          isOpen={showPinCodeModal}
          onClose={() => setShowPinCodeModal(false)}
          mountPoint={domNode}
          disableScrollLocking={true}
        >
          <PinCode
            submitFunction={() => {
              if (userEmailArr.length === 0) {
                handleSetSearchParams({
                  type: statusRouterMap.securitySettingsInit,
                });
              } else {
                setSecuritySettingsType(statusRouterMap.google);
                // TODO: Skip referrer
                // handleSetSearchParams({
                //   type: statusRouterMap.setUpReferrer,
                // });
                const emailHashArr = userEmailArr.map((email) => {
                  return kecca256String(email.address);
                });
                // if (
                //   securitySettingsType !== statusRouterMap.google &&
                //   securitySettingsType !== statusRouterMap.setUpGuardians
                // ) {
                //   throw new Error("securitySettingsType is invalid");
                // }
                handleCreateAccount(
                  seed,
                  kontosName,
                  "",
                  [],
                  currentThreshold,
                  referrerName,
                  0,
                  emailHashArr
                );
              }
              setShowPinCodeModal(false);
            }}
            seed={seed}
            setSeed={setSeed}
          />
        </BottomSheet>
      </Wrapper>
    </GoogleOAuthProvider>
  );
};

export default observer(CreateAccount);
