import styled from "styled-components";
import { useMemo } from "react";
import { ReqType, useKontosConnector } from "@/hooks/useKontosConnect";
import { ConnectScreen } from "./views/ConnectScreen";
import { ChooseAccount } from "./views/ChooseAccount";
import { ExecuteUserOp } from "./views/ExecuteUserOp";
import { SignMessage } from "./views/SignMessage";

const Container = styled.div`
  height: 100%;
  width: 100%;
`;

type ConnectStatus =
  | "initializing"
  | "requestingConnection"
  | "requestingSendTx"
  | "requestingSignMsg";

export const Connect: React.FC = () => {
  const { initialized, requestType } = useKontosConnector();

  const status = useMemo((): ConnectStatus => {
    console.log("initialized", initialized);
    console.log("requestType", requestType);

    if (!initialized) {
      return "initializing";
    }
    if (initialized && requestType === ReqType.Connect) {
      return "requestingConnection";
    }
    if (initialized && requestType === ReqType.SendTransaction) {
      return "requestingSendTx";
    }
    if (initialized && requestType === ReqType.SignMessage) {
      return "requestingSignMsg";
    }
    return "initializing";
  }, [initialized, requestType]);

  return (
    <Container>
      {(status === "initializing" || !requestType) && <ConnectScreen />}

      {status === "requestingConnection" && (
        <ChooseAccount
          onDone={function (): void {
            throw new Error("Function not implemented.");
          }}
        />
      )}

      {status === "requestingSendTx" && <ExecuteUserOp />}

      {status === "requestingSignMsg" && <SignMessage />}
    </Container>
  );
};
