import { AirdropGuideModal } from "@/sheets/dapps/components/AirdropGuideModal";
import { AuthModal } from "./AuthModal";
import { observer } from "mobx-react";
import { useStores } from "@/hooks/useStore";

export const GlobalModals: React.FC = observer(() => {
  const { globalStore } = useStores();

  return (
    <>
      <AuthModal />
      {globalStore.showAirdropGuideModal && (
        <AirdropGuideModal
          onClose={() => globalStore.closeAirdropGuideModal()}
        />
      )}
    </>
  );
});
