import { useKontosConnector } from "@/hooks/useKontosConnect";
import { useStores } from "@/hooks/useStore";
import { TxConfirmation } from "@/pages/contract-interaction/TxConfirmation";
import { ContractInteractionType } from "@/pages/contract-interaction/types";
import { getChain } from "@/store/storeHelper";
import { PaymentInitData, PaymentProvider } from "@/store/trade/PaymentStore";
import { observer } from "mobx-react";
import { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const ExecuteUserOp: React.FC = observer(() => {
  const { userStore } = useStores();
  const [paymentInitData] = useState<PaymentInitData | undefined>(undefined);
  const { rejectSendingTx, onSendingTxSuccess, taskData, txPayload } =
    useKontosConnector();

  return (
    <Container>
      <PaymentProvider initData={paymentInitData}>
        <TxConfirmation
          interactionType={ContractInteractionType.Dapp}
          target={txPayload?.to || "-"}
          onCancel={() => rejectSendingTx()}
          onSuccess={onSendingTxSuccess}
          onFail={rejectSendingTx}
          wallet={userStore.accountName!}
          userOpCallProps={{
            taskData: taskData!,
            chain: getChain(txPayload!.chainId)!,
          }}
        />
      </PaymentProvider>
    </Container>
  );
});
