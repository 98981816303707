import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import arrowIco from "src/assets/icons/events/eb/banner-arrow.svg";
import { fontH7, fontRegular } from "@/style/style.global";
import { createRandomMotion, openUrl } from "@/utils/helper";
import { AIRDROP_URL } from "@/config";
import mainCoinImg from "./coin-main.png";
import topCoinImg from "./coin-top.png";
import rightTopCoinImg from "./coin-right-top.png";
import rightCoinImg from "./coin-right.png";
import { motion } from "framer-motion";

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  user-select: none;
`;

const Left = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
`;

const LeftLine1 = styled.span`
  color: var(--Deep-800, #1a2535);
  ${fontH7};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
`;

const LeftLine2 = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;

  @media (hover: hover) {
    &:hover {
      opacity: 0.8;
    }
  }

  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
`;

const LeftLine2Text = styled.span`
  color: var(--Kontos-Blue, #413dec);
  ${fontRegular}
  font-size: 12px;
  font-style: normal;
  line-height: normal;
`;

const LeftLine2Icon = styled.img`
  width: 14px;
`;

const Right = styled.div`
  position: relative;
  margin-right: 21px;
  width: 110px;
  min-width: 110px;
  height: 80px;
`;

const CoinIcon = styled(motion.img)<{
  $width: number;
  $height: number;
  $left?: number;
  $right?: number;
  $top?: number;
  $bottom?: number;
}>`
  position: absolute;
  width: ${(props) => props.$width}px;
  height: ${(props) => props.$height}px;
  ${(props) =>
    props.$left !== undefined &&
    css`
      left: ${props.$left}px;
    `};
  ${(props) =>
    props.$right !== undefined &&
    css`
      right: ${props.$right}px;
    `};
  ${(props) =>
    props.$top !== undefined &&
    css`
      top: ${props.$top}px;
    `};
  ${(props) =>
    props.$bottom !== undefined &&
    css`
      bottom: ${props.$bottom}px;
    `};
`;

const COIN_GROUP = [
  {
    src: mainCoinImg,
    width: 59.119,
    height: 52.915,
    left: 10.51,
    bottom: 9.94,
  },
  {
    src: topCoinImg,
    width: 28.647,
    height: 13.55,
    left: 23.34,
    top: 0,
  },
  {
    src: rightTopCoinImg,
    width: 53.462,
    height: 20.616,
    right: -8.59,
    top: 0,
  },
  {
    src: rightCoinImg,
    width: 42.514,
    height: 36.493,
    right: 2.36,
    bottom: 8.11,
  },
];

interface Props {
  className?: string;
}

export const AirdropBanner: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation();

  return (
    <Container
      className={className}
      onClick={() => {
        openUrl(AIRDROP_URL);
      }}
    >
      <Left>
        <LeftLine1>{t("Claim your $KOS Airdrop!")}</LeftLine1>
        <LeftLine2>
          <LeftLine2Text>{t("Claim $KOS Now")}</LeftLine2Text>
          <LeftLine2Icon src={arrowIco} alt="go to airdrop site" />
        </LeftLine2>
      </Left>

      <Right>
        {COIN_GROUP.map((item) => (
          <CoinIcon
            key={item.src}
            src={item.src}
            $width={item.width}
            $height={item.height}
            $left={item.left}
            $right={item.right}
            $top={item.top}
            $bottom={item.bottom}
            animate={createRandomMotion(5, 5, 10)}
          />
        ))}
      </Right>
    </Container>
  );
};
