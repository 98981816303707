import { Overlay } from "src/components/popups/Overlay";
import styled from "styled-components";
import EnergyBoostingTicker from "../EnergyBoostingTicker";
import { Trans, useTranslation } from "react-i18next";
import EbBar from "../energy-bar/EbBar";
import Skeleton from "react-loading-skeleton";
import avatarIco from "src/assets/icons/events/eb/help-avatar.svg";
import { KontosButton } from "src/components/button/KontosButton";
import { EbHelpItem } from "./EbHelpItem";
import chameleonNftIco from "src/assets/icons/events/eb/rewards/chameleon-nft.png";
import genesisNftIco from "src/assets/icons/events/eb/rewards/genesis-nft.png";
import kosIco from "src/assets/icons/events/eb/rewards/kos.svg";
import usdtIco from "src/assets/icons/events/eb/rewards/usdt.svg";
import lightningIco from "src/assets/icons/events/eb/lightning.svg";
import * as ebTypes from "src/apis/types/energyBoostingTypes";
import { getOrbImg } from "src/utils/events/ebHelper";
import { fontBold } from "@/style/style.global";

const TickerWrapper = styled.div`
  width: calc(100% - 40px);

  .popup-ticker {
    border-radius: 99px;
    background: var(--White, #fff);
    box-shadow: 6px 6px 10px 0px rgba(0, 13, 31, 0.03);
    margin: 0 !important;
  }
`;

const Container = styled.div`
  max-height: 80%;
  width: calc(100% - 40px);
  margin-top: 12px;

  border-radius: 16px;
  background: var(--White, #fff);
  padding: 24px 0 20px 0;

  display: flex;
  flex-direction: column;
  align-items: center;

  .eb-help-header {
    color: var(--Deep-800, #1a2535);
    text-align: center;
    ${fontBold};
    font-size: 18px;
    line-height: 22px;
  }

  .eb-help-avatar {
    margin-top: 20px;

    width: 40px;
    height: 40px;
  }

  .eb-help-name {
    margin-top: 6px;

    color: var(--Deep-800, #1a2535);
    ${fontBold};
    font-size: 14px;
  }

  .eb-help-intro {
    margin-top: 12px;

    color: var(--Deep-400, #80868f);
    text-align: center;
    font-family: "HarmonyOS Sans SC";
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;

    > span {
      color: var(--Kontos-Blue, #413dec);
      ${fontBold};
      font-size: 14px;
      line-height: 140%;
    }
  }

  .eb-help-bar-wrapper {
    margin-top: 20px;
    width: calc(100% - 32px);

    .eb-help-bar-wrapper-inner {
      display: flex;
      align-items: baseline;

      .eb-help-bar {
        width: 100%;
      }

      .eb-help-bar-text {
        margin-left: 6px;
        color: var(--Deep-800, #1a2535);
        ${fontBold};
        font-size: 12px;
      }
    }
  }

  .eb-help-rewards-wrapper {
    margin-top: 22px;
    width: calc(100% - 32px);
    display: flex;
    justify-content: center;
    gap: 11px;
  }

  .eb-help-boost-btn {
    width: calc(100% - 32px);
    margin: 20px 16px 0 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px;

    > img {
      margin-right: 6px;
      width: 27px;
      height: 48px;
    }
  }
`;

interface Props {
  inviter: string;
  inviterEnergyValue: number | undefined;
  inviterEnergyValueWithIncreasement: number | undefined;
  inviterEnergyPercent: number | undefined;
  inviterEnergyPercentWithIncreasement: number | undefined;
  inviterEnergyIncreasement: number;
  inviterNextOrbNumber: number | undefined;
  inviterNextOrbType: number | undefined;
  inviterCurrentBarStage: ebTypes.EnergyBarStage | undefined;
  ebNotifications: ebTypes.Notification[];
  onBoost: () => void;
  isSuccess: boolean;
}

export const EbHelpPopup: React.FC<Props> = ({
  inviter,
  inviterEnergyValue,
  inviterEnergyValueWithIncreasement,
  inviterEnergyPercent,
  inviterEnergyPercentWithIncreasement,
  inviterEnergyIncreasement,
  inviterNextOrbNumber,
  inviterNextOrbType,
  inviterCurrentBarStage,
  ebNotifications,
  onBoost,
  isSuccess,
}) => {
  const { t } = useTranslation();
  // TODO: Eb
  const orbNumberNode: string = inviterNextOrbNumber
    ? inviterNextOrbNumber === 0
      ? "?"
      : inviterNextOrbNumber.toString()
    : "?";

  return (
    <Overlay>
      {/* Ticker */}
      <TickerWrapper>
        <EnergyBoostingTicker
          className="popup-ticker"
          notifications={ebNotifications}
        />
      </TickerWrapper>

      <Container>
        {/* Header */}
        <div className="eb-help-header">{t("Boosting! Boosting!")}</div>

        {/* Avatar */}
        <img className="eb-help-avatar" src={avatarIco} alt="" />

        {/* Inviter Name */}
        <div className="eb-help-name">{inviter}</div>

        {/* Intro */}
        <div className="eb-help-intro">
          <Trans i18nKey={"eb-help-intro"}>
            {/* I just need a little more energy to claim the
            <br />
            <span>
              Surprise Rewards + Energy Orbs x {{ orbNumberNode } as any}
            </span> */}
            Please help me boost my Kontos account!
          </Trans>
        </div>

        {/* Bar */}
        <div className="eb-help-bar-wrapper">
          {inviterCurrentBarStage &&
          inviterEnergyPercent !== undefined &&
          inviterEnergyPercentWithIncreasement !== undefined &&
          inviterEnergyValue !== undefined &&
          inviterEnergyValueWithIncreasement !== undefined ? (
            <div className="eb-help-bar-wrapper-inner">
              <EbBar
                className="eb-help-bar"
                energyValue={inviterEnergyValueWithIncreasement}
                prevEnergyValue={
                  inviterEnergyIncreasement > 0 ? inviterEnergyValue : 0
                }
                energyPercent={inviterEnergyPercentWithIncreasement}
                prevEnergyPercent={
                  inviterEnergyIncreasement > 0 ? inviterEnergyPercent : 0
                }
                stage={inviterCurrentBarStage}
                animationDuraion={2}
                countUpDuration={2}
                simplified={true}
              />
              <span className="eb-help-bar-text">100%</span>
            </div>
          ) : (
            <Skeleton
              style={{
                height: "55px",
                borderRadius: "8px",
              }}
            />
          )}
        </div>

        {/* Rewards */}
        <div className="eb-help-rewards-wrapper">
          <EbHelpItem
            items={[
              {
                title: t("Kontos Token"),
                subTitle: t("Voucher"),
                icon: kosIco,
              },
              {
                title: t("Up to 10,000 USDT"),
                icon: usdtIco,
              },
              {
                title: t("Chameleon NFT"),
                icon: chameleonNftIco,
              },
              {
                title: t("Genesis NFT"),
                icon: genesisNftIco,
              },
            ]}
          />
          <EbHelpItem
            items={[
              {
                title: t("Energy Orbs") + " x " + orbNumberNode,
                icon: getOrbImg(inviterNextOrbType),
              },
            ]}
          />
        </div>

        {/* Boosting Button */}
        <KontosButton
          className="eb-help-boost-btn"
          disabled={isSuccess}
          onClick={onBoost}
        >
          <img src={lightningIco} alt="" />
          {t("Boost!")}
        </KontosButton>
        {/* {ebAccountLoaded ? (
          <KontosButton
            className="eb-help-boost-btn"
            disabled={isSuccess}
            onClick={onBoost}
          >
            <img src={lightningIco} alt="" />
            {t("Boosting!")}
          </KontosButton>
        ) : (
          <div style={{ width: "100%" }}>
            <Skeleton
              style={{
                margin: "20px 16px 0 16px",
                width: "calc(100% - 32px)",
                height: "55px",
                borderRadius: "99px",
              }}
            />
          </div>
        )} */}
      </Container>
    </Overlay>
  );
};
