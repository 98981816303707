import { makeAutoObservable, runInAction } from "mobx";
import {
  getDefaultClientPolymorphism,
  KontosKey,
  KontosKeyHelper,
  NetworkInfo,
  StorageKontosKey,
} from "@zkkontos/kontos-sdk";
import { DEFAULT_DECIMAL, KONTOS_URL } from "../config";
import localKeeper, { OnboardingType } from "./localKeeper";
import { KontosCtx } from "src/apis/types";
import {
  getNoSuffixKontosName,
  isMobileDevice,
  setCronJob,
} from "src/utils/helper";
import { loadingStore } from "./loadingStore";
import toast from "src/components/toast/Toast";
import { Account, Chain, FtAsset, toKontosAccount } from "src/type/zkkontos";
import { getTimeDifference, isSameKontosName } from "src/utils/zkkontosHelper";
import { LANG } from "src/i18n/lang";
import i18n from "src/i18n/i18n";
import KontosNumber from "src/utils/KontosNumber";
import { t } from "i18next";
import { RootStore } from "./RootStore";
import { SheetView } from "./SheetStore";
import { fetchAccountBalances } from "@/service/asset-service";
import { fetchAccount } from "@/service/account-service";
import { callRegisterAccount } from "@/apis/aa-apis";
import { ReqType as KontosConnectorReqType } from "@/hooks/useKontosConnect";

const CRON_ACCOUNT_INTERVAL = 60000;
const CRON_BALANCE_INTERVAL = 20000;
const NO_ACCOUNT_RETRY_TIME = 2;

export class UserStore {
  rootStore: RootStore;
  kontosKey?: KontosKey;
  accountsRecovering: string[] = [];
  kontosCtx?: KontosCtx;
  isMobile: boolean = isMobileDevice();
  accountInfo?: Account;
  accountBalances: FtAsset[] = [];
  unlockAmount: number = localKeeper.getUnlockAmount();
  storeAccountSelectedIndex: number = 0;
  toBindAccount?: string;
  storageKeys: StorageKontosKey[] = [];
  lang: string = LANG.en.name;
  isInitialized: boolean = false;
  routeAfterAuth?: string;
  routeAfterUnlock?: string;
  onboardingTodos: OnboardingType[] = [];
  pin?: string;
  restoreCount: number = 0;
  restoring: boolean = false;
  accountCronjobId: NodeJS.Timer | null = null;
  balanceCronjobId: NodeJS.Timer | null = null;
  connectorReqType?: KontosConnectorReqType; // for kontos connector

  get aaAccounts(): { [key: string]: string } {
    return !!this.accountInfo
      ? Object.keys(this.accountInfo.blockChainAccounts).reduce(
          (acc, key) => {
            acc[key] = this.accountInfo!.blockChainAccounts[key].aaAddress;
            return acc;
          },
          {} as { [key: string]: string }
        )
      : {};
  }

  reset = () => {
    this.kontosKey = undefined;
    this.accountsRecovering = [];
    this.accountInfo = undefined;
    this.accountBalances = [];
    this.routeAfterUnlock = undefined;
    this.routeAfterAuth = undefined;
    this.onboardingTodos = [];
    this.pin = undefined;
    if (this.balanceCronjobId !== null) {
      clearInterval(this.balanceCronjobId);
      this.balanceCronjobId = null;
    }
  };

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.rootStore = rootStore;
    localKeeper.clearFtAssets();
    localKeeper.startOnboarding();
    localKeeper.checkLocalRecords();
    this.accountsRecovering = localKeeper.getAccountsRecovering() || [];
    this.storageKeys = localKeeper.getAllStorageKontosKey() || [];
    const lang = localKeeper.getLang() || navigator.language || LANG.en.name;
    this.updateLang(lang);
    this.isInitialized = true;
  }

  ensureAccountInfo = async (
    retryCount: number = NO_ACCOUNT_RETRY_TIME
  ): Promise<boolean> => {
    const { flag } = await this.executeWithAccountInfoRetry(
      () => {
        return true;
      },
      () => {
        return false;
      },
      retryCount
    );
    return flag;
  };

  executeWithAccountInfoRetry = async <T, K>(
    todo?: () => Promise<T> | T,
    fallback?: () => Promise<K> | K,
    retryCount: number = NO_ACCOUNT_RETRY_TIME
  ) => {
    if (!this.accountInfo) {
      let count = 0;
      while (count < retryCount) {
        try {
          await this.fetchAndUpdateAccountInfoFromBackend();
          break;
        } catch {
          count++;
        }
      }
      if (count === retryCount) {
        return { flag: false, data: await fallback?.() };
      }
    }
    return { flag: true, data: await todo?.() };
  };

  fetchAndUpdateAccountBalances = async (accountName?: string) => {
    const name: string | undefined = accountName
      ? accountName
      : localKeeper.getStorageKontosKeyWithCheck()?.accountName;
    if (name) {
      try {
        const resp = await fetchAccountBalances(name);
        runInAction(() => {
          this.accountBalances = resp;
        });
        return resp;
      } catch (e) {
        console.log("Failed to fetch account balances", e);
      }
    } else {
      console.log("No storage key for fetching account balances");
    }
  };

  fetchAndUpdateAccountInfoFromBackend = async (accountName?: string) => {
    const name: string | undefined = accountName
      ? accountName
      : localKeeper.getStorageKontosKeyWithCheck()?.accountName;
    if (name !== undefined) {
      try {
        await this.checkExplorerApitatus();
        const account = await fetchAccount(name);
        runInAction(() => {
          this.accountInfo = account;
        });
        return account;
      } catch (e) {
        console.log("Failed to fetch account from chain", e);
      }
    } else {
      console.log("No storage key for fetching account from chain");
    }
  };

  // add cron job here
  startCronjob = async () => {
    this.accountCronjobId = setCronJob(
      this.accountCronjobId,
      () => this.fetchAndUpdateAccountInfoFromBackend(this.accountInfo?.name),
      CRON_ACCOUNT_INTERVAL
    );

    this.balanceCronjobId = setCronJob(
      this.balanceCronjobId,
      () => this.fetchAndUpdateAccountBalances(this.accountInfo?.name),
      CRON_BALANCE_INTERVAL
    );
  };

  initNetwork = async () => {
    try {
      return await this.checkExplorerApitatus();
    } catch (e) {}
  };

  restore = async (
    needBalance?: boolean,
    showLoading: boolean = true,
    retryCount: number = 0
  ) => {
    this.restoring = true;
    showLoading && loadingStore.showLoading();
    this.accountsRecovering = localKeeper.getAccountsRecovering() || [];
    this.storageKeys = localKeeper.getAllStorageKontosKey() || [];
    const storageKey = localKeeper.getStorageKontosKeyWithCheck();

    await this.initNetwork();
    if (!storageKey) {
      console.log("no storage key");
      return;
    }

    try {
      const getAccountFromBackend = async () => {
        const account = await fetchAccount(storageKey.accountName);
        return account;
      };

      const getAccountBalances = () =>
        fetchAccountBalances(storageKey.accountName);

      const getAccount = getAccountFromBackend;

      const toFetch = needBalance
        ? [getAccount(), getAccountBalances()]
        : [getAccount()];

      const results = await Promise.allSettled(toFetch);

      for (const [index, result] of results.entries()) {
        if (result.status === "fulfilled") {
          if (index === 0) {
            runInAction(() => {
              this.setAccountInfo((result.value as Account) || undefined);
            });
          } else if (index === 1) {
            runInAction(() => {
              this.accountBalances = Array.isArray(result.value)
                ? result.value
                : [];
            });
          }
        } else {
          if (index === 0) {
            if (retryCount > 0) {
              await this.restore(needBalance, showLoading, retryCount - 1);
              return;
            } else if (this.noNetwork) {
              this.setAccountInfo(toKontosAccount(storageKey.accountName));
            }
            console.error(result.reason);
            toast({ type: "error", text: result.reason.toString() });
          } else {
            console.error(result.reason);
            toast({ type: "error", text: result.reason.toString() });
          }
        }
      }

      // set onboarding todos
      runInAction(() => {
        this.onboardingTodos =
          localKeeper
            .getOnboardingStatus()
            ?.filter((item) => item.finished === false)
            ?.map((item) => item.type) || [];
      });
    } catch (e) {
      console.error(e);
      const errorMessage =
        e instanceof Error
          ? e.message
          : "An unknown error occurred when restore";
      if (!errorMessage.startsWith("cannot new kontos query client")) {
        toast({ type: "error", text: errorMessage });
      }
    } finally {
      showLoading && loadingStore.hideLoading();
      this.startCronjob();
      runInAction(() => {
        this.restoring = false;
        this.restoreCount += 1;
      });
    }
  };

  checkPubKey = async (toVerifyPubKey: string): Promise<boolean> => {
    try {
      const accountName = this.accountInfo?.name;
      const account = await fetchAccount(accountName!);
      if (
        toVerifyPubKey.toLocaleLowerCase() ===
        account.pubKey.toLocaleLowerCase()
      ) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.error(e);
      return false;
    }
  };

  getAccountInfo = (): Account | undefined => {
    return this.accountInfo;
  };

  setAccountInfo = (data: Account | undefined) => {
    this.accountInfo = data;
  };

  updateAccountsRecovering = (arr: string[]) => {
    localKeeper.updateAccountsRecovering(arr);
    this.accountsRecovering = arr;
  };

  resetStorage = () => {
    localKeeper.resetAccount();
    this.rootStore.tradeStore.reset();
    this.reset();
  };

  updateKey = async (key: KontosKey, seed: string) => {
    await localKeeper.updateKontosKey(key);
    await this.reconstructKontosCli(seed);
  };

  newKontosKey = async (
    seed: string,
    accountName: string,
    recoverPassword: string,
    guardians: string[] = [],
    actionThreshold: number = 0,
    inviter: string,
    actionType: number = 0,
    emailHashes: string[] = [],
    network: NetworkInfo = { rpcUrl: KONTOS_URL }
  ): Promise<KontosKey> => {
    try {
      const key = await getDefaultClientPolymorphism().createKontosKey(
        accountName,
        seed
      );
      await callRegisterAccount({
        name: accountName,
        pubKey: key.pubKeyData.compressedPubKey,
        emails: emailHashes,
        guardians,
        threshold: actionThreshold,
        inviter,
      });
      await localKeeper.updateKontosKey(key);
      runInAction(() => {
        this.kontosKey = key;
      });
    } catch (e) {
      if (e instanceof Error) {
        throw e;
      } else {
        throw new Error("unknown error");
      }
    }
    return this.kontosKey!;
  };

  checkExplorerApitatus = async () => {
    return true;
  };

  reconstructKontosCli = async (
    seed: string,
    isRecovery?: boolean,
    network: NetworkInfo = { rpcUrl: KONTOS_URL }
  ): Promise<KontosKey> => {
    const storageKontosKey = localKeeper.getStorageKontosKey(
      isRecovery ? undefined : this.accountName
    );
    if (!storageKontosKey) {
      throw new Error("empty data");
    }
    try {
      console.log("reconstructKontosCli");
      const key = await KontosKeyHelper.fromStorageKontosKey(
        storageKontosKey,
        seed
      );
      key.accountName = getNoSuffixKontosName(key.accountName);
      this.kontosKey = key;
      return this.kontosKey;
    } catch (e) {
      console.warn("reconstructKontosCli error for first try", e);
      const key = await KontosKeyHelper.fromStorageKontosKey(
        storageKontosKey,
        seed
      );
      key.accountName = getNoSuffixKontosName(key.accountName);
      this.kontosKey = key;
      return this.kontosKey;
    }
  };

  // TODO: Seed
  getKontosKey = async (): Promise<KontosKey> => {
    if (!this.kontosKey) {
      throw new Error("no seed in session, you need to unlock first");
    }
    return this.kontosKey!;
  };

  hasAccountInStorage = (): boolean => {
    const key = localKeeper.getStorageKontosKey();
    return !!key;
  };

  getStorageKontosKey = (): StorageKontosKey => {
    const key = localKeeper.getStorageKontosKey();
    return key!;
  };

  updateUnlockAmount = (amount: number) => {
    localKeeper.updateUnlockAmount(amount);
    this.unlockAmount = amount;
  };

  getRouteAfterUnlock = (): string | undefined => {
    return this.routeAfterUnlock;
  };

  updateRouteAfterUnlock = (route?: string) => {
    this.routeAfterUnlock = route;
  };

  switchAccount = async (index: number, isRecovery = false) => {
    if (localKeeper.getIndex() === index) {
      return;
    }
    localKeeper.switchAccount(index);
    this.storeAccountSelectedIndex = index;
    this.kontosKey = undefined;
    if (isRecovery) {
      return;
    }
    try {
      loadingStore.showLoading();
      await this.rootStore.dappConnectStore.disConnectAll();
      await this.restore(true);
    } catch (e) {
      console.log("Exception occured when switching account", e);
    } finally {
      loadingStore.hideLoading();
    }
  };

  removeAccount = (name: string, force?: boolean) => {
    const currentName = this.accountInfo?.name;
    if (force || (currentName && !isSameKontosName(currentName, name))) {
      localKeeper.removeAccount(name);
      this.storageKeys = localKeeper.getAllStorageKontosKey() || [];
    }
    // Check recovering accounts
    localKeeper.removeRecoveringAccount(name);
    this.accountsRecovering = localKeeper.getAccountsRecovering() || [];
    if (force) {
      this.restore();
    }
  };

  getToBindAccount = (): string | undefined => {
    return this.toBindAccount;
  };

  updateToBindAccount = (account: string) => {
    this.toBindAccount = account;
  };

  updateLang = async (lang: string) => {
    localKeeper.updateLang(lang);
    this.lang = lang;
    i18n.changeLanguage(lang);
  };

  updateRouteAfterAuth = (route?: string) => {
    this.routeAfterAuth = route;
  };

  filterUserHoldingsChainsWithSpecificChains(specificChains: Chain[]): Chain[] {
    const userHoldFtAssetsChains = this.userHoldings?.map(
      (ftAsset) => ftAsset?.chainIndex.toLocaleLowerCase()
    );
    return specificChains.filter((chain) =>
      userHoldFtAssetsChains.includes(chain?.chainIndex.toLocaleLowerCase())
    );
  }

  unlock = (
    unlockCallback?: (cli: KontosKey) => void,
    needExecute?: boolean
  ): boolean => {
    if (!this.kontosKey) {
      this.rootStore.sheetStore.openSheet(SheetView.Unlock, { unlockCallback });
      return false;
    }
    if (needExecute) {
      unlockCallback?.(this.kontosKey);
    }
    return true;
  };

  retryConnectToNetwork = async () => {
    await this.checkExplorerApitatus();
    await this.restore();
  };

  markOnboardingFinished = (type: OnboardingType) => {
    localKeeper.markOnboardingFinished(type);
    this.onboardingTodos =
      localKeeper
        .getOnboardingStatus()
        ?.filter((item) => item.finished === false)
        ?.map((item) => item.type) || [];
  };

  getPin = () => {
    return this.pin;
  };

  setPin = (seed: string) => {
    this.pin = seed;
  };

  setConnectorReqType = (val?: KontosConnectorReqType) => {
    this.connectorReqType = val;
  };

  get guardianChanged(): boolean {
    if (this.accountInfo && this.accountInfo.updateGuardiansDeadline > 0) {
      if (
        Array.isArray(this.accountInfo?.futureGuardians) &&
        this.accountInfo.futureGuardians.length > 0
      ) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  get emailGuardianChanged(): boolean {
    if (this.accountInfo && this.accountInfo.updateGuardiansDeadline > 0) {
      if (
        Array.isArray(this.accountInfo?.futureEmailGuardians) &&
        this.accountInfo.futureEmailGuardians.length > 0
      ) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  get updateGuardianDeadlineTimeAgo(): string {
    if (this.accountInfo?.updateGuardiansDeadline) {
      return getTimeDifference(this.accountInfo?.updateGuardiansDeadline, t);
    }
    return "";
  }

  get kontosName(): string {
    if (this?.accountInfo?.name) {
      return this.accountInfo.name + ".os";
    } else {
      return "";
    }
  }

  get accountStorageKey(): StorageKontosKey | undefined {
    if (this.accountInfo && this.storageKeys) {
      return this.storageKeys.find((item) =>
        isSameKontosName(item.accountName, this.accountInfo?.name)
      );
    }
    return undefined;
  }

  get pubKeyException(): boolean {
    if (this.noNetwork) {
      return false;
    }
    if (this.accountInfo && this.accountStorageKey) {
      if (
        this.accountInfo.pubKey !==
          this.accountStorageKey.pubKeyData.compressedPubKey &&
        !this.accountsRecovering.includes(this.accountInfo.name)
      ) {
        return true;
      }
    }
    return false;
  }

  get balanceInUSD(): KontosNumber | undefined {
    return this.accountBalances?.reduce(
      (acc, cur) =>
        acc.add(cur?.balance?.balanceUsdAmount || 0, DEFAULT_DECIMAL),
      new KontosNumber(0)
    );
  }

  get balanceInUSDStr(): string | undefined {
    return this.balanceInUSD?.toFormat();
  }

  get userHoldings(): FtAsset[] {
    return this.accountBalances ? this.accountBalances : [];
  }

  get userHoldingsInWhitelist(): FtAsset[] {
    return this.userHoldings.filter((item) => item.isWhitelist === true);
  }

  get userHoldingsInBlacklist(): FtAsset[] {
    return this.userHoldings.filter((item) => item.isWhitelist === false);
  }

  get userHoldingsSortedByBalances(): FtAsset[] {
    return this.userHoldings.slice().sort((a, b) => {
      if (
        Number(a.balance?.balance || "0") > Number(b.balance?.balance || "0")
      ) {
        return -1;
      } else if (
        Number(b.balance?.balance || "0") > Number(a.balance?.balance || "0")
      ) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  get accountName(): string | undefined {
    if (this.accountInfo) return this.accountInfo.name;
    return undefined;
  }

  get accountNameWithOs(): string | undefined {
    return this.accountInfo
      ? this.accountInfo.name.replaceAll(".os", "") + ".os"
      : undefined;
  }

  get noNetwork(): boolean {
    // TODO: network
    // return !this.kontosQueryCli;
    return false;
  }

  get needOnboardingHome(): boolean {
    return this.onboardingTodos.includes(OnboardingType.Home);
  }

  get needOnboardingEb(): boolean {
    return this.onboardingTodos.includes(OnboardingType.Eb);
  }

  get needOnboardingAssets(): boolean {
    return this.onboardingTodos.includes(OnboardingType.Assets);
  }

  get needOnboardingPayment(): boolean {
    return this.onboardingTodos.includes(OnboardingType.Payment);
  }

  get needOnboardingAiScore(): boolean {
    return this.onboardingTodos.includes(OnboardingType.AiScore);
  }

  get needOnboardingEbLeaderBoardRanking(): boolean {
    return this.onboardingTodos.includes(OnboardingType.EbLeaderBoardRanking);
  }

  get needOnboardingSell(): boolean {
    return this.onboardingTodos.includes(OnboardingType.Sell);
  }
}
