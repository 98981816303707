import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ViewFinder } from "./ViewFinder";
import ReactQrReader from "@zkkontos/react-qr-reader-es6";
import Skeleton from "react-loading-skeleton";
import toast from "../toast/Toast";
import { useTranslation } from "react-i18next";

const Container = styled.div<{ height: number }>`
  width: 100%;
  height: ${(props) => props.height}px;
  position: relative;

  .qr-loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: ${(props) => props.height}px;
    z-index: 20;
  }
`;

interface Props {
  onConnect: (uri: string) => Promise<void>;
  onError: () => void;
}

export const KontosQrReader: React.FC<Props> = ({ onConnect, onError }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [loading, setLoading] = useState(true);
  const [width, setWidth] = useState<number>(0);
  const [show, setShow] = useState(true);
  const { t } = useTranslation();

  const onScan = useCallback(
    async (data: string | null) => {
      console.log("data", data);
      if (data) {
        await onConnect(data);
      }
    },
    [onConnect]
  );

  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current.offsetWidth);
    }
    return () => {
      setShow(false);
    };
  }, []);

  return (
    <Container ref={ref} height={width}>
      {show && (
        <Fragment>
          {loading && <Skeleton className="qr-loading" />}
          <ReactQrReader
            onLoad={() => setLoading(false)}
            showDefaultViewFinder={false}
            onError={(e) => {
              if (typeof e.toString) {
                if (e.toString().toLowerCase().includes("device not found")) {
                  toast({
                    text: t(
                      "It looks like your device does not include a camera."
                    ),
                    type: "warning",
                  });
                }
              }
              onError();
            }}
            onScan={onScan}
            ViewFinder={ViewFinder}
          />
        </Fragment>
      )}
    </Container>
  );
};
