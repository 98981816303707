import { makeAutoObservable } from "mobx";
import { RootStore } from "./RootStore";

export class GlobalStore {
  rootStore: RootStore;
  showAuthModal: boolean = false;
  showAirdropGuideModal: boolean = false;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.rootStore = rootStore;
  }

  openAuthModal = () => {
    this.showAuthModal = true;
  };

  closeAuthModal = () => {
    this.showAuthModal = false;
  };

  openAirdropGuideModal = () => {
    this.showAirdropGuideModal = true;
  };

  closeAirdropGuideModal = () => {
    this.showAirdropGuideModal = false;
  };
}
