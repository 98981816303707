import { KEY_STORE_NAME } from "../config";

export interface IKEYS {
  [key: string]: string;
}

export let KEYS: IKEYS = {
  store_account_info: "store_account_info",
  store_block_chain_accounts_status: "store_block_chain_accounts_status",
  store_has_requested_asset_type_list: "store_has_requested_asset_type_list",
  store_has_requested_balance_list: "store_has_requested_balance_list",
  store_has_requested_task_list: "store_has_requested_task_list",
  store_has_requested_history_list: "store_has_requested_history_list",
  store_asset_type_list: "store_asset_type_list",
  store_balance_list: "store_balance_list",
  store_aggregated_asset_list: "store_aggregated_asset_list",
  store_task_list: "store_task_list",
  store_history_list: "store_history_list",
  store_account_total_USD_balance: "store_account_total_USD_balance",
  store_is_default_wallet: "store_is_default_wallet",
  store_chain_list: "store_chain_list",
  store_kontos_key: KEY_STORE_NAME,
  store_kontos_cli: "store_kontos_cli",
  default_chain_id: "default_chain_id",
  inject_chain_info: "inject_chain_info",
  store_third_party_address: "store_third_party_address",
  store_third_party_reset: "store_third_party_reset",
  store_auth_website_info: "store_auth_website_info",
  store_kontos_tx: "store_kontos_tx",
  store_send_assets_info: "store_send_assets_info",
  store_connected_sites: "store_connected_sites",
  store_inject_address_list: "store_inject_address_list",
  store_account_selected_index: "store_account_selected_index",
  store_currency_name: "store_currency_name",
  store_unlock_amount: "store_unlock_amount",
  store_accounts_recovering: "store_accounts_recovering",
  store_dapp_transaction_hash: "store_dapp_transaction_hash",
  store_kontos_ctx: "store_kontos_ctx",
  session_pin: "session_pin",
  store_ft_assets: "store_ft_assets",
  store_lang: "store_lang",
  store_dapp_history: "store_dapp_history",
  store_wc_user: "store_wc_user",
  session_trade_mode: "session_trade_mode",
  store_onboarding: "store_onboarding",
  session_showed_manage_asset_tip: "session_showed_manage_asset_tip",
  store_tasks_and_txs: "store_tasks_and_txs",
  store_accounts_recovering_email: "store_accounts_recovering_email",
  session_0x_scope_ai_score: "session_0x_scope_ai_score",
  store_disable_ai_score: "store_disable_ai_score",
  store_bybit_task_deposit_callback_params:
    "store_bybit_task_deposit_callback_params",
  store_kontos_connections: "store_kontos_connections",
};

// @ts-ignore
// eslint-disable-next-line array-callback-return
Object.keys(KEYS).map((item) => {
  if (item !== "store_kontos_key") {
    // @ts-ignore
    KEYS[item] = KEYS[item] + "_v2";
  }
});
