export const set = (key: string, value: any) => {
  const stringValue = typeof value === "string" ? value : JSON.stringify(value);
  localStorage.setItem(key, stringValue);
};

export const get = (key: string) => {
  const stringValue = localStorage.getItem(key);

  if (!stringValue) {
    return null;
  }

  try {
    return JSON.parse(stringValue);
  } catch (error) {
    return stringValue;
  }
};

export const getV2 = <T>(key: string): T | null => {
  const stringValue = localStorage.getItem(key);

  if (!stringValue) {
    return null;
  }

  try {
    return JSON.parse(stringValue) as T;
  } catch (error) {
    console.error(`Failed to parse localStorage key "${key}":`, error);
    return null;
  }
};

export const getByClass = <T extends object>(
  key: string,
  cls: { new (...args: any[]): T }
) => {
  const stringValue = localStorage.getItem(key);
  if (!stringValue) {
    throw new Error(`No data found for key "${key}"`);
  }

  let data;
  try {
    data = JSON.parse(stringValue);
  } catch (error) {
    throw new Error(`Error parsing data for key "${key}"`);
  }

  if (typeof data !== "object") {
    throw new Error(
      `Cannot convert non-object data to class instance for key "${key}"`
    );
  }

  return Object.assign(new cls(), data);
};

export const remove = (key: string) => {
  localStorage.removeItem(key);
};
