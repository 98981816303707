import { observer } from "mobx-react";
import styled from "styled-components";
import { t } from "i18next";
import SheetUpHeader from "src/components/common/header/SheetUpHeader";
import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { useNavigate } from "react-router";
import SecuritySettingsRecover from "src/components/start/SecuritySettingsRecover";
import { useSearchParams } from "react-router-dom";
import Congratulations from "src/components/start/Congratulations";
import PinCode from "src/components/start/PinCode";
import NormalRecover from "src/components/start/NormalRecover";
import WaitingAuthorizeKontos from "src/components/start/WaitingAuthorizeKontos";
import WaitingAuthorizeMail from "src/components/start/WaitingAuthorizeMail";
import {
  KontosKey,
  KontosKeyHelper,
  StorageKontosKey,
} from "@zkkontos/kontos-sdk";
import { debounce } from "lodash";
import HeaderRightComponent from "src/components/start/HeaderRightComponent";
import { statusRouterMap } from "src/components/start/routerMap";
import {
  ROUTE_HOME,
  ROUTE_RECOVER_ACCOUNT_OLD,
} from "src/router/router-config";
import toast from "src/components/toast/Toast";
import { useStores } from "src/hooks/useStore";
import { getStorageKontosValList } from "src/store/localKeeper";
import { BottomSheet } from "@/components/bottom-sheet/BottomSheet";
import { loadingStore } from "@/store/loadingStore";
import { fetchAccount, getSequence } from "@/service/account-service";
import { Account } from "@/type/zkkontos";

const ACCOUNT_REFRESH_INTERVAL = 5000;

const Wrapper = styled.div`
  box-sizing: border-box;
  padding: 20px 20px;
  width: 100%;
  margin: 0 auto;
  min-height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const WrapperExistToast = styled.div`
  span {
    color: #413dec;
    text-decoration: underline;
  }
`;

const kontosNameValidatePageArr = [
  statusRouterMap.pinCode,
  statusRouterMap.securitySettingsRecover,
  statusRouterMap.waitingAuthorizeKontos,
  statusRouterMap.waitingAuthorizeMail,
  statusRouterMap.congratulations,
];

const seedValidatePageArr = [
  statusRouterMap.securitySettingsRecover,
  statusRouterMap.waitingAuthorizeKontos,
  statusRouterMap.waitingAuthorizeMail,
  statusRouterMap.congratulations,
];

const withOutHeaderPageArr = [statusRouterMap.congratulations];

export function checkIsAccountAlreadyAvailable(
  accountName: string,
  dataA: string[],
  dataB: StorageKontosKey[]
): boolean {
  const isInA = dataA.includes(accountName);
  const isInB = dataB.some(
    (accountData) => accountData.accountName === accountName
  );
  return !isInA && isInB;
}

const RecoverAccount: React.FC = () => {
  const { userStore } = useStores();
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const domNode = wrapperRef.current as Element | undefined;
  const [searchParams, setSearchParams] = useSearchParams();
  const [kontosName, setKontosName] = useState(searchParams.get("name") || "");
  const setKontosNameTrimed = useCallback((newValue: string) => {
    setKontosName(newValue.trim().replaceAll(".os", "").trim());
  }, []);
  const navigate = useNavigate();
  const [seed, setSeed] = useState("");
  const [kontosNameInfo, setKontosNameInfo] = useState<Account | undefined>(
    undefined
  );
  const [kontosKey, setKontosKey] = useState<KontosKey | undefined>(undefined);
  const [nonce, setNonce] = useState(0);
  const [showPinCodeModal, setShowPinCodeModal] = useState(false);
  const intervalRef = useRef<number | null>(null);
  const routeStatus = searchParams.get("type") || statusRouterMap.normal;
  const [refreshInterval, setRefreshInterval] = useState<number>(
    ACCOUNT_REFRESH_INTERVAL
  );

  useEffect(() => {
    // check if is recovering account
    if (!routeStatus) {
      userStore.restore(true);
    }
  }, [routeStatus, userStore]);

  const accountAlreadyRecoveredToastItem = useMemo((): JSX.Element => {
    return (
      <div
        onClick={() => {
          navigate(ROUTE_HOME);
        }}
      >
        <WrapperExistToast>
          {t("This account has been recovered in your wallet.")}{" "}
          <span>{t("Click here to go to your dashboard.")}</span>
        </WrapperExistToast>
      </div>
    );
  }, [navigate]);

  // If PIN is already set, skip PIN setup
  const checkSeed = async () => {
    const storageKey = userStore.getStorageKontosKey();
    const seed = userStore.getPin();
    if (storageKey && seed) {
      setSeed(seed);
    }
  };

  const getKontosNameInfo = useCallback(async (kontosName: string) => {
    const account = await fetchAccount(kontosName);
    setKontosNameInfo(account);
    return account;
  }, []);

  const checkIsRecoverSuccess = useCallback((): boolean => {
    if (!kontosKey || !kontosNameInfo) return false;
    const localPubKey = kontosKey.pubKeyData.compressedPubKey;
    const onChainPubKey = kontosNameInfo.pubKey;
    // recover is success
    console.log("localPubKey", localPubKey);
    console.log("onChainPubKey", onChainPubKey);
    if (localPubKey === onChainPubKey) {
      // go to congratulations page
      setSearchParams({ type: statusRouterMap.congratulations });
      // clear interval and reset recover status
      if (intervalRef.current !== null) {
        window.clearInterval(intervalRef.current);
      }
      // remove kontosName from accountsRecovering
      const accountsRecovering = userStore.accountsRecovering || [];
      const index = accountsRecovering.indexOf(kontosName);
      if (index > -1) {
        accountsRecovering.splice(index, 1);
        userStore.updateAccountsRecovering(accountsRecovering);
      }
      return true;
    }
    return false;
  }, [kontosKey, kontosName, kontosNameInfo, setSearchParams, userStore]);

  const refreshKontosNameInfo = useCallback(async (): Promise<boolean> => {
    if (
      routeStatus === statusRouterMap.waitingAuthorizeKontos ||
      routeStatus === statusRouterMap.waitingAuthorizeMail
    ) {
      await getKontosNameInfo(kontosName);
      return checkIsRecoverSuccess();
    }
    return false;
  }, [checkIsRecoverSuccess, getKontosNameInfo, kontosName, routeStatus]);

  // check recover success
  useEffect(() => {
    if (intervalRef.current !== null) {
      window.clearInterval(intervalRef.current);
    }

    intervalRef.current = window.setInterval(() => {
      try {
        refreshKontosNameInfo();
      } catch (e) {
        console.warn(e);
      }
    }, refreshInterval);

    return () => {
      if (intervalRef.current !== null) {
        window.clearInterval(intervalRef.current);
      }
    };
  }, [refreshInterval, refreshKontosNameInfo]);

  useEffect(() => {
    checkSeed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // check route is in kontosNameValidatePageArr
    if (kontosNameValidatePageArr.includes(routeStatus) && kontosName === "") {
      setSearchParams({
        type: statusRouterMap.normal,
      });
    }
    if (!seed && seedValidatePageArr.includes(routeStatus)) {
      setSearchParams({
        type: statusRouterMap.normal,
      });
    }
  }, [kontosName, routeStatus, seed, setSearchParams]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleGetKontosNameInfo = useCallback(
    debounce(async (kontosName) => {
      console.log("request", kontosName);
      if (!kontosName) {
        return;
      }
      try {
        return await getKontosNameInfo(kontosName);
      } catch (e) {
        console.warn(e);
        setKontosNameInfo(undefined);
      }
    }, 1000),
    []
  );

  useEffect(() => {
    if (!kontosName) return;
    handleGetKontosNameInfo(kontosName);
  }, [handleGetKontosNameInfo, kontosName]);

  /**
   * Create Kontos key by input account name and save it locally
   * Meanwhile, save recovering record locally to mark this account as temporarily unavailable until recovered
   */
  const createAndSaveNewKontosKey = useCallback(
    async (accountName: string) => {
      const key = await KontosKeyHelper.createKontosKey(accountName, seed);
      await userStore.updateKey(key, seed);
      userStore.updateAccountsRecovering([
        ...(userStore.accountsRecovering || []),
        accountName,
      ]);
    },
    [seed, userStore]
  );

  const checkKeyAndGoToRecoverPage = useCallback(
    async (route: string) => {
      if (!kontosName || !seed) return;
      loadingStore.showLoading();
      try {
        // Check if is recovering locally
        const matchIndex = userStore.accountsRecovering?.findIndex(
          (item) => item === kontosName
        );

        // No local recovering record, create & save key
        if (
          matchIndex === -1 ||
          userStore.accountsRecovering?.length === 0 ||
          !userStore.accountsRecovering
        ) {
          await createAndSaveNewKontosKey(kontosName);
        }

        // Check if key is stored locally
        let storeKontosValList = getStorageKontosValList();
        let matchAccountExitInLocalIndex = storeKontosValList.findIndex(
          (item: StorageKontosKey) => item.accountName === kontosName
        );

        // No local key, create & save & update to variable
        if (matchAccountExitInLocalIndex === -1) {
          await createAndSaveNewKontosKey(kontosName);
          // Re check
          storeKontosValList = getStorageKontosValList();
          matchAccountExitInLocalIndex = storeKontosValList.findIndex(
            (item: StorageKontosKey) => item.accountName === kontosName
          );
        }

        // If we still cannot find matching local key, throw error
        if (matchAccountExitInLocalIndex === -1) {
          loadingStore.hideLoading();
          throw new Error("This account does not exist locally");
        }

        await userStore.switchAccount(matchAccountExitInLocalIndex, true);
        await userStore.reconstructKontosCli(seed, true);
        // update the newest kontosKey to statusKeeper
        setKontosKey(userStore.kontosKey as KontosKey);
        const nonce = await getSequence(kontosName);
        setNonce(nonce);
        setSearchParams({
          type: route,
        });
        loadingStore.hideLoading();
      } catch (e) {
        console.warn("handleRecover err", e);
        loadingStore.hideLoading();
        toast({
          text: t("fail: " + e),
          type: "error",
          autoClose: 3000,
        });
      } finally {
        loadingStore.hideLoading();
      }
    },
    [createAndSaveNewKontosKey, kontosName, seed, setSearchParams, userStore]
  );

  const goToSecuritySettingsPage = useCallback(() => {
    // Check if seed is already set, if not, set seed first
    if (seed.length !== 6) {
      setShowPinCodeModal(true);
      return;
    }

    const userEmailArr = kontosNameInfo?.emailGuardians || [];
    const guardianArr = kontosNameInfo?.guardians || [];

    console.log("nameinfo", kontosNameInfo);

    // Only email
    if (userEmailArr.length <= 0 && guardianArr.length > 0) {
      checkKeyAndGoToRecoverPage(statusRouterMap.waitingAuthorizeKontos);
      return;
    }

    // Only guardian
    if (userEmailArr.length > 0 && guardianArr.length <= 0) {
      checkKeyAndGoToRecoverPage(statusRouterMap.waitingAuthorizeMail);
      return;
    }

    // Old password
    if (userEmailArr.length <= 0 && guardianArr.length <= 0) {
      navigate(ROUTE_RECOVER_ACCOUNT_OLD);
      return;
    }

    setSearchParams({
      type: statusRouterMap.securitySettingsRecover,
    });
  }, [
    checkKeyAndGoToRecoverPage,
    kontosNameInfo,
    navigate,
    seed.length,
    setSearchParams,
  ]);

  return (
    <Wrapper ref={wrapperRef}>
      {!withOutHeaderPageArr.includes(routeStatus) && (
        <SheetUpHeader
          handleBack={() => {
            navigate(-1);
          }}
          headStyle={{ justifyContent: "center" }}
          title={t("Recover Wallet")}
          padding="8px"
          rightComponent={
            <HeaderRightComponent
              type={"recover"}
              kontosName={kontosName}
              threshold={kontosNameInfo?.actionThreshold || 1}
            />
          }
        />
      )}

      {routeStatus === statusRouterMap.normal && (
        <NormalRecover
          submitFunction={() => {
            // check if this kontos is in recovering or not
            const isAccountAlreadyAvailable = checkIsAccountAlreadyAvailable(
              kontosName,
              userStore.accountsRecovering,
              userStore.storageKeys
            );

            // If the account user input is already available in local, switch to it and navigate home
            // To be noticed: it doesn't mean the pubkey is matched, we don't check it here
            if (isAccountAlreadyAvailable === true) {
              const storeKontosValList = getStorageKontosValList();
              const matchAccountExitInLocalIndex = storeKontosValList.findIndex(
                (item: StorageKontosKey) => item.accountName === kontosName
              );
              matchAccountExitInLocalIndex !== -1 &&
                userStore.switchAccount(matchAccountExitInLocalIndex);
              toast({
                type: "warning",
                text: accountAlreadyRecoveredToastItem,
              });
              return;
            }

            goToSecuritySettingsPage();
          }}
          kontosName={kontosName}
          setKontosName={setKontosNameTrimed}
        />
      )}

      {routeStatus === statusRouterMap.waitingAuthorizeKontos && (
        <WaitingAuthorizeKontos
          kontosName={kontosName}
          actionThreshold={kontosNameInfo?.actionThreshold || 0}
          kontosKey={kontosKey}
        />
      )}

      {routeStatus === statusRouterMap.waitingAuthorizeMail && (
        <WaitingAuthorizeMail
          kontosName={kontosName}
          userEmailArr={kontosNameInfo?.emailGuardians || []}
          nonce={nonce}
          kontosKey={kontosKey}
          actionThreshold={kontosNameInfo?.actionThreshold || 0}
          emailGuardianHashes={kontosNameInfo?.emailGuardians || []}
          setRefreshInterval={setRefreshInterval}
        />
      )}

      {routeStatus === statusRouterMap.securitySettingsRecover && (
        <SecuritySettingsRecover
          userEmailArr={kontosNameInfo?.emailGuardians || []}
          guardianArr={kontosNameInfo?.guardians || []}
          checkKeyAndGoToRecoverPage={checkKeyAndGoToRecoverPage}
        />
      )}

      {routeStatus === statusRouterMap.congratulations && (
        <Congratulations congratulationType={"recovered"} />
      )}

      <BottomSheet
        isOpen={showPinCodeModal}
        onClose={() => setShowPinCodeModal(false)}
        mountPoint={domNode}
        disableScrollLocking={true}
      >
        <PinCode
          submitFunction={() => {
            setShowPinCodeModal(false);
            goToSecuritySettingsPage();
          }}
          seed={seed}
          setSeed={setSeed}
        />
      </BottomSheet>
    </Wrapper>
  );
};

export default observer(RecoverAccount);
