import { FtAssetV2 } from "@zkkontos/kontos-sdk/src/api/types";
import { AccountStatus } from "@zkkontos/kontos-sdk/src/codec/kontos/aa/v1/aa";

export enum MSGTYPE {
  Register = "register",
  Recover = "recover",
  TradeFutures = "futuresTrading",
  TradeSpots = "spotTrading",
  Send = "send",
}

export enum MSGSTATUS {
  Success = "success",
  Fail = "fail",
}

export interface KontosCtx {
  ctx_key: string;
  cmd_type: string;
  cmd_source: string;
  user_handle: string;
  platform: string;
  isSuccess: boolean;
  isFail: boolean;
  expire_at: string;
  current_kontos_account: string;
  guild: string;
  channel: string;
  path: string;
  trade_opt?: {
    chain_index: string;
    asset_address: string;
    usd_amount: string;
  };
}

export interface KontosNotify {
  ctx_key: string;
  msg_type: MSGTYPE;
  msg_status: MSGSTATUS;
  msg: string; // Operation hash
  data: string; // Specific data, e.g. account name for register
}

export function isKontosCtxExpired(kontosCtx: KontosCtx): boolean {
  const expireAt = new Date(kontosCtx.expire_at);
  const now = new Date();
  return now > expireAt;
}

export function isKontosCtxSuccess(kontosCtx: KontosCtx): boolean {
  return kontosCtx.isSuccess;
}

export function isKontosCtxFail(kontosCtx: KontosCtx): boolean {
  return kontosCtx.isFail;
}

export interface AccountBalancesRes {
  ChainIndex: string;
  ChainName: string;
  ChainImageUrl: string;
  Address: string;
  Balance: string;
}

export interface AssetsRes {
  ChainIndex: string;
  ChainName: string;
  ChainImageUrl: string;
  Address: string;
  Name: string;
  Symbol: string;
  Decimals: string;
  ImageUrl: string;
  UsdPrice: string;
}

export interface BalanceAsset {
  ChainIndex: string;
  ChainName: string;
  ChainImageUrl: string;
  Address: string;
  Balance: string;

  Name: string;
  Symbol: string;
  Decimals: string;
  UsdPrice: string;
  ImageUrl: string;
}

export const getAccountBalanceAsset = (
  abr: AccountBalancesRes[],
  ar: AssetsRes[]
): BalanceAsset[] => {
  let res: BalanceAsset[] = [];

  abr.forEach((a) => {
    const asset = ar.find((r) => {
      const isEqual =
        r.Address.toLowerCase() === a.Address.toLowerCase() &&
        r.ChainIndex.toLowerCase() === a.ChainIndex.toLowerCase();
      return isEqual;
    });

    if (asset) {
      console.log(a);
      const newAsset: BalanceAsset = { ...asset, ...a };
      res.push(newAsset);
    }
  });

  return res;
};

export const findAssetsResByBalanceAsset = (
  balanceAsset: BalanceAsset,
  assetsResArray: AssetsRes[]
): AssetsRes | undefined => {
  return assetsResArray.find(
    (asset) =>
      asset.Address.toLowerCase() === balanceAsset.Address.toLowerCase() &&
      asset.ChainIndex === balanceAsset.ChainIndex
  );
};

export const findAssetsResByBalanceAssets = (
  balanceAssets: BalanceAsset[],
  assetsResArray: AssetsRes[]
): AssetsRes[] => {
  return balanceAssets.reduce((acc: AssetsRes[], balanceAsset) => {
    const matchedAsset = assetsResArray.find(
      (asset) =>
        asset.Address.toLowerCase() === balanceAsset.Address.toLowerCase() &&
        asset.ChainIndex === balanceAsset.ChainIndex
    );
    if (matchedAsset) {
      acc.push(matchedAsset);
    }
    return acc;
  }, []);
};

export const getBalanceAssetByAssetsRes = (
  balanceAssets: BalanceAsset[],
  assetRes: AssetsRes
): BalanceAsset | undefined => {
  return balanceAssets.find(
    (balanceAsset) =>
      balanceAsset.Address.toLowerCase() === assetRes.Address.toLowerCase() &&
      balanceAsset.ChainIndex === assetRes.ChainIndex
  );
};

export const getBalanceAssetsByAssetsRes = (
  balanceAssets: BalanceAsset[],
  assetsRes: AssetsRes[]
): BalanceAsset[] => {
  return balanceAssets.filter((balanceAsset) =>
    assetsRes.some(
      (assetRes) =>
        balanceAsset.Address.toLowerCase() === assetRes.Address.toLowerCase() &&
        balanceAsset.ChainIndex === assetRes.ChainIndex
    )
  );
};

export enum AssetType {
  AssetTypeNone = 0,
  AssetTypeNative = 1,
  AssetTypeERC20 = 2,
  AssetTypeERC721 = 3,
  AssetTypeERC1155 = 4,
}

export enum SingerSendOrReceive {
  Receive = 0,
  Send = 1,
}

export interface AssetChange {
  assetChangeId: number;
  txId: number;
  height: number;
  txHash: string;
  msgTypeUrls: string;
  transferFrom: string;
  transferFromName: string;
  transferTo: string;
  transferToName: string;
  transferAssetAddress: string;
  transferAmount: string;
  transferErc721Index: string;
  transferErc1155: { [key: string]: string };
  transferType: AssetType;
  assetDetail: FtAssetV2;
  singerSendOrReceive: SingerSendOrReceive;
  blockTime: number;
  gasUsed: number;
}

export interface MediaInfo {
  mediaUrl: string;
  imageUrl: string;
}

export interface FtAssetBalance {
  id: string;
  balance: string;
  balanceUsdAmount: string;
}

export interface FtAsset {
  chainIndex: string;
  address: string;
  name: string;
  symbol: string;
  decimals: number;
  imageUrl: string;
  usdPrice: string;
  balance: FtAssetBalance | null;
  isWhitelist: boolean;
}

export interface FtAssetDetail extends FtAsset {
  usdPrice: string;
}

export interface ApiBlockchainAccount {
  aaAddress: `0x${string}`;
  createdAt: number;
}

export interface ApiAccountDetail {
  name: string;
  nameAddress: string;
  kontosAddress: string;
  pubKey: string;
  futurePubKey: string;
  recoveryPubKey: string;
  blockChainAccounts: {
    [key: string]: ApiBlockchainAccount;
  };
  guardians: string[] | null;
  futureGuardians: string[] | null;
  emailGuardians: string[] | null;
  futureEmailGuardians: string[] | null;
  actionThreshold: number;
  futureThreshold: number;
  updateGuardiansDeadline: number;
  accountStatus: AccountStatus;
  inviter: string;
  createdAt: number;
}

export interface ApiAccount {
  name: string;
  nameAddress: string;
  kontosAddress: string;
  createdAt: number;
}

export interface ApiChain {
  chainIndex: string;
  chainSymbol: string;
  chainStatus: number;
  status: number;
  imageURL: string;
  greyImageURL: string;
  explorerLinkURL: string;
  entryPointAddress: string;
  accountFactoryAddress: string;
  ftConnectorAddress: string;
}

export interface ApiActionInfoTransfer {
  receiver: string;
  assetAddress: string;
  assetSymbol: string;
  assetImageUrl: string;
  assetAmount: string;
  totalFeeUsdCost: string;
}

export interface ApiActionInfoBuyGasPayment {
  assetSymbol: string;
  assetImageUrl: string;
  assetAmount: string;
}

export interface ApiActionInfoBuy {
  receiver: string;
  assetAddress: string;
  assetAmount: string;
  assetSymbol: string;
  assetImageUrl: string;
  usdAmount: string;
  gasPayment: ApiActionInfoBuyGasPayment;
}

export interface ApiActionInfoSell {
  receiver: string;
  srcChainIndex: string;
  srcAssetAddress: string;
  srcAssetSymbol: string;
  srcAssetImageUrl: string;
  dstAssetAddress: string;
  dstAssetSymbol: string;
  dstAssetImageUrl: string;
  srcAssetAmount: string;
  dstAssetAmount: string;
  totalFeeUsdCost: string;
}

export interface ApiActionInfoUserOp {
  dstAddress: string;
  totalFeeUsdCost: string;
}

export enum ApiActionType {
  EmptyCall = 0,
  Buy = 1,
  Sell = 2,
  Transfer = 3,
  UserOp = 4,
}

export enum ApiUpdatePubKeyAction {
  UpdatePubKeyActionNone = 0,
  UpdatePubKeyActionNew = 1,
  UpdatePubKeyActionUpdate = 2,
}

export enum ApiTaskStatus {
  Pending = 0,
  Success = 1,
  Fail = 2,
}

export interface ApiTask {
  id: string;
  opHash: string;
  sender: string;
  chainIndex: string;
  actionType: ApiActionType;
  updatePubKeyAction: ApiUpdatePubKeyAction;
  actionInfoTransfer: ApiActionInfoTransfer | null;
  actionInfoBuy: ApiActionInfoBuy | null;
  actionInfoSell: ApiActionInfoSell | null;
  actionInfoUserOp: ApiActionInfoUserOp | null;
  status: ApiTaskStatus;
  createdAt: number;
  failReason: string;
}

export interface ApiCostDetail {
  costType: number;
  desc: string;
  usdCost: string;
}

export type ApiCostDetails = ApiCostDetail[];

export interface ApiPayment {
  chainIndex: string;
  assetAddress: string;
  assetSymbol: string;
  assetImageUrl: string;
  assetAmount: string;
  usdAmount: string;
  assetUsdPrice: string;
}

export type ApiPayments = ApiPayment[];

export interface ApiTaskDetail {
  id: string;
  opHash: string;
  userOpHash: string | null;
  sender: string;
  broker: string;
  chainIndex: string;
  actionType: ApiActionType;
  updatePubKeyAction: ApiUpdatePubKeyAction;
  actionInfoTransfer: ApiActionInfoTransfer | null;
  actionInfoBuy: ApiActionInfoBuy | null;
  actionInfoSell: ApiActionInfoSell | null;
  actionInfoUserOp: ApiActionInfoUserOp | null;
  payments: ApiPayments;
  costDetails: ApiCostDetails;
  status: number;
  createdAt: number;
  failReason: string;
  totalFeeInUsd: string;
  totalPaymentsInUsd: string;
  totalSyncAccountFeeInUsd: string;
}

export enum ApiThresholdActionType {
  NewPubKey = 0,
  NewGuardians = 1,
  NewPubKeyV2 = 2,
  Unrecognized = -1,
}

export enum ApiThresholdActionStatus {
  Pending = 0,
  Accepted = 1,
  Rejected = 2,
  Canceled = 3,
  Unrecognized = -1,
}

export interface ApiThresholdAction {
  createAt: string;
  actionHash: string;
  sender: string;
  executor: string;
  nonce: Long;
  actionType: ApiThresholdActionType;
  actionMsg: string;
  approvals: { [key: string]: number };
  rejects: { [key: string]: number };
  status: ApiThresholdActionStatus;
  txHash: string;
  newPubKey?: string;
}

export interface ApiPaymentConfirmation {
  opHash: string;
  sig: string;
}

export enum ApiEmailRecoveryStatus {
  PENDING = 0,
  HANDLED = 1,
  FAILED = 2,
}

export interface ApiEmailNewPubKeyActionProcess {
  message: string;
  statusCode: ApiEmailRecoveryStatus;
}

export interface ApiUniqueAsset {
  chainIndex: string;
  assetAddress: string;
}

export interface ReqTaskSwapData {
  chainIndex: string;
  src: string;
  dst: string;
  srcAmount: string;
  slippage: string;
  receiver: string;
}

export interface ReqTaskTransfer {
  chainIndex: string;
  assetAddress: string;
  assetAmount: string;
  receiver: string;
}

export interface ReqTaskBuy {
  chainIndex: string;
  assetAddress: string;
  usdAmount: string;
  receiver: string;
  slippage: string;
  quoteAsset: ApiUniqueAsset; // Works when balance insufficient
}

export interface ReqTaskSell {
  sellChainIndex: string;
  sellAssetAddress: string;
  sellAssetAmount: string;
  buyChainIndex: string;
  buyAssetAddress: string;
  receiver: string;
  slippage: string;
}

export interface ApiEVMAssetInfo {
  address: string;
  amount: string;
}

export interface ReqTaskUseOp {
  chainIndex: string;
  targetAddress: string;
  value: string;
  callData: string;
  requiredAssets: ApiEVMAssetInfo[];
}
