import styled from "styled-components";
import { BottomSheet } from "src/components/bottom-sheet/BottomSheet";
import { useStores } from "src/hooks/useStore";
import { SheetView } from "src/store/SheetStore";
import { observer } from "mobx-react";
import Header from "src/components/common/header";
import { VerifyStatus } from "src/store/DappConnectStore";
import { useCallback, useEffect, useState } from "react";
import { WcConnectTip } from "./components/WcConnectTip";
import { WcConnectGeneralInfo } from "./components/WcConnectGeneralInfo";
import { WcConnectDesc } from "./components/WcConnectDesc";
import { BlankFilledArea } from "src/components/blank-area/BlankFilledArea";
import { WcConnectItems } from "./components/WcConnectItems";
import { WcConnectBtns } from "./components/WcConnectBtns";
import { loadingStore } from "src/store/loadingStore";
import { KontosError, wcError } from "src/type/error";
import toast from "src/components/toast/Toast";
import { useSearchParams } from "react-router-dom";

interface Props {
  mountPoint?: Element;
}

export const WcConnectConfirmSheet: React.FC<Props> = observer(
  ({ mountPoint }) => {
    const { sheetStore, dappConnectStore } = useStores();
    const isOpen = sheetStore.sheetVisibility.get(
      SheetView.WcConnect
    ) as boolean;
    const [customHeight, setCustomHeight] = useState(
      dappConnectStore.proposalVerifyStatus === VerifyStatus.DomainMatch
        ? 330
        : undefined
    );

    useEffect(() => {
      if (isOpen === false) {
        dappConnectStore.clearSessionProposal();
      }
    }, [dappConnectStore, isOpen]);

    useEffect(() => {
      if (isOpen)
        setCustomHeight(
          dappConnectStore.proposalVerifyStatus === VerifyStatus.DomainMatch
            ? 330
            : undefined
        );
    }, [dappConnectStore.proposalVerifyStatus, isOpen]);

    return (
      <BottomSheet
        isOpen={isOpen}
        onClose={() => sheetStore.closeSheetByView(SheetView.WcConnect)}
        mountPoint={mountPoint}
        customHeight={customHeight}
      >
        <WcConnectConfirmSheetContent />
      </BottomSheet>
    );
  }
);

const Container = styled.div`
  flex: 1;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;

  .header {
  }

  > .wc-connect-confirm-general-info {
    padding: 0 20px;
  }

  > .tip {
    margin-top: 15.94px;
    padding: 0 20px;
  }

  > .desc {
    margin-top: 16px;
    padding: 0 20px;
  }

  > .items {
    margin-top: 19px;
    padding: 0 24px;
  }

  > .btns {
    padding: 0 20px;
  }
`;

const WcConnectConfirmSheetContent: React.FC = observer(() => {
  const { userStore, dappConnectStore, sheetStore } = useStores();
  const [, setSearchParams] = useSearchParams();
  const account = userStore.accountName;
  const verifyStatus = dappConnectStore.proposalVerifyStatus;
  const dappName =
    dappConnectStore.sessionProposal?.params?.proposer?.metadata?.name;
  const dappIcon =
    dappConnectStore.sessionProposal?.params?.proposer?.metadata?.icons?.[0];
  const dappUrl =
    dappConnectStore.sessionProposal?.params.proposer.metadata.url;
  const dappDesc =
    dappConnectStore.sessionProposal?.params?.proposer?.metadata?.description;

  const handleConnect = useCallback(async () => {
    loadingStore.showLoading();
    try {
      await dappConnectStore.approveSessionProposal();
      setSearchParams();
    } catch (e) {
      if (e instanceof KontosError) {
        if (
          e.name === wcError.wcNoSessionProposal.name ||
          wcError.wcSessionProposalExpired.name ||
          wcError.approveSessionFail.name
        ) {
          toast({
            type: "error",
            text: e.message,
          });
          return;
        }
      }
    } finally {
      loadingStore.hideLoading();
      sheetStore.closeSheetByView(SheetView.WcConnect);
    }
  }, [dappConnectStore, setSearchParams, sheetStore]);

  const handleCancel = useCallback(() => {
    sheetStore.closeSheetByView(SheetView.WcConnect);
  }, [sheetStore]);

  if (verifyStatus === undefined) {
    return <></>;
  }
  return (
    <Container>
      <Header padding="0 20px 20px 20px" title={"Wallet Connect"} />

      {/* General Info */}
      <div className="wc-connect-confirm-general-info">
        <WcConnectGeneralInfo
          type={verifyStatus}
          icon={dappIcon}
          name={dappName}
          url={dappUrl}
        />
      </div>

      {/* Tip */}
      {verifyStatus !== VerifyStatus.DomainMatch && (
        <div className="tip">
          <WcConnectTip type={verifyStatus} />
        </div>
      )}

      {/* Desc */}
      {verifyStatus !== VerifyStatus.DomainMatch && (
        <div className="desc">
          <WcConnectDesc desc={dappDesc} />
        </div>
      )}

      {/* Items */}
      <WcConnectItems className="items" wallet={account!} />

      {/* Blank */}
      <BlankFilledArea />

      {/* Buttons */}
      <div className="btns">
        <WcConnectBtns
          type={verifyStatus}
          onConnect={handleConnect}
          onCancel={handleCancel}
        />
      </div>
    </Container>
  );
});
