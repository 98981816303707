import React from "react";
import styled from "styled-components";
import { useSearchParams } from "react-router-dom";
import { statusRouterMap as statusRouterMapRecovery } from "src/components/start/routerMap";
import { Trans } from "react-i18next";
import { t } from "i18next";
import { TipsV2 } from "./TipsV2";
import { TipsDark } from "./TipsDark";
import { fontBold } from "@/style/style.global";

const Wrapper = styled.div`
  .title,
  .item {
    color: var(--Deep-800, #1a2535);
    font-family: HarmonyOS Sans SC;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 200%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    span {
      color: var(--Kontos-Blue, #413dec);
      ${fontBold};
      font-size: 14px;
      font-style: normal;
      line-height: 200%;
      margin: 0 3px;
    }

    b {
      width: 3px;
      display: inline-block;
      height: 3px;
      border-radius: 50%;
      background: black;
      margin-right: 10px;
    }
  }
`;

const WrapperCreate = styled.div`
  text-align: left;
  font-family: HarmonyOS Sans SC;
  margin-top: -10px;

  .title,
  .item {
    margin-bottom: 16px;
  }

  > div:nth-child(1) {
    margin-top: 16px;
  }

  .title {
    color: var(--Deep-800, #1a2535);
    ${fontBold}
    font-size: 14px;
    font-style: normal;
    line-height: 130%; /* 18.2px */
  }

  .item {
    color: var(--Deep-800, #1a2535);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;

    span {
      color: var(--Kontos-Blue, #413dec);
      ${fontBold}
      font-size: 14px;
      font-style: normal;
      line-height: 130%;
    }
  }
`;

export const TipsTextAddNormal = () => {
  return (
    <Wrapper>
      <Trans i18nKey={"trans-Account ID can only contain the following"}>
        <div className={"title"}>
          Account ID can only contain the following:
        </div>
        <div className={"content"}>
          <div className={"item"}>
            <b />
            Lowercase letters <span>(a-z)</span>
          </div>
          <div className={"item"}>
            <b />
            Digits <span>(0-9)</span>
          </div>
          <div className={"item"}>
            <b />
            Must be at least <span>4</span> characters
          </div>
          <div className={"item"}>
            <b />
            No more than <span>64</span> characters
          </div>
        </div>
      </Trans>
    </Wrapper>
  );
};

export const TipsTextCreate = () => {
  return (
    <WrapperCreate>
      <Trans
        i18nKey={
          "trans-Assigning a trusted guardian is highly recommended to secure your Kontos account."
        }
      >
        <div className={"title"}>
          Assigning a trusted guardian is highly recommended to secure your
          Kontos account.
        </div>
        <div className={"content"}>
          <div className={"item"}>
            Choose someone reliable you can contact for account recovery, or you
            may lose control over your account.
          </div>
          <div className={"item"}>
            <span>
              Avoid setting each other as mutual guardians, as this could lead
              to both accounts being irretrievable.
            </span>
          </div>
        </div>
      </Trans>
    </WrapperCreate>
  );
};

export const TipsReferral = () => {
  return (
    <WrapperCreate>
      <Trans i18nKey={"trans-referral-tips."}>
        <div className={"content"}>
          <div className={"item"}>
            Your commission comes from <span>10%</span> of the transaction fees
            generated by the users you invite and <span>5%</span> of the
            transaction fees generated by the users they (your referrals) invite
            when they make transactions.
          </div>
          <div className={"item"} style={{ marginTop: "-16px" }}>
            <span>You can earn more income by inviting more users.</span>
          </div>
        </div>
      </Trans>
    </WrapperCreate>
  );
};

type IPropsTipsTextRecover = {
  kontosName: string;
  threshold?: number;
};

export const TipsTextRecover = ({
  kontosName,
  threshold,
}: IPropsTipsTextRecover) => {
  const [searchParams] = useSearchParams();
  const routeStatus =
    searchParams.get("type") || statusRouterMapRecovery.normal;

  const renderContent = () => {
    if (routeStatus === statusRouterMapRecovery.waitingAuthorizeMail) {
      return (
        <div className={"content"}>
          {kontosName && (
            <div className={"item"}>
              {t("Current recovery account:")}
              <br />
              <span>{kontosName}.os</span>
            </div>
          )}
          <Trans
            i18nKey={
              "trans-To recover your account, please fill in the security email address"
            }
          >
            <div className={"item"}>
              To recover your account, please fill in the security email address
              associated with your account, and click <span>"Send Email"</span>{" "}
              to send a recovery email to Kontos.
            </div>
            <div className={"item"}>
              Choose <span>"More Options"</span> to manually send the email if
              there is no response.
            </div>
            <div className={"title"}>
              Please do not change any content in the email！
            </div>
            <div className={"item"}>
              *After sending the recovery email, wait around 5 minutes for
              confirmation.
            </div>
          </Trans>
        </div>
      );
    }
    if (routeStatus === statusRouterMapRecovery.waitingAuthorizeKontos) {
      return (
        <div className={"content"}>
          <Trans i18nKey={"trans-recover-tips-guardian"}>
            <div className={"item"}>
              Please click <span>"Copy Public Key"</span> and share it with your
              guardians. Your guardians will need to go to “Account Settings,”
              select “Guardian,” find “Guard for,” choose your account, and
              submit the recovery request using this public key.
            </div>
            <div className={"item"}>
              Account recovery requires approval from any{" "}
              <span>{{ threshold } as any}</span> of your guardians. Please stay
              on this page; your account will be successfully recovered once{" "}
              <span>{{ threshold } as any}</span> guardians have approved the
              request.
            </div>
          </Trans>
        </div>
      );
    }
    return (
      <div className={"content"}>
        {kontosName && (
          <div className={"item"}>
            {t("Current recovery account:")}
            <br />
            <span>{kontosName}.os</span>
          </div>
        )}
        <div className={"title"}>
          {t(
            "Password recovery has been displaced by Email or Guardian recovery in the latest update!"
          )}
        </div>

        <div className={"item"}>
          {t(
            "*Immediately set a guardian or email as safeguard after recovering your account. Accounts without new safeguards may become inaccessible in future updates!"
          )}
        </div>
      </div>
    );
  };

  return <WrapperCreate>{renderContent()}</WrapperCreate>;
};

const TipsBuyMaxAvailableWrapper = styled.div`
  color: var(--Deep-800, #1a2535);
  font-family: "HarmonyOS Sans SC";
  font-size: 14px;
  font-weight: 400;
  line-height: 130%;

  .blue {
    color: var(--Kontos-Blue, #413dec);
    ${fontBold}
  }

  .strong {
    color: var(--Deep-800, #1a2535);
    ${fontBold}
  }
`;

export const TipsBuyMaxAvailable: React.FC = () => {
  return (
    <TipsBuyMaxAvailableWrapper>
      <Trans i18nKey={"trans-tips-buy-max"}>
        <div>
          Kontos calculates the fees required to purchase this asset and shows
          the <span className="blue">maximum USD</span> value of the asset you
          can buy based on your estimated post-fee balance.
          <br />
          <br />
          If your balance is insufficient to cover fees or the cost/asset ratio
          is too high, the maximum available amount will show as 0.
          <br />
          <br />
          <div className="strong">
            *This helps prevent unintentional overspending from fees.
          </div>
        </div>
      </Trans>
    </TipsBuyMaxAvailableWrapper>
  );
};

export const TipsSwapMaxAvailable: React.FC = () => {
  return (
    <TipsBuyMaxAvailableWrapper>
      <Trans i18nKey={"trans-tips-swap-max"}>
        <div>
          Kontos calculates the fees required to swap these two assets and shows
          the <span className="blue">maximum quantity</span> of the asset you
          can use to swap based on your estimated post-fee balance.
          <br />
          <br />
          If your balance is insufficient to cover fees or the cost/asset ratio
          is too high, the maximum available amount will show as 0.
          <br />
          <br />
          <div className="strong">
            *This helps prevent unintentional overspending from fees.
          </div>
        </div>
      </Trans>
    </TipsBuyMaxAvailableWrapper>
  );
};

export const TipsBuyMaxAvailableV2: React.FC = () => {
  return (
    <TipsV2>
      <TipsBuyMaxAvailableWrapper>
        <Trans i18nKey={"trans-tips-buy-max"}>
          <div>
            Kontos calculates the fees required to purchase this asset and shows
            the <span className="blue">maximum USD</span> value of the asset you
            can buy based on your estimated post-fee balance.
            <br />
            <br />
            If your balance is insufficient to cover fees or the cost/asset
            ratio is too high, the maximum available amount will show as 0.
            <br />
            <br />
            <div className="strong">
              *This helps prevent unintentional overspending from fees.
            </div>
          </div>
        </Trans>
      </TipsBuyMaxAvailableWrapper>
    </TipsV2>
  );
};

export const TipsSwapMaxAvailableV2: React.FC = () => {
  return (
    <TipsV2>
      <TipsBuyMaxAvailableWrapper>
        <Trans i18nKey={"trans-tips-swap-max"}>
          <div>
            Kontos calculates the fees required to swap these two assets and
            shows the <span className="blue">maximum quantity</span> of the
            asset you can use to swap based on your estimated post-fee balance.
            <br />
            <br />
            If your balance is insufficient to cover fees or the cost/asset
            ratio is too high, the maximum available amount will show as 0.
            <br />
            <br />
            <div className="strong">
              *This helps prevent unintentional overspending from fees.
            </div>
          </div>
        </Trans>
      </TipsBuyMaxAvailableWrapper>
    </TipsV2>
  );
};

export const TipsEbLeaderBoardRanking: React.FC = () => {
  return <TipsDark>{t("Your ranking is being calculated...")}</TipsDark>;
};
