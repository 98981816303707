import ScrollableTextBox from "src/components/scrollable/ScrollableTextBox";
import styled from "styled-components";
import { CodeBlock } from "react-code-blocks";

const Container = styled.div`
  width: 100%;
`;

const Text = styled.span`
  color: var(--Deep-400, #80868f);
  font-family: "HarmonyOS Sans SC";
  font-size: 14px;
  font-weight: 400;
`;

interface Props {
  className?: string;
  desc?: string;
  data?: Record<string, unknown>;
}

export const WcConnectDesc: React.FC<Props> = ({ className, desc, data }) => {
  return (
    <Container className={className}>
      <ScrollableTextBox maxHeight={250}>
        {data ? (
          <CodeBlock
            codeContainerStyle={{ padding: "0" }}
            showLineNumbers={false}
            text={JSON.stringify(data, null, 2)}
            language="json"
          />
        ) : (
          <Text>{desc}</Text>
        )}
        {/* <Text>{desc}</Text> */}
      </ScrollableTextBox>
    </Container>
  );
};
