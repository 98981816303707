import styled from "styled-components";
import { EbBarSvg } from "./EbBarSvg";
import EbBarTip from "./EbBarTip";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import * as ebTypes from "src/apis/types/energyBoostingTypes";
import { EbBarStage } from "./EbBarStage";
import { ENERGY_BAR_DISTRIBUTIONS, EbStageDistrubution } from "src/config";
import Skeleton from "react-loading-skeleton";
import { getOrbImg } from "src/utils/events/ebHelper";

const Container = styled.div<{ $isPercentage: boolean }>`
  position: relative;

  > .bar-tip {
    position: absolute;
    left: 0;
  }

  > .bar-svg {
    margin-top: ${(props) => (props.$isPercentage ? "35px" : "42px")};
  }

  > .bar-stage {
    position: absolute;
  }
`;

const EbBarStageWrapper = styled.div<{
  $isPercentage: boolean;
  width: number;
  total: EbStageDistrubution;
  index: number;
}>`
  position: absolute;
  top: ${(props) => (props.$isPercentage ? "34px" : "41px")};
  left: ${(props) => {
    const distribution = ENERGY_BAR_DISTRIBUTIONS[props.total];
    const position = distribution[props.index];
    // Ensure that position is defined, otherwise it defaults to 0
    return `${props.width * (position ?? 0)}px`;
  }};
`;

const calculateOffsetForSingleThreshold = (
  energy: number,
  thresholds: ebTypes.EnergyThreshold[],
  width: number
): { offset: number; reachMax: boolean } => {
  let offset = 0;
  const lastIndex = thresholds
    .slice()
    .reverse()
    .findIndex((item) => energy >= item.value);
  const currentThresholdIndex =
    lastIndex >= 0 ? thresholds.length - 1 - lastIndex : 0;
  if (thresholds.length - 1 === currentThresholdIndex) {
    offset = width;
    return { offset, reachMax: true };
  }
  if (energy < thresholds[currentThresholdIndex].value) {
    offset = (width * energy) / thresholds[currentThresholdIndex].value;
  } else {
    offset =
      (width * (energy - thresholds[currentThresholdIndex].value)) /
      (thresholds[currentThresholdIndex + 1].value -
        thresholds[currentThresholdIndex].value);
  }
  return { offset, reachMax: false };
};

const calcualteOffset = (
  energy: number,
  thresholds: ebTypes.EnergyThreshold[],
  distrs: number[],
  width: number
): { offset: number; reachMax: boolean } => {
  let offset = 0;
  const lastIndex = thresholds
    .slice()
    .reverse()
    .findIndex((item) => energy >= item.value);
  const currentThresholdIndex =
    lastIndex >= 0 ? thresholds.length - 1 - lastIndex : 0;
  if (thresholds.length - 1 === currentThresholdIndex) {
    // It means it's bigger than the last threshold
    offset += width * distrs[currentThresholdIndex];
    return { offset, reachMax: true };
  } else {
    if (energy < thresholds[currentThresholdIndex].value) {
      // It means it doesn't reach the first threshold
      offset +=
        (energy / thresholds[currentThresholdIndex].value) *
        distrs[currentThresholdIndex] *
        width;
    } else {
      offset += width * distrs[currentThresholdIndex];
      offset +=
        ((energy - thresholds[currentThresholdIndex].value) /
          (thresholds[currentThresholdIndex + 1].value -
            thresholds[currentThresholdIndex].value)) *
        (width *
          (distrs[currentThresholdIndex + 1] - distrs[currentThresholdIndex]));
    }
    return { offset, reachMax: false };
  }
};

interface Props {
  className?: string;
  energyValue: number;
  prevEnergyValue?: number;
  energyPercent?: number;
  prevEnergyPercent?: number;
  stage: ebTypes.EnergyBarStage;
  animationDuraion: number;
  countUpDuration: number;
  finished?: boolean;
  simplified?: boolean;
  currentThreshold?: ebTypes.EnergyThreshold;
  onClaim?: (
    threshold: number,
    energyOrbRewardType: number,
    energyOrbsRewardCount: number
  ) => void;
  onAnimationComplete?: () => void;
}

const EbBar: React.FC<Props> = memo(
  ({
    className,
    energyValue,
    prevEnergyValue = 0,
    energyPercent,
    prevEnergyPercent = 0,
    stage,
    animationDuraion,
    countUpDuration,
    finished = false,
    simplified = false,
    currentThreshold,
    onClaim,
    onAnimationComplete,
  }) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const [width, setWidth] = useState<number>(0);
    const leftOffset: { offset: number; reachMax: boolean } | undefined =
      useMemo(() => {
        if (width > 0) {
          const offset = simplified
            ? calculateOffsetForSingleThreshold(
                energyValue,
                stage.thresholds,
                width
              )
            : calcualteOffset(
                energyValue,
                stage.thresholds,
                ENERGY_BAR_DISTRIBUTIONS[
                  stage.thresholds.length as EbStageDistrubution
                ],
                width
              );
          //onLeftOffsetUpdate?.(offset);
          return offset;
        }
      }, [energyValue, simplified, stage, width]);

    const leftPercent =
      leftOffset && (leftOffset.reachMax ? 1 : leftOffset.offset / width);
    // There is an error between the left offset of tip and the length of SVG
    // So we need a compensation value here
    // And I assume it's linear, {x = 0, y = 8}, {x = 1, y = 0} => y = -8x + 8
    const tipCompensationValue: number = useMemo(() => {
      if (leftPercent !== undefined) return -8 * leftPercent + 8;
      else return 0;
    }, [leftPercent]);
    const leftDistance = leftOffset && leftOffset.offset + tipCompensationValue;

    useEffect(() => {
      if (ref.current) {
        setWidth(ref.current.offsetWidth);
      }
    }, []);

    return (
      <Container $isPercentage={simplified} className={className} ref={ref}>
        {leftDistance !== undefined && leftPercent !== undefined ? (
          <>
            <EbBarTip
              className="bar-tip"
              leftDistance={leftDistance}
              animationDuraion={animationDuraion}
              countUpStart={simplified ? prevEnergyPercent! : prevEnergyValue}
              countUpEnd={simplified ? energyPercent! : energyValue}
              countUpDuration={countUpDuration}
              finished={finished}
              isPercentage={simplified}
            />

            <EbBarSvg
              className="bar-svg"
              animationDuraion={animationDuraion}
              percent={leftPercent}
              onAnimationComplete={onAnimationComplete}
            />

            {stage.thresholds.map((item, index) => (
              <EbBarStageWrapper
                key={item.value}
                $isPercentage={simplified}
                width={width}
                total={stage.thresholds.length as EbStageDistrubution}
                index={index}
              >
                <EbBarStage
                  orbImg={getOrbImg(item.energyOrbRewardType)}
                  reached={energyValue >= item.value}
                  claimed={item.claimStatus === 1}
                  canClaim={
                    currentThreshold
                      ? item.value === currentThreshold.value
                      : true
                  }
                  ballNum={item.energyOrbsRewardCount}
                  simplified={simplified}
                  onClaim={() =>
                    onClaim?.(
                      item.value,
                      item.energyOrbRewardType,
                      item.energyOrbsRewardCount
                    )
                  }
                />
              </EbBarStageWrapper>
            ))}
          </>
        ) : (
          <Skeleton
            style={{
              height: "106px",
              width: "100%",
            }}
          />
        )}
      </Container>
    );
  }
);

export default EbBar;
