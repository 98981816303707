import Header from "src/components/common/header";
import { t } from "i18next";
import styled from "styled-components";
import FloatingButton from "src/pages/trade/FloatingButton";
import addIcon from "src/assets/icons/add3.svg";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { KontosQueryCli } from "@zkkontos/kontos-sdk";
import avatarIcon from "src/assets/icons/settings/avatar.svg";
import KontosNumber from "src/utils/KontosNumber";
import {
  DEFAULT_DECIMAL,
  DEFAULT_DISPLAY_PRECESION,
  LOCAL_ACCOUNT_QUANTITY_LIMIT,
} from "src/config";
import {
  isSameAddress,
  isSameKontosName,
  prioritizeItems,
} from "src/utils/zkkontosHelper";
import toast from "src/components/toast/Toast";
import { loadingStore } from "src/store/loadingStore";
import { useStores } from "src/hooks/useStore";
import { callAccountsBalances } from "@/apis/asset-apis";
import { fetchAaAccounts } from "@/service/account-service";
import { useKontosConnector } from "@/hooks/useKontosConnect";
import tipsIcon from "src/assets/icons/trade/tip.svg";
import { fontBold, fontDescription } from "@/style/style.global";
import AddAccount from "../../settings/account/switch-account/AddAccount";
import { BottomSheet } from "@/components/bottom-sheet/BottomSheet";
import { observer } from "mobx-react";
import {
  isAccountPubKeyException,
  isAccountRecovering,
} from "@/store/storeHelper";
import { useNavigate } from "react-router-dom";
import { ROUTE_RECOVER_ACCOUNT } from "@/router/router-config";
import { callAccountsPubKey } from "@/apis/aa-apis";
import localKeeper from "@/store/localKeeper";
import { AccountItem } from "@/components/account-item/AccountItem";
import { PopupModal } from "@/components/popups/PopupModal";
import NoDataV2 from "@/components/no-data/NoDataV2";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  overflow: hidden;
  padding: 16px 16px 82px 16px;
  height: 100%;
`;

const ConnectionTips = styled.div`
  padding: 8px 10px 8px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const TipsIcon = styled.img`
  width: 28px;
  height: 16px;
`;

const TipsText = styled.span`
  overflow: hidden;
  color: var(--Deep-400, #80868f);
  text-align: center;
  text-overflow: ellipsis;

  ${fontDescription}
`;

const TipsTextStrong = styled.span`
  ${fontBold}
  color: var(--Kontos-Blue, #413dec);
`;

const Scrollable = styled.div`
  width: 100%;

  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;

  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledNoDataV2 = styled(NoDataV2)`
  justify-content: flex-start;
  padding-top: 16px;
`;

const AddButton = styled(FloatingButton)`
  position: fixed;
  bottom: 16px;
  width: min(335px, 90%);
  height: 50px;
  z-index: 2;
  margin: 0 auto;
`;

type iDisplayAccount = {
  index: number;
  usdAmount: string | undefined;
  name: string;
  address: string;
  recovering: boolean;
};

type iDisplayAccountBalance = {
  account: string;
  usdAmount: string;
};

const updateAccountsWithBalance = (
  _accountsBalances: iDisplayAccountBalance[],
  _accounts: iDisplayAccount[]
): iDisplayAccount[] => {
  const newAccounts = _accounts.map((account) => {
    const accountBalance = _accountsBalances.find((ab) =>
      isSameAddress(ab.account, account.name)
    );
    if (accountBalance) {
      return {
        ...account,
        usdAmount: accountBalance.usdAmount,
      };
    }
    return account;
  });
  return newAccounts;
};

interface IProps {
  onDone: () => void;
}

export const ChooseAccount: React.FC<IProps> = observer(({ onDone }) => {
  const navigate = useNavigate();
  const { openerOrigin, rejectConnection, approveConnection, route } =
    useKontosConnector();
  const { userStore } = useStores();
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const domNode = wrapperRef.current as Element | undefined;
  const [accountsBalances, setAccountsBalances] = useState<
    iDisplayAccountBalance[]
  >([]);
  const [pubKeyCheckRecord, setPubKeyCheckRecord] =
    useState<Record<string, string>>();
  const [showAddAccountSheet, setShowAddAccountSheet] =
    useState<boolean>(false);
  const [showResetModal, setShowResetModal] = useState<boolean>(false);
  const [toResetAccount, setToResetAccount] = useState<string>();
  const currentAccounts: iDisplayAccount[] = useMemo(() => {
    return localKeeper.getAllStorageKontosKey()?.map((item, index) => {
      return {
        index: index,
        name: item.accountName,
        address: KontosQueryCli.nameAddress(item.accountName),
        usdAmount: undefined,
        recovering: userStore.accountsRecovering?.includes(item.accountName),
      };
    });
  }, [userStore.accountsRecovering]);
  const accounts: iDisplayAccount[] = useMemo(() => {
    let res = currentAccounts;
    if (accountsBalances.length > 0) {
      res = updateAccountsWithBalance(accountsBalances, res);
    }
    res = prioritizeItems(res, userStore.accountInfo?.name!);
    return res;
  }, [accountsBalances, currentAccounts, userStore.accountInfo?.name]);

  // Check balances & pubKeys when mounted
  useEffect(() => {
    // init balances, not blocking
    const initBalancesAsync = async (accounts: string[]) => {
      try {
        const { accountUsdBalances } = await callAccountsBalances({ accounts });
        const accountsBalancesIDisplay =
          Object.entries(accountUsdBalances).map((item) => {
            return { account: item[0], usdAmount: item[1] };
          }) || [];
        setAccountsBalances(accountsBalancesIDisplay);
      } catch (e) {
        console.warn(e);
      }
    };

    // check pubKeys, blocking
    const checkPubKeysAsync = async (accounts: string[]) => {
      try {
        loadingStore.showLoading();
        const { data } = await callAccountsPubKey({ accounts });
        setPubKeyCheckRecord(data);
      } catch (e) {
        console.warn(e);
      } finally {
        loadingStore.hideLoading();
      }
    };

    const accounts = localKeeper
      .getAllStorageKontosKey()
      .map((item) => item.accountName);
    if (accounts.length === 0) {
      return;
    }
    initBalancesAsync(accounts);
    checkPubKeysAsync(accounts);
  }, []);

  /**
   * select an available account as the connection object
   * unavailable accounts should be blocked in progress
   */
  const handleAccountClick = useCallback(
    async (account: iDisplayAccount) => {
      if (isAccountRecovering(account.name)) {
        userStore.updateRouteAfterAuth(route);
        navigate(`${ROUTE_RECOVER_ACCOUNT}?name=${account.name}`);
        return;
      }

      if (isAccountPubKeyException(account.name, pubKeyCheckRecord)) {
        setShowResetModal(true);
        setToResetAccount(account.name);
        return;
      }

      try {
        loadingStore.showLoading();
        // fetch aa address arr
        let aaAccounts = {};
        if (isSameKontosName(account.name, userStore.accountName)) {
          aaAccounts = userStore.aaAccounts;
        } else {
          aaAccounts = await fetchAaAccounts(account.name);
        }

        if (Object.keys(aaAccounts).length === 0) {
          throw new Error(t("Failed to obtain AA accounts"));
        }
        // approve connection
        await approveConnection(account.name, aaAccounts);
      } catch (e) {
        const errorMessage = e instanceof Error ? e.message : t("System error");
        toast({
          type: "error",
          text: errorMessage,
        });
        return;
      } finally {
        loadingStore.hideLoading();
      }
    },
    [approveConnection, navigate, pubKeyCheckRecord, route, userStore]
  );

  const handleResetBtnClick = useCallback(() => {
    if (!toResetAccount) {
      return;
    }

    userStore.removeAccount(toResetAccount);
    userStore.updateRouteAfterAuth(route);
    navigate(`${ROUTE_RECOVER_ACCOUNT}?name=${toResetAccount}`);
    setShowResetModal(false);
    return;
  }, [navigate, route, toResetAccount, userStore]);

  const handleAddBtnClick = useCallback(() => {
    if (accounts.length >= LOCAL_ACCOUNT_QUANTITY_LIMIT) {
      toast({
        type: "warning",
        text: t(
          "Your local account limit is almost reached. Please consider removing some accounts to proceed."
        ),
      });
      return;
    }
    setShowAddAccountSheet(true);
  }, [accounts.length]);

  return (
    <Container ref={wrapperRef}>
      <Header
        title={t("Choose an Account")}
        padding="8px"
        rightBtnText={t("Cancel")}
        rightBtnCallBack={rejectConnection}
      />

      <ConnectionTips>
        <TipsIcon src={tipsIcon} alt="" />
        <TipsText>
          {t("Requesting to connect to")}{" "}
          <TipsTextStrong>
            {openerOrigin ? new URL(openerOrigin).host : "-"}
          </TipsTextStrong>
        </TipsText>
      </ConnectionTips>

      <Scrollable>
        {accounts?.length > 0 ? (
          accounts.map((account) => (
            <AccountItem
              key={account.name}
              recovering={account.recovering}
              pubKeyException={isAccountPubKeyException(
                account.name,
                pubKeyCheckRecord
              )}
              name={account.name}
              avatar={avatarIcon}
              balanceText={
                account.usdAmount
                  ? new KontosNumber(
                      account.usdAmount,
                      DEFAULT_DECIMAL
                    ).toFormat(DEFAULT_DISPLAY_PRECESION) + " USD"
                  : undefined
              }
              onClick={() => {
                handleAccountClick(account);
              }}
            />
          ))
        ) : (
          <StyledNoDataV2 text={t("No account found.")} />
        )}
      </Scrollable>

      <AddButton
        fixedIconRight={false}
        onClick={handleAddBtnClick}
        fixedIcon={addIcon}
      >
        {t("Add Account")}
      </AddButton>

      <BottomSheet
        isOpen={showAddAccountSheet}
        onClose={() => setShowAddAccountSheet(false)}
        mountPoint={domNode}
        detent="content-height"
        customHeight={330}
      >
        <AddAccount targetRoute={route} />
      </BottomSheet>

      {/* pub key exception modal */}
      {showResetModal && (
        <PopupModal
          type="error"
          onConfirm={handleResetBtnClick}
          onCancel={(event) => {
            setShowResetModal(false);
          }}
          onOutClick={(event) => {
            setShowResetModal(false);
          }}
          title={t("Account Warning !")}
          desc={t(
            "Some issues with your public key, the account can not make transactions any more."
          )}
          subDesc={"*Please reset and recover your account first."}
          btnTxt={t("Reset Now") as string}
          cancelBtnText={t("Later") as string}
        />
      )}
    </Container>
  );
});
