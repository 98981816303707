import styled from "styled-components";
import { useTranslation } from "react-i18next";
import React from "react";
import { KontosButton } from "src/components/button/KontosButton";
import Modal from "src/components/modal/Modal";
import { fontH5, fontH7, fontMainText } from "@/style/style.global";
import warningIcon from "src/assets/icons/warn1.svg";

const Container = styled.div`
  width: 343px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const WarningIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const Title = styled.span`
  color: var(--Warning, #faad14);
  text-align: center;
  ${fontH5}
`;

const Content = styled.div`
  color: var(--Deep-800, #1a2535);
  text-align: center;
  ${fontMainText}
`;

const GotItButton = styled(KontosButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  color: var(--White, #fff);
  text-align: center;
  ${fontH7}
`;

interface IProps {
  onClose: () => void;
}

export const GuardianWarningModal: React.FC<IProps> = ({ onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal onClose={onClose}>
      <Container>
        <MainContainer>
          <WarningIcon src={warningIcon} alt="" />
          <Title>{t("Warning!")}</Title>
          <Content>
            {t(
              "Choose someone reliable you can contact for account recovery, or you may lose control over your account."
            )}
          </Content>
        </MainContainer>

        <GotItButton onClick={onClose}>{t("Got it")}</GotItButton>
      </Container>
    </Modal>
  );
};
