import { ROUTE_HOME } from "@/router/router-config";
import {
  isValidOrigin,
  toastErrorMsg,
  uint8ArrayToBase64,
} from "@/utils/helper";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useStores } from "./useStore";
import localKeeper, { getStorageKontosValList } from "@/store/localKeeper";
import { KontosQueryCli, StorageKontosKey } from "@zkkontos/kontos-sdk";
import { isSameKontosName } from "@/utils/zkkontosHelper";
import { useTranslation } from "react-i18next";
import toast from "@/components/toast/Toast";
import { KONTOS_CONNECT_WHITELIST } from "@/pages/connect/connectConfig";
import { callAccountsPubKey } from "@/apis/aa-apis";
import { loadingStore } from "@/store/loadingStore";
import { fetchUserOpPaymentTask } from "@/service/trade-service";
import { RespTaskPayment } from "@zkkontos/kontos-sdk/src/api/paymentApi";
import { enhancedFromHex } from "@zkkontos/kontos-sdk/src/core/utils";

const SEARCH_PARAM_OPENER_ORIGIN = "openerOrigin";

export type KontosWalletData = {
  name: string;
  nameAddress: string;
  aaAccounts: Record<string, `0x${string}`>;
};

export enum ReqType {
  Connect = "connect",
  SendTransaction = "sendTransaction",
  SignMessage = "signMessage",
}

export enum RespType {
  RequestReceived = "requestReceived",
  ApproveConnection = "approveConnection",
  RejectConnection = "rejectConnection",
  TxSubmittedSuccessfully = "txSubmittedSuccessfully",
  TxSubmittedFailed = "txSubmittedFailed",
  SignMessageSuccessfully = "msgSignedSuccessfully",
  SignMessageFailed = "msgSignedFailed",
}

export type SendTransactionPayload = {
  account: string;
  chainId: string;
  to: string;
  data: `0x${string}`;
  value: string;
};

export type SignMessagePayload = {
  account: string;
  message: string;
};

export type KontosConnectorRequest =
  | {
      type: ReqType.Connect;
      payload?: never;
    }
  | {
      type: ReqType.SendTransaction;
      payload: SendTransactionPayload;
    }
  | {
      type: ReqType.SignMessage;
      payload: SignMessagePayload;
    };

export type KontosConnectorResponse =
  | { type: RespType.RequestReceived; payload: ReqType }
  | {
      type: RespType.ApproveConnection;
      payload: KontosWalletData;
    }
  | { type: RespType.RejectConnection; payload: { reason: string } }
  | {
      type: RespType.TxSubmittedSuccessfully;
      payload: { userOpHash: `0x${string}` };
    }
  | { type: RespType.TxSubmittedFailed; payload: { reason: string } }
  | {
      type: RespType.SignMessageSuccessfully;
      payload: { signature: string; expiredAt: number };
    }
  | { type: RespType.SignMessageFailed; payload: { reason: string } };

const useKontosConnectValue = () => {
  const { t } = useTranslation();
  const { pathname, search, hash } = useLocation();
  const { userStore } = useStores();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const openerOriginRaw = searchParams.get(SEARCH_PARAM_OPENER_ORIGIN);
  const openerOrigin = !!openerOriginRaw
    ? decodeURIComponent(openerOriginRaw)
    : null;
  const openerHost = openerOrigin ? new URL(openerOrigin).hostname : null;
  const [availableAccounts, setAvailableAccounts] =
    useState<StorageKontosKey[]>();
  const [initialized, setInitialized] = useState<boolean>(false);
  const [requestType, setRequestType] = useState<ReqType | undefined>(
    userStore.connectorReqType
  );
  const [route] = useState(`${pathname}${search}${hash}`);
  const [txPayload, setTxPayload] = useState<SendTransactionPayload>();
  const [signPayload, setSignPayload] = useState<SignMessagePayload>();
  const [taskData, setTaskData] = useState<RespTaskPayment>();

  useEffect(() => {
    const initialize = async () => {
      if (
        !window.opener ||
        typeof openerOrigin !== "string" ||
        !isValidOrigin(openerOrigin) ||
        !KONTOS_CONNECT_WHITELIST.some((pattern) => pattern.test(openerOrigin))
      ) {
        // A valid opener is necessary for connecting to Kontos wallet
        console.log("initialize branch 1");
        !!window.opener ? window.close() : navigate(ROUTE_HOME);
        setInitialized(true);
        return;
      }

      const accounts = localKeeper.getAllStorageKontosKey();
      if (accounts.length === 0) {
        console.log("initialize branch 2");
        userStore.updateRouteAfterAuth(`${pathname}${search}${hash}`);
        setInitialized(true);
        return;
      }

      try {
        console.log("initialize branch 3");
        loadingStore.showLoading();
        const { data } = await callAccountsPubKey({
          accounts: accounts.map((item) => item.accountName),
        });
        const checkRes = accounts.filter(
          (item) =>
            data[item.accountName]?.toLowerCase() ===
            item.pubKeyData.compressedPubKey?.toLowerCase()
        );
        setAvailableAccounts(checkRes);
        setInitialized(true);
      } catch (e) {
        const errorMessage = e instanceof Error ? e.message : t("System error");
        toast({
          type: "error",
          text: errorMessage,
        });
        return;
      } finally {
        console.log("initialize branch 4");
        loadingStore.hideLoading();
      }
    };

    if (initialized === false) initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const rejectSendingTx = useCallback(
    (reason?: string) => {
      if (!openerOrigin) {
        console.warn("Unrecognized communication source");
        window.close();
        return;
      }

      window.opener.postMessage(
        {
          type: RespType.TxSubmittedFailed,
          payload: {
            reason: reason || "user rejected the request",
          },
        } as KontosConnectorResponse,
        openerOrigin
      );

      setTimeout(() => {
        window.close();
      }, 3000);
    },
    [openerOrigin]
  );

  const rejectSigingMsg = useCallback(
    (reason?: string) => {
      if (!openerOrigin) {
        console.warn("Unrecognized communication source");
        window.close();
        return;
      }

      window.opener.postMessage(
        {
          type: RespType.SignMessageFailed,
          payload: {
            reason: reason || "user rejected the request",
          },
        } as KontosConnectorResponse,
        openerOrigin
      );

      setTimeout(() => {
        window.close();
      }, 3000);
    },
    [openerOrigin]
  );

  const checkForSendingTx = useCallback(
    async (payload: SendTransactionPayload) => {
      if (txPayload !== undefined) {
        return;
      }

      setTxPayload(payload);
      const storeKontosValList = localKeeper.getAllStorageKontosKey();
      const matchAccountExitInLocalIndex = storeKontosValList.findIndex(
        (item: StorageKontosKey) =>
          isSameKontosName(item.accountName, payload.account)
      );

      if (matchAccountExitInLocalIndex === -1) {
        toast({
          type: "error",
          text: (
            <>
              Unable to find a matching account locally (
              <span
                style={{
                  color: "var(--Kontos-Blue, #413dec)",
                  fontWeight: 700,
                }}
              >
                ${payload.account}.os
              </span>
              , please disconnect from dapp and try to connect again )
            </>
          ),
        });
        setTimeout(() => {
          rejectSendingTx("account not exists");
        }, 5000);
        return;
      }

      await userStore.switchAccount(matchAccountExitInLocalIndex);

      // fetch & set task data
      try {
        loadingStore.showLoading();
        const resp = await fetchUserOpPaymentTask(
          payload.account,
          payload.chainId,
          payload.to,
          payload.value,
          uint8ArrayToBase64(enhancedFromHex(payload.data))
        );
        setTaskData(resp);
      } catch (e) {
        const errMsg = toastErrorMsg(e);
        setTimeout(() => {
          rejectSendingTx(errMsg);
        }, 5000);
      } finally {
        loadingStore.hideLoading();
      }

      setRequestType(ReqType.SendTransaction);
    },
    [rejectSendingTx, txPayload, userStore]
  );

  const checkForSignMsg = useCallback(
    async (payload: SignMessagePayload) => {
      if (signPayload !== undefined) {
        return;
      }

      setSignPayload(payload);
      const storeKontosValList = localKeeper.getAllStorageKontosKey();
      const matchAccountExitInLocalIndex = storeKontosValList.findIndex(
        (item: StorageKontosKey) =>
          isSameKontosName(item.accountName, payload.account)
      );

      if (matchAccountExitInLocalIndex === -1) {
        toast({
          type: "error",
          text: (
            <>
              Unable to find a matching account locally (
              <span
                style={{
                  color: "var(--Kontos-Blue, #413dec)",
                  fontWeight: 700,
                }}
              >
                ${payload.account}.os
              </span>
              , please disconnect from dapp and try to connect again )
            </>
          ),
        });
        setTimeout(() => {
          rejectSigingMsg("account not exists");
        }, 5000);
        return;
      }

      await userStore.switchAccount(matchAccountExitInLocalIndex);

      setRequestType(ReqType.SignMessage);
    },
    [rejectSigingMsg, signPayload, userStore]
  );

  useEffect(() => {
    const handleMessage = (event: MessageEvent<KontosConnectorRequest>) => {
      try {
        // Verify the source of the message
        // console.log("openerOrigin", openerOrigin);
        // console.log("event.origin", event.origin);
        // console.log("equal", openerOrigin === event.origin);
        // console.log("event", event);
        if (event.origin !== openerOrigin) {
          console.warn("Received message from unknown origin:", event.origin);
          return;
        }

        const { type, payload } = event.data || {};

        switch (type) {
          case ReqType.Connect:
            setRequestType(ReqType.Connect);
            userStore.setConnectorReqType(ReqType.Connect);
            window.opener.postMessage(
              {
                type: RespType.RequestReceived,
                payload: ReqType.Connect,
              } as KontosConnectorResponse,
              openerOrigin
            );
            break;
          case ReqType.SendTransaction:
            window.opener.postMessage(
              {
                type: RespType.RequestReceived,
                payload: ReqType.SendTransaction,
              } as KontosConnectorResponse,
              openerOrigin
            );
            checkForSendingTx(payload);
            break;
          case ReqType.SignMessage:
            window.opener.postMessage(
              {
                type: RespType.RequestReceived,
                payload: ReqType.SignMessage,
              } as KontosConnectorResponse,
              openerOrigin
            );
            checkForSignMsg(payload);
            break;
          default:
            console.warn("Unhandled message type:", type);
            break;
        }
      } catch (error) {
        console.error("Error handling message:", error);
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [checkForSendingTx, checkForSignMsg, navigate, openerOrigin, userStore]);

  /**
   * approve connection to input account
   * this func doesn't check account's state & availablity
   */
  const approveConnection = useCallback(
    async (name: string, aaAccounts: Record<string, `0x${string}`>) => {
      if (!openerOrigin) {
        toast({
          type: "error",
          text: t("Unrecognized host source"),
        });
        return;
      }

      const storeKontosValList = getStorageKontosValList();
      const matchAccountExitInLocalIndex = storeKontosValList.findIndex(
        (item: StorageKontosKey) => isSameKontosName(item.accountName, name)
      );
      // switch to connecting account
      matchAccountExitInLocalIndex !== -1 &&
        userStore.switchAccount(matchAccountExitInLocalIndex);
      // save to localStorage
      localKeeper.saveConnection(name, openerOrigin);
      // respond to opener
      console.log("window.parent", window.parent);
      console.log("openerOrigin", openerOrigin);

      window.opener.postMessage(
        {
          type: RespType.ApproveConnection,
          payload: {
            name,
            nameAddress: KontosQueryCli.nameAddress(name),
            aaAccounts,
          } as KontosWalletData,
        } as KontosConnectorResponse,
        openerOrigin
      );

      toast({
        type: "success",
        text: t("Connection successful!"),
      });

      // setTimeout(() => {
      //   window.close();
      // }, 5000);
    },
    [openerOrigin, t, userStore]
  );

  const rejectConnection = useCallback(
    (reason?: string) => {
      if (!openerOrigin) {
        console.warn("Unrecognized communication source");
        setTimeout(() => {
          window.close();
        }, 3000);
        return;
      }

      window.opener.postMessage(
        {
          type: RespType.RejectConnection,
          payload: { reason: reason || "user rejected the request" },
        } as KontosConnectorResponse,
        openerOrigin
      );

      setTimeout(() => {
        window.close();
      }, 3000);
    },
    [openerOrigin]
  );

  const onSigningMsgSuccess = useCallback(
    async (signature: string, expiredAt: number) => {
      if (!openerOrigin) {
        toast({
          type: "error",
          text: t("Unrecognized host source"),
        });
        setTimeout(() => {
          window.close();
        }, 5000);
        return;
      }

      window.opener.postMessage(
        {
          type: RespType.SignMessageSuccessfully,
          payload: { signature, expiredAt },
        } as KontosConnectorResponse,
        openerOrigin
      );

      toast({
        type: "success",
        text: t("Successfully signed!"),
      });

      setTimeout(() => {
        window.close();
      }, 5000);
    },
    [openerOrigin, t]
  );

  const onSendingTxSuccess = useCallback(
    async (userOpHash?: string) => {
      if (!openerOrigin) {
        toast({
          type: "error",
          text: t("Unrecognized host source"),
        });
        setTimeout(() => {
          window.close();
        }, 5000);
        return;
      }

      // respond to opener
      window.opener.postMessage(
        {
          type: RespType.TxSubmittedSuccessfully,
          payload: { userOpHash: userOpHash || "" },
        } as KontosConnectorResponse,
        openerOrigin
      );

      setTimeout(() => {
        window.close();
      }, 5000);
    },
    [openerOrigin, t]
  );

  return {
    initialized,
    requestType,
    openerOrigin,
    availableAccounts,
    approveConnection,
    rejectConnection,
    route,
    onSigningMsgSuccess,
    rejectSendingTx,
    rejectSigingMsg,
    openerHost,
    onSendingTxSuccess,
    taskData,
    txPayload,
    signPayload,
  };
};

type KontosConnectorContextValue = ReturnType<typeof useKontosConnectValue>;

const KontosConnectorContext = createContext<
  KontosConnectorContextValue | undefined
>(undefined);

interface KontosConnectorProviderProps {
  children: React.ReactNode;
}

export const KontosConnectorProvider: React.FC<
  KontosConnectorProviderProps
> = ({ children }) => {
  const kontosConnectData = useKontosConnectValue();

  return (
    <KontosConnectorContext.Provider value={kontosConnectData}>
      {children}
    </KontosConnectorContext.Provider>
  );
};

export const useKontosConnector = () => {
  const context = useContext(KontosConnectorContext);
  if (!context) {
    throw new Error(
      "useKontosConnectorContext must be used within KontosConnectorProvider"
    );
  }
  return context;
};
