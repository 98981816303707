interface IProps {
  color: "grey" | "blue" | "red" | "yellow";
}

export const AccountArrow: React.FC<IProps> = ({ color }) => {
  const strokeColor =
    color === "blue"
      ? "var(--Kontos-Blue, #413DEC)"
      : color === "yellow"
        ? "var(--Warning, #FAAD14)"
        : color === "red"
          ? "var(--error-notice, #FF1E2B)"
          : "#80868F";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M8.05025 12.9497L13 8L8.05025 3.05025"
        stroke={strokeColor}
        strokeLinecap="round"
      />
    </svg>
  );
};
