import Header from "src/components/common/header";
import { t } from "i18next";
import styled from "styled-components";
import RadioButtonV3 from "src/components/button/RadioButtonV3";
import { useCallback, useState } from "react";
import { KontosButton } from "src/components/button/KontosButton";
import { useNavigate } from "react-router-dom";
import {
  ROUTE_CREATE_ACCOUNT,
  ROUTE_RECOVER_ACCOUNT,
  ROUTE_MULTI_ACCOUNTS_PIN_VERIFIVER,
} from "src/router/router-config";
import { useStores } from "src/hooks/useStore";

const Container = styled.div`
  margin-top: -20px;

  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const RadioButtonWrapper = styled.div`
  margin-top: 20px;

  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 19px;
`;

const Scrollable = styled.div`
  flex: 1;
  overflow: auto;
`;

const ButtonWrapper = styled.div`
  width: 89%;
  margin: 0 auto;
  margin-bottom: 16px;
`;

interface IProps {
  targetRoute?: string;
}

const AddAccount: React.FC<IProps> = ({ targetRoute }) => {
  const { userStore } = useStores();
  const navigate = useNavigate();
  const [selected, setSelected] = useState<"create" | "recover">("create");

  const handleContinueClick = useCallback(() => {
    if (selected === "create") {
      userStore.updateRouteAfterUnlock(ROUTE_CREATE_ACCOUNT);
      userStore.updateRouteAfterAuth(targetRoute);
    }
    if (selected === "recover") {
      userStore.updateRouteAfterUnlock(ROUTE_RECOVER_ACCOUNT);
      userStore.updateRouteAfterAuth(targetRoute);
    }
    navigate(ROUTE_MULTI_ACCOUNTS_PIN_VERIFIVER);
  }, [navigate, selected, targetRoute, userStore]);

  return (
    <Container>
      <Header title={t("Choose account type to add")} />
      <RadioButtonWrapper>
        <RadioButtonV3
          selected={selected === "create"}
          text={t("New account")}
          onClick={() => {
            setSelected("create");
          }}
        />
        <RadioButtonV3
          selected={selected === "recover"}
          text={t("I already have an account")}
          onClick={() => {
            setSelected("recover");
          }}
        />
      </RadioButtonWrapper>
      <Scrollable></Scrollable>
      <ButtonWrapper>
        <KontosButton onClick={handleContinueClick}>
          {t("Continue")}
        </KontosButton>
      </ButtonWrapper>
    </Container>
  );
};

export default AddAccount;
