import { Chain, FtAssetBase } from "@/type/zkkontos";
import { rootStore } from "..";
import { FtAsset } from "@/apis/types";
import { isSameFtAsset, isSameKontosName } from "@/utils/zkkontosHelper";
import { isSamePubKey } from "@/utils/helper";
import localKeeper from "./localKeeper";
import { callAccountsPubKey } from "@/apis/aa-apis";
import { t } from "i18next";
import { UniformedPayment } from "@zkkontos/kontos-sdk/src/api/types";
import { DEFAULT_DECIMAL } from "@/config";
import KontosNumber from "@/utils/KontosNumber";
import defaultChainIcon from "src/assets/icons/trade/default-chain.svg";

export const getChain = (chainIndex: string | undefined): Chain | undefined => {
  return rootStore.chainStore.chains.find(
    (chain) => chain.chainIndex === chainIndex
  );
};

export const getChainByAsset = (
  ftAsset: FtAsset | undefined
): Chain | undefined => {
  return rootStore.chainStore.chains.find(
    (chain) => chain.chainIndex === ftAsset?.chainIndex
  );
};

export const isSameChainAsset = (assetA: FtAsset, assetB: FtAsset): boolean => {
  const chainA = getChainByAsset(assetA);
  const chainB = getChainByAsset(assetB);
  if (
    typeof chainA !== "undefined" &&
    typeof chainB !== "undefined" &&
    chainA.chainIndex === chainB.chainIndex
  ) {
    return true;
  }
  return false;
};

export const filterUserHoldings = (
  assets: FtAssetBase[],
  useTradeStore?: boolean
): FtAsset[] => {
  if (useTradeStore)
    return rootStore.tradeStore.accountBalances.filter((item) =>
      assets.some((subItem) => isSameFtAsset(subItem, item))
    );
  return rootStore.userStore.accountBalances.filter((item) =>
    assets.some((subItem) => isSameFtAsset(subItem, item))
  );
};

export const findUserHolding = (
  asset: FtAssetBase,
  useTradeStore?: boolean
): FtAsset | undefined => {
  if (useTradeStore)
    return rootStore.tradeStore.accountBalances.find((item) =>
      isSameFtAsset(asset, item)
    );

  return rootStore.userStore.accountBalances.find((item) =>
    isSameFtAsset(asset, item)
  );
};

export const convertFtAssetToUniformedPayment = (
  asset: FtAsset
): UniformedPayment => {
  return {
    ...asset,
    assetAddress: asset.address,
    assetAmount: new KontosNumber(
      asset.balance?.balanceUsdAmount,
      DEFAULT_DECIMAL
    )
      .divide(asset.usdPrice, DEFAULT_DECIMAL)
      .toString(),
    usdAmount: asset.balance?.balanceUsdAmount || "0",
    assetName: asset.name,
    assetSymbol: asset.symbol,
    assetImageUrl: asset.imageUrl,
    assetDecimals: asset.decimals,
    assetUsdPrice: asset.usdPrice,
    chainSymbol: getChainByAsset(asset)?.chainSymbol || "-",
    chainImageUrl: getChainByAsset(asset)?.imageURL || defaultChainIcon,
    chainGreyImageUrl: getChainByAsset(asset)?.greyImageURL || defaultChainIcon,
  };
};

export const bulkConvertFtAssetToUniformedPayment = (
  assets: FtAsset[]
): UniformedPayment[] => {
  return assets.map((asset) => convertFtAssetToUniformedPayment(asset));
};

export const isAccountRecovering = (accountName: string): boolean => {
  const storedKeys = localKeeper.getAllStorageKontosKey() || [];
  // Check for existence
  if (
    !storedKeys.some((key) => isSameKontosName(key.accountName, accountName))
  ) {
    return false;
  }
  // Check the recovery array
  const recoveringAccounts = localKeeper.getAccountsRecovering() || [];
  return recoveringAccounts.some((item) => isSameKontosName(item, accountName));
};

export const isAccountPubKeyException = (
  accountName: string,
  pubKeyRecord?: Record<string, string>
): boolean => {
  // Check if the account is recovering
  const isRecovering = isAccountRecovering(accountName);
  if (isRecovering) {
    return false; // Recovering accounts are not exceptions
  }

  // Find the stored key for the given account name
  const storedKeys = localKeeper.getAllStorageKontosKey() || [];
  const matchedStoredKey = storedKeys.find((key) =>
    isSameKontosName(key.accountName, accountName)
  );

  // If no stored key is found, it is not an exception
  if (!matchedStoredKey) {
    return false;
  }

  // Check if the account matches the current user's account
  if (
    isSameKontosName(
      rootStore.userStore.accountName,
      matchedStoredKey.accountName
    )
  ) {
    return !isSamePubKey(
      rootStore.userStore.accountInfo?.pubKey,
      matchedStoredKey.pubKeyData.compressedPubKey
    );
  }

  // Check if the account exists in the public key record
  if (pubKeyRecord?.hasOwnProperty(accountName)) {
    return !isSamePubKey(
      pubKeyRecord[accountName],
      matchedStoredKey.pubKeyData.compressedPubKey
    );
  }

  // If the account is not in the record, throw an error
  // throw new Error(t("Failed to verify public key"));
  // To avoid blocking pages due to backend issues
  return false;
};

export const isAccountPubKeyExceptionAsync = async (
  accountName: string
): Promise<boolean> => {
  const storedKeys = localKeeper.getAllStorageKontosKey() || [];
  if (storedKeys.length === 0) {
    throw new Error(t("No account found locally"));
  }
  const accounts = storedKeys.map((key) => key.accountName);
  const { data: pubKeyRecord } = await callAccountsPubKey({ accounts });
  return isAccountPubKeyException(accountName, pubKeyRecord);
};
