import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useStores } from "src/hooks/useStore";
import { observer } from "mobx-react";
import Header from "src/components/common/header";
import { BlankFilledArea } from "@/components/blank-area/BlankFilledArea";
import { useKontosConnector } from "@/hooks/useKontosConnect";
import { fontDescription, fontH6, fontMainText } from "@/style/style.global";
import { useCallback } from "react";
import { signForBe } from "@/utils/zkkontosHelper";
import { KontosKey } from "@zkkontos/kontos-sdk";
import { COMMON_SIGN_EXPIREDAT } from "@/config";
import { toastErrorMsg } from "@/utils/helper";
import { loadingStore } from "@/store/loadingStore";
import { KontosButton } from "@/components/button/KontosButton";
import ScrollableTextBox from "@/components/scrollable/ScrollableTextBox";
import avatarIco from "src/assets/icons/dapp/wc-avatar.svg";

const Container = styled.div`
  height: 100%;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

const StyledScrollableTextBox = styled(ScrollableTextBox)`
  margin-top: 16px;
  height: 200px;
  color: var(--Deep-400, #80868f);
  ${fontDescription}
`;

const Items = styled.div`
  margin: 16px 0;
  padding: 0 4px;
`;

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const ItemLeft = styled.div`
  color: var(--Deep-400, #80868f);
  ${fontDescription};
`;
const ItemRight = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  color: var(--Deep-400, #80868f);
  text-align: right;
  ${fontDescription};
`;

const ItemIcon = styled.img`
  width: 22px;
  height: 22px;
`;

const Btns = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;

const Host = styled.div`
  margin: 16px auto 0 auto;

  display: inline-flex;
  padding: 8px 16px;
  align-items: center;
  gap: 12px;
  border-radius: 99px;
  background: rgba(65, 61, 236, 0.1);

  overflow: hidden;
  color: var(--Deep-400, #80868f);
  text-overflow: ellipsis;
  white-space: nowrap;
  ${fontDescription}
`;

const HostFrom = styled.span`
  color: var(--Deep-800, #1a2535);
  ${fontH6}
`;

const HostUrl = styled.span`
  overflow: hidden;
  color: var(--Kontos-Blue, #413dec);
  text-overflow: ellipsis;
  ${fontMainText}
`;

export const SignMessage: React.FC = observer(() => {
  const { t } = useTranslation();
  const { userStore } = useStores();
  const { signPayload, rejectSigingMsg, onSigningMsgSuccess, openerOrigin } =
    useKontosConnector();

  const signMessage = useCallback(
    async (accountName: string, key: KontosKey) => {
      try {
        loadingStore.showLoading();
        const { signature, expiredAt } = await signForBe(
          accountName,
          key,
          signPayload!.message,
          COMMON_SIGN_EXPIREDAT
        );
        await onSigningMsgSuccess(signature, expiredAt);
      } catch (e) {
        const errMsg = toastErrorMsg(e);
        rejectSigingMsg(errMsg);
      } finally {
        loadingStore.hideLoading();
      }
    },
    [rejectSigingMsg, onSigningMsgSuccess, signPayload]
  );

  return (
    <Container>
      <Header padding="8px" title={t("Signature Requested")} />

      {/* Host */}
      <Host>
        <HostFrom>{t("From")}</HostFrom>
        <HostUrl>{openerOrigin}</HostUrl>
      </Host>

      {/* Desc */}
      <StyledScrollableTextBox>{signPayload?.message}</StyledScrollableTextBox>

      {/* Items */}
      <Items>
        <Item>
          <ItemLeft>{t("Wallet: ")}</ItemLeft>
          <ItemRight>
            <ItemIcon className="item-icon" src={avatarIco} alt="avatar" />
            <span>{userStore.accountNameWithOs}</span>
          </ItemRight>
        </Item>
      </Items>

      {/* Blank */}
      <BlankFilledArea />

      {/* Buttons */}
      <Btns>
        <KontosButton
          onClick={() =>
            userStore.unlock((key) => signMessage(userStore.accountName!, key))
          }
        >
          {t("Sign")}
        </KontosButton>
        <KontosButton $isOutlined onClick={() => rejectSigingMsg()}>
          {t("Cancel")}
        </KontosButton>
      </Btns>
    </Container>
  );
});
