import styled, { css } from "styled-components";
import defaultCloseIco from "src/assets/icons/close2.svg";
import darkCloseIco from "src/assets/icons/close6.svg";

export const CloseButton = styled.div<{
  closeIco?: any;
  $lazy?: boolean;
  size?: number;
  $darkMode?: boolean;
}>`
  background-image: ${(props) =>
    `url(${
      props.closeIco || (props.$darkMode ? darkCloseIco : defaultCloseIco)
    })`};
  background-repeat: no-repeat;
  background-size: cover;

  width: ${(props) => (props.size ? `${props.size}px` : "32px")};
  height: ${(props) => (props.size ? `${props.size}px` : "32px")};
  border-radius: 99px;
  background-color: transparent;
  cursor: pointer;
  ${(props) =>
    !props.$lazy &&
    css`
      &:hover {
        background-color: var(--Deep-50, #edeef1);
      }
      &:active {
        background-color: var(--Deep-50, #edeef1);
      }
    `}
`;
