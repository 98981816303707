import styled, { css, CSSProperties } from "styled-components";
import { useTranslation } from "react-i18next";
import { fontH8, fontRegular, fontSmaller } from "@/style/style.global";
import { AccountArrow } from "./AccountArrow";
import { normalizeKontosName } from "@/utils/helper";
import { useState } from "react";

type AbnormalAccountStatus = "todo" | "warning" | "error";

const Wrapper = styled.div<{
  $selected: boolean;
}>`
  width: 100%;
  cursor: ${(props) => (props.$selected ? "default" : "pointer")};
`;

const Container = styled.div<{
  $selected: boolean;
  $recovering: boolean;
  $disabled: boolean;
}>`
  position: relative;
  z-index: 2;
  width: 100%;
  padding: 20px 20px 20px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-radius: 8px;
  border: 1px solid
    ${(props) =>
      props.$selected
        ? `var(--Kontos-Blue, #413dec)`
        : "var(--Deep-50, #EDEEF1)"};
  background: var(--White, #fff);

  div {
    opacity: ${(props) => (props.$disabled ? "0.5" : "1")};
  }

  ${(props) =>
    !props.$disabled &&
    css`
      @media (hover: hover) {
        &:hover {
          border-color: var(--Kontos-Blue, #413dec);
          .balance-arrow-icon {
          }
        }
      }
      &:active {
        border-color: var(--Kontos-Blue, #413dec);
      }
    `};

  overflow: hidden;
`;

const LeftPart = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Avatar = styled.img`
  width: 40px;
  height: 40px;
`;

const NameText = styled.span`
  color: var(--Deep-800, #1a2535);
  ${fontRegular}
  font-size: 16px;
  font-style: normal;
  line-height: normal;
`;

const RightPart = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
`;

const BalanceText = styled.span`
  color: var(--Deep-400, #80868f);
  text-align: right;
  ${fontSmaller}
`;

const Tag = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  padding: 2px 16px;
  border-radius: 0 0 8px 0;
  background: var(--Kontos-Blue, #413dec);

  color: #fff;
  text-align: center;
  ${fontH8}
`;

const SubContainer = styled.div<{ $type: AbnormalAccountStatus }>`
  z-index: 0;
  margin-top: -8px;
  padding: 16px 24px 8px 24px;
  border-radius: 0px 0px 8px 8px;
  cursor: pointer;
  background: ${(props) =>
    props.$type === "todo"
      ? "rgba(65, 61, 236, 0.05)"
      : props.$type === "warning"
        ? "rgba(250, 173, 20, 0.05)"
        : "rgba(255, 30, 43, 0.05)"};

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  ${fontSmaller}
  color: ${(props) =>
    props.$type === "todo"
      ? "var(--Kontos-Blue, #413DEC)"
      : props.$type === "warning"
        ? "var(--Warning, #FAAD14)"
        : "var(--error-notice, #FF1E2B)"};
`;

interface Props {
  name: string;
  avatar?: string;
  balanceText?: string;
  tag?: string;
  className?: string;
  style?: CSSProperties;
  subStyle?: CSSProperties;
  selected?: boolean;
  recovering?: boolean;
  pubKeyException?: boolean;
  onClick?: () => void;
}

export const AccountItem = ({
  name,
  avatar = undefined,
  balanceText = undefined,
  tag = undefined,
  className = undefined,
  style = undefined,
  subStyle = undefined,
  selected = false,
  recovering = false,
  pubKeyException = false,
  onClick,
}: Props) => {
  const { t } = useTranslation();
  const [hover, setHover] = useState<boolean>(false);
  const disabled = recovering || pubKeyException;
  const status: AbnormalAccountStatus | "normal" = recovering
    ? "todo"
    : pubKeyException
      ? "error"
      : "normal";

  return (
    <Wrapper $selected={selected} onClick={onClick}>
      <Container
        $selected={selected}
        $recovering={recovering}
        $disabled={disabled}
        className={className}
        style={style}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <LeftPart>
          <Avatar src={avatar} alt={t("Avatar")} />
          <NameText>{`${normalizeKontosName(name)}.os`}</NameText>
        </LeftPart>

        <RightPart>
          {!disabled && !!balanceText && (
            <BalanceText>{balanceText}</BalanceText>
          )}
          <AccountArrow color={hover && !disabled ? "blue" : "grey"} />
        </RightPart>

        {selected ? <Tag>{t("Current")}</Tag> : tag ? <Tag>{tag}</Tag> : null}
      </Container>

      {status !== "normal" && (
        <SubContainer $type={status} style={subStyle}>
          <span>
            {recovering && t("Review your recovery progress.")}
            {pubKeyException && t("Public key error, reset now.")}
          </span>
          <AccountArrow
            color={
              status === "error" ? "red" : status === "todo" ? "blue" : "yellow"
            }
          />
        </SubContainer>
      )}
    </Wrapper>
  );
};
