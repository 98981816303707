import styled from "styled-components";
import { Trans, useTranslation } from "react-i18next";
import React from "react";
import { KontosButton } from "src/components/button/KontosButton";
import Modal from "src/components/modal/Modal";
import { AIRDROP_URL } from "src/config";
import { fontBold, fontH6, fontH7, fontMainText } from "@/style/style.global";
import { isMobileDevice, openUrl } from "@/utils/helper";
import tipsIcon from "src/assets/icons/trade/tip.svg";
import arrowIcon from "../assets/arrow.svg";
import guideDesktopIcon from "../assets/airdrop-guide-desktop.png";
import guideMobileIcon from "../assets/airdrop-guide-mobile.png";

const Container = styled.div`
  width: 343px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const TipsIcon = styled.img`
  width: 42px;
  height: 24px;
`;

const Title = styled.span`
  color: var(--Kontos-Blue, #413dec);
  text-align: center;
  ${fontH6}
`;

const Desc = styled.span`
  color: var(--Deep-800, #1a2535);
  text-align: center;
  ${fontMainText}
`;

const Strong = styled.span`
  color: var(--Kontos-Blue, #413dec);
  ${fontBold}
`;

const GuideImage = styled.img`
  width: 311px;
  height: auto;
`;

const GoToButton = styled(KontosButton)`
  width: fit-content;
  min-width: 295px;
  padding: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 19px;
`;

const ButtonText = styled.span`
  color: var(--White, #fff);
  text-align: center;
  ${fontH7}
`;

const ButtonIcon = styled.img`
  width: 20px;
  height: 20px;
`;

interface IProps {
  onClose: () => void;
}

export const AirdropGuideModal: React.FC<IProps> = ({ onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal zExtraIndex={9999999} onClose={onClose}>
      <Container>
        <MainContent>
          <TipsIcon src={tipsIcon} alt="tips" />

          <Title>{t("Oops!")}</Title>

          <Desc>{t("It seems you are connecting to the Kontos Airdrop.")}</Desc>

          <GuideImage
            src={isMobileDevice() ? guideMobileIcon : guideDesktopIcon}
            alt="guide"
          />

          <Desc>
            <Trans i18nKey={"trans-wallet-connect-airdrop-guide"}>
              Please select <Strong>Kontos Wallet</Strong> directly after
              clicking <Strong>"Connect to Kontos"</Strong> on the Kontos
              airdrop page.
            </Trans>
          </Desc>
        </MainContent>

        <GoToButton
          onClick={() => {
            openUrl(AIRDROP_URL);
            onClose();
          }}
        >
          <ButtonText>{t("Go to Kontos Airdrop")}</ButtonText>
          <ButtonIcon src={arrowIcon} alt="" />
        </GoToButton>
      </Container>
    </Modal>
  );
};
