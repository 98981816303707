import styled, { CSSProperties } from "styled-components";
import arrowIcon from "src/assets/icons/account/blue-right-icon.svg";
import Skeleton from "react-loading-skeleton";
import bluePointIco from "src/assets/icons/account/blue-point.svg";
import { useTranslation } from "react-i18next";

const Container = styled.div<{
  $isSelected: boolean;
  $recovering: boolean;
  $disable: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 20px 6px 20px;
  height: 100px;
  margin-bottom: 8px;

  border-radius: 8px;
  border: ${(props) =>
    props.$isSelected
      ? `2px solid` + props.theme.colors.__kontos_blue
      : `1px solid` + props.theme.colors.__deep_50};
  background: ${(props) =>
    props.$isSelected
      ? props.theme.colors.__white
      : props.theme.colors.__deep_25};

  cursor: ${(props) =>
    props.$disable ? "default" : props.$recovering ? "not-allowed" : "pointer"};
  -webkit-tap-highlight-color: transparent;

  ${(props) =>
    !props.$disable &&
    `
    &:hover {
      border: ${props.$isSelected ? `2px` : `1px`} solid ${
        props.theme.colors.__kontos_blue
      };
      background-color: ${props.theme.colors.__white};
    }
  `}

  .top {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .avatar {
      width: 40px;
      height: 40px;

      margin-right: 12px;
    }

    .title {
      color: ${(props) => props.theme.colors.__deep_800};

      font-family: HarmonyOS Sans;
      font-size: 18px;
      font-weight: 400;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .bottom {
    width: 100%;

    .normal {
      display: flex;
      justify-content: center;
      align-items: center;

      color: ${(props) => props.theme.colors.__deep_400};
      text-align: center;
      font-family: HarmonyOS Sans SC;
      font-size: 12px;
      font-weight: 400;
    }

    .recovering {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      color: ${(props) => props.theme.colors.__kontos_blue};
      font-family: HarmonyOS Sans;
      font-size: 12px;
      font-weight: 400;

      cursor: pointer;

      .left {
        display: flex;
        justify-content: center;
        align-items: center;

        .circle {
          width: 6px;
          height: 6px;
          margin-right: 6px;
        }
      }

      .right {
        .arrow {
        }
      }
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    background: ${(props) => props.theme.colors.__deep_50};
    margin-top: 15px;
    margin-bottom: 6px;
  }
`;

interface Props {
  recovering: boolean;
  name: string;
  avatar: any;
  selected: boolean;
  balanceText?: string;
  style?: CSSProperties;
  disable?: boolean;
  onClick?: () => void;
  onBottomClick?: () => void;
}

const AccountItem = ({
  recovering,
  name,
  avatar,
  selected,
  balanceText,
  style,
  disable = false,
  onClick,
  onBottomClick,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Container
      $isSelected={selected}
      $recovering={recovering}
      $disable={disable}
      style={style}
      onClick={async (event) => {
        event.stopPropagation();
        onClick && !recovering && !disable && onClick();
      }}
    >
      <div className="top">
        <img className="avatar" src={avatar} alt="" />
        <span className="title">
          {name}
          {".os"}
        </span>
      </div>
      <div className="divider"></div>
      <div className="bottom">
        {recovering ? (
          <div
            className="recovering"
            onClick={async (event) => {
              event.stopPropagation();
              !disable && onBottomClick && onBottomClick();
            }}
          >
            <div className="left">
              <img className="circle" src={bluePointIco} alt="" />
              <span>
                {t("Please review your recovery progress and confirm")}
              </span>
            </div>
            <div className="right">
              <img className="arrow" src={arrowIcon} alt="" />
            </div>
          </div>
        ) : (
          <div className="normal">
            {balanceText ? (
              t("Balance: ") + balanceText
            ) : (
              <Skeleton count={1} style={{ width: "200px" }} />
            )}
          </div>
        )}
      </div>
    </Container>
  );
};

export default AccountItem;
