import React, { useEffect, useRef } from "react";
import styled from "styled-components";

const StyledDiv = styled.div<{ maxHeight: number }>`
  max-height: ${(props) => `${props.maxHeight}px`};
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px 17px;
  border-radius: 8px;
  background-color: var(--Deep-25, #f5f5f6);
  word-wrap: break-word;
  white-space: normal;
  height: 200px;
`;

interface ScrollableDivProps {
  children: React.ReactNode;
  maxHeight?: number;
  className?: string;
}

const ScrollableTextBox: React.FC<ScrollableDivProps> = ({
  children,
  maxHeight = 201,
  className,
}) => {
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const div = divRef.current;
    if (!div) return;

    const checkScroll = () => {
      const atBottom = div.scrollHeight - div.scrollTop === div.clientHeight;

      if (atBottom) {
        div.style.paddingBottom = "0";
      } else {
        div.style.paddingBottom = "10px";
      }
    };

    div.addEventListener("scroll", checkScroll);

    return () => div.removeEventListener("scroll", checkScroll);
  }, []);

  return (
    <StyledDiv ref={divRef} className={className} maxHeight={maxHeight}>
      {children}
    </StyledDiv>
  );
};

export default ScrollableTextBox;
