import styled from "styled-components";
import LogoIcon from "src/assets/images/logo.svg";
import { useCallback, useEffect, useRef, useState } from "react";
import toast from "src/components/toast/Toast";
import { t } from "i18next";
import { PINCodeInput } from "../../../components/images/PINCodeInput";
import { observer } from "mobx-react";
import { UnlockTips } from "./UnlockTips";
import { loadingStore } from "src/store/loadingStore";
import { ROUTE_AUTH, ROUTE_SIGN, ROUTE_HOME } from "src/router/router-config";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "src/components/button/Button";
import { useStores } from "src/hooks/useStore";
import { uploadErrorOnce } from "src/service/wallet-service";
import { fontBold } from "@/style/style.global";
import { PIN_FOCUS_DELAY } from "@/config";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-image: url(/static/bg-light.png);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;

  width: 100%;
  height: 100vh;
  padding: 20px;

  box-sizing: border-box;

  .main {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .app-icon {
    width: 96px;
    height: 96px;
    margin-top: 120px;
    margin-bottom: 16px;
  }

  .title {
    color: #413dec;
    font-size: 28px;
    line-height: 33px;
    ${fontBold};
    margin-bottom: 6px;
  }

  .desc {
    color: #413dec;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
  }

  .footer {
    margin-bottom: 30px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 0px;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    transform: translateY(23px);

    .logo {
      height: auto;
      width: 65px;
      margin-left: 8px;
      transform: translateY(2px);
    }

    .radio {
      margin: 0;
      margin-right: 10px;
      appearance: none;
      position: relative;

      :before {
        content: "";
        width: 15px;
        height: 15px;
        border: 1px solid #413dec;
        display: inline-block;
        border-radius: 50%;
        vertical-align: middle;
      }
    }

    .checked:after {
      content: "";
      width: 11px;
      height: 11px;
      background: #413dec;
      left: 3px;
      top: 3px;
      border-radius: 50%;
      position: absolute;
    }

    .link {
      color: #413dec;
      cursor: pointer;
      margin-left: 4px;
    }

    .logo {
    }
  }

  .input-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-top: auto;
    margin-bottom: 24px;

    .info {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      color: #1a2535;
      text-align: center;
      font-size: 14px;
      margin-bottom: 12px;
    }
  }
`;

export const MAX_UNLOCK_COUNT = 9;

enum VerifyMode {
  AddAccount = "addAccount",
  Connect = "connect",
}

export const MultiAccountsPinVerifier: React.FC = observer(() => {
  const { userStore } = useStores();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [seed, setSeed] = useState("");
  const [searchParams] = useSearchParams();
  const signType = searchParams.get("signType") || "";
  const mode = searchParams.get("mode") || VerifyMode.AddAccount;
  const inputRef = useRef<{ resetValue: () => void; focus: () => void }>(null);

  const clearInput = useCallback(() => {
    inputRef.current?.resetValue();
    setTimeout(() => {
      inputRef.current?.focus();
    }, PIN_FOCUS_DELAY);
  }, []);

  const reset = useCallback(async () => {
    loadingStore.showLoading();
    try {
      toast({
        text: "You have exhausted all retry attempts. Your local storage will now be reset.",
        type: "error",
      });
      await uploadErrorOnce(
        userStore.accountName || "",
        "storage_reset",
        {
          unlockAmount: userStore.unlockAmount,
        },
        ""
      );
      userStore.updateUnlockAmount(0);
      userStore.resetStorage();
      navigate(ROUTE_AUTH);
    } catch (e) {
      await uploadErrorOnce(
        userStore.accountName || "",
        "storage_reset",
        {
          unlockAmount: userStore.unlockAmount,
        },
        ""
      );
    } finally {
      loadingStore.hideLoading();
    }
  }, [navigate, userStore]);

  useEffect(() => {
    if (userStore.unlockAmount > MAX_UNLOCK_COUNT) {
      reset();
    }
  }, [reset, userStore.unlockAmount]);

  const handleUnlock = useCallback(async () => {
    try {
      setLoading(true);
      await userStore.reconstructKontosCli(seed);
      setLoading(false);
      // If no 'afterUnlock' param is passed, navigate to MAIN_ROUTE by default.
      // unlock successfully unlockAmount set 0 (init)
      userStore.setPin(seed);
      userStore.updateUnlockAmount(0);
      if (signType) {
        navigate(`${ROUTE_SIGN}?signType=${signType}`);
      } else {
        console.log("ok", userStore.getRouteAfterUnlock());
        navigate(userStore.getRouteAfterUnlock() || ROUTE_HOME, {
          replace: true,
        });
      }
    } catch (e) {
      console.log("e: ", e);
      toast({
        text: `The PIN code incorrect. You only remain ${
          MAX_UNLOCK_COUNT - userStore.unlockAmount
        } more chances to try.`,
        type: "error",
      });
      userStore.updateUnlockAmount(userStore.unlockAmount + 1);
      setSeed("");
      clearInput();
    } finally {
      setLoading(false);
    }
  }, [clearInput, navigate, seed, signType, userStore]);

  const handleCancel = useCallback(() => {
    navigate(ROUTE_HOME);
  }, [navigate]);

  useEffect(() => {
    if (seed.length === 6 && userStore.unlockAmount <= MAX_UNLOCK_COUNT) {
      handleUnlock();
    }
  }, [handleUnlock, seed.length, userStore.unlockAmount]);

  return (
    <Wrapper>
      <div className="main">
        <img
          className="app-icon"
          src="/static/kontos-app.svg"
          alt="kontos-app"
        />
        <div className="title">{t("Welcome to Kontos")}</div>
        <div className="desc">{t("One Account, Infinite Possibilities")}</div>
        <div className="input-wrapper">
          <div className="info">
            <span>
              {mode === VerifyMode.Connect
                ? t("Verify your PIN code to continue")
                : t("Verify your PIN code to add a new account")}
            </span>
            <UnlockTips />
          </div>
          <PINCodeInput ref={inputRef} size="small" onPINChange={setSeed} />
        </div>
        <Button
          text={t(loading ? t("Verifying") : t("Verify"))}
          fullWidth
          loading={loading}
          onClick={handleUnlock}
          disabled={
            !seed ||
            seed.length !== 6 ||
            userStore.unlockAmount > MAX_UNLOCK_COUNT
          }
        />
        <br />
        <Button
          text={t("Cancel")}
          fullWidth
          onClick={handleCancel}
          variant={"outlined"}
        />
      </div>
      <div className="footer">
        <span>{t("Powered by")}</span>
        <img src={LogoIcon} className={"logo"} alt="" />
      </div>
    </Wrapper>
  );
});
