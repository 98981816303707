export const ROUTE_DEFAULT = "/";

// auth
export const ROUTE_AUTH = "/auth";
export const ROUTE_CREATE_ACCOUNT = "/createAccount";
export const ROUTE_RECOVER_ACCOUNT = "/recoverAccount";
export const ROUTE_RECOVER_ACCOUNT_OLD = "/recoverAccountOld";
export const ROUTE_UNLOCK = "/unlock";
export const ROUTE_MULTI_ACCOUNTS_PIN_VERIFIVER =
  "/multi-accounts-pin-verifier";
export const ROUTE_SIGN = "/sign";

export const PREV = "../";

export const ROUTE_TRANSFER = "/transfer";

export const ROUTE_ASSET_DETAIL_QUOTE = "/assets/quote";
export const ROUTE_SETTINGS = "/settings";
export const ROUTE_GUARDIAN = "/guardian";
export const ROUTE_SECURITY_EMAIL = "/security-email";

// new ui
export const ROUTE_HOME = "/home";
export const ROUTE_MARKETS = "/markets";

// main/..
export const ROUTE_MAIN_RECEIVE = "receive/";
export const ROUTE_MAIN_RECEIVE_CHOOSE = "receive/choose";

// settings/..
export const ROUTE_SETTINGS_ACCOUNT = "account/";
export const ROUTE_SETTINGS_ACCOUNT_SWITCH = "switch";
export const ROUTE_SETTINGS_ACCOUNT_REFERRAL = "referral";

export const ROUTE_SETTINGS_GENERAL = "/settings/general";
export const ROUTE_SETTINGS_GENERAL_LANG = "/settings/general/lang";

export const ROUTE_SETTINGS_TRADE = "settings/";
export const ROUTE_SETTINGS_PAYMENT = "payment";

// trade
export const ROUTE_TRADE = "/trade";
export const ROUTE_TRADE_BUY = "/buy";
export const ROUTE_TRADE_SELL = "/sell";

// discover
export const ROUTE_DISCOVER = "/discover";
export const ROUTE_DISCOVER_EXPLORE = "explore";
export const ROUTE_DISCOVER_FAVORITES = "favorites";
export const ROUTE_DISCOVER_TRENDINGS = "trendings";
export const ROUTE_DISCOVER_SEARCH = "discoverSearch";

// H5 events
export const ROUTE_ENERGYBOOSTING = "/energyBoosting";
export const ROUTE_ENERGYBOOSTING_LEADERBOARD = "leaderboard";
// scan
export const ROUTE_QR_READER = "qr-reader";

// ai
export const ROUTE_AI = "/ai";

// receive
export const ROUTE_RECEIVE = "/receive";

// connect (by dapp)
export const ROUTE_CONNECT = "/connect";
