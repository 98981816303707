import Header from "src/components/common/header";
import { t } from "i18next";
import styled from "styled-components";
import FloatingButton from "src/pages/trade/FloatingButton";
import addIcon from "src/assets/icons/add3.svg";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { KontosQueryCli } from "@zkkontos/kontos-sdk";
import avatarIcon from "src/assets/icons/settings/avatar.svg";
import KontosNumber from "src/utils/KontosNumber";
import {
  DEFAULT_DECIMAL,
  DEFAULT_DISPLAY_PRECESION,
  LOCAL_ACCOUNT_QUANTITY_LIMIT,
} from "src/config";
import { isSameAddress, prioritizeItems } from "src/utils/zkkontosHelper";
import { observer } from "mobx-react";
import useMouseDownOutside from "src/hooks/useMouseDownOutside";
import AddAccount from "./AddAccount";
import deleteIcon from "src/assets/icons/settings/delete.svg";
import toast from "src/components/toast/Toast";
import DelAccount from "./DelAccount";
import { useStores } from "src/hooks/useStore";
import { action } from "mobx";
import { callAccountsBalances } from "@/apis/asset-apis";
import { AccountItem } from "@/components/account-item/AccountItem";
import { BottomSheet } from "@/components/bottom-sheet/BottomSheet";
import {
  isAccountPubKeyException,
  isAccountRecovering,
} from "@/store/storeHelper";
import { callAccountsPubKey } from "@/apis/aa-apis";
import localKeeper from "@/store/localKeeper";
import { useNavigate } from "react-router-dom";
import { ROUTE_RECOVER_ACCOUNT } from "@/router/router-config";
import { toastErrorMsg } from "@/utils/helper";
import { PopupModal } from "@/components/popups/PopupModal";

const Container = styled.div`
  padding: 0 16px 82px 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  flex: 1;

  @keyframes slideIn {
    from {
      transform: translateX(100%-82px);
    }
    to {
      transform: translateX(-82px);
    }
  }

  @keyframes slideOut {
    from {
      transform: translateX(-82px);
    }
    to {
      transform: translateX(100%-82px);
    }
  }

  .slide-in {
    animation: slideIn 0.5s forwards;
  }

  .slide-out {
    animation: slideOut 0.5s forwards;
  }
`;

const Scrollable = styled.div`
  width: 100%;

  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;

  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const AccountItemWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const DeleteBtn = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: -82px;
  margin: auto;

  > img {
    width: 82px;
    height: 82px;
  }

  &:hover {
    opacity: 0.9;
  }

  cursor: pointer;
`;

const AddButton = styled(FloatingButton)`
  position: fixed;
  bottom: 16px;
  width: min(335px, 90%);
  height: 50px;
  z-index: 2;
  margin: 0 auto;
`;

type iDisplayAccount = {
  index: number;
  usdAmount: string | undefined;
  name: string;
  address: string;
  recovering: boolean;
};

type iDisplayAccountBalance = {
  account: string;
  usdAmount: string;
};

const updateAccountsWithBalance = (
  _accountsBalances: iDisplayAccountBalance[],
  _accounts: iDisplayAccount[]
): iDisplayAccount[] => {
  const newAccounts = _accounts.map((account) => {
    const accountBalance = _accountsBalances.find((ab) =>
      isSameAddress(ab.account, account.name)
    );
    if (accountBalance) {
      return {
        ...account,
        usdAmount: accountBalance.usdAmount,
      };
    }
    return account;
  });
  return newAccounts;
};

interface IProps {
  onDone: () => void;
}

const SwitchAccount: React.FC<IProps> = ({ onDone }) => {
  const { userStore, uiSettingStore } = useStores();
  const navigate = useNavigate();
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const domNode = wrapperRef.current as Element | undefined;
  const [managing, setManaging] = useState<boolean>(false);
  const [animationClass, setAnimationClass] = useState("");
  const [activeAccount, setActiveAccount] = useState<iDisplayAccount>();
  const [accountsBalances, setAccountsBalances] = useState<
    iDisplayAccountBalance[]
  >([]);
  const [pubKeyCheckRecord, setPubKeyCheckRecord] =
    useState<Record<string, string>>();
  const [showResetModal, setShowResetModal] = useState<boolean>(false);
  const [toResetAccount, setToResetAccount] = useState<string>();

  const currentAccounts: iDisplayAccount[] = useMemo(() => {
    return localKeeper.getAllStorageKontosKey()?.map((item, index) => {
      return {
        index: index,
        name: item.accountName,
        address: KontosQueryCli.nameAddress(item.accountName),
        usdAmount: undefined,
        recovering: userStore.accountsRecovering?.includes(item.accountName),
      };
    });
  }, [userStore.accountsRecovering]);
  const accounts: iDisplayAccount[] = useMemo(() => {
    let res = currentAccounts;
    if (accountsBalances.length > 0) {
      res = updateAccountsWithBalance(accountsBalances, res);
    }
    res = prioritizeItems(res, userStore.accountInfo?.name!);
    return res;
  }, [accountsBalances, currentAccounts, userStore.accountInfo?.name]);

  const closeModals = useCallback(() => {
    uiSettingStore.closeAddAccountModal();
    uiSettingStore.closeRemoveAccountModal();
    uiSettingStore.closeRecoverDetailModal();
  }, [uiSettingStore]);

  useMouseDownOutside({
    ref: wrapperRef,
    callback: closeModals,
    shouldClose: true,
  });

  // Check balances & pubKeys when mounted
  useEffect(() => {
    // init balances, not blocking
    const initBalancesAsync = async (accounts: string[]) => {
      try {
        const { accountUsdBalances } = await callAccountsBalances({ accounts });
        const accountsBalancesIDisplay =
          Object.entries(accountUsdBalances).map((item) => {
            return { account: item[0], usdAmount: item[1] };
          }) || [];
        setAccountsBalances(accountsBalancesIDisplay);
      } catch (e) {
        console.warn(e);
      }
    };

    // check pubKeys, blocking
    const checkPubKeysAsync = async (accounts: string[]) => {
      try {
        const { data } = await callAccountsPubKey({ accounts });
        setPubKeyCheckRecord(data);
      } catch (e) {
        console.warn(e);
      }
    };

    const accounts = localKeeper
      .getAllStorageKontosKey()
      .map((item) => item.accountName);
    if (accounts.length === 0) {
      return;
    }
    initBalancesAsync(accounts);
    checkPubKeysAsync(accounts);
  }, []);

  const handleSwitchClick = useCallback(
    async (account: iDisplayAccount) => {
      if (isAccountPubKeyException(account.name, pubKeyCheckRecord)) {
        setShowResetModal(true);
        setToResetAccount(account.name);
        return;
      }

      if (isAccountRecovering(account.name)) {
        navigate(`${ROUTE_RECOVER_ACCOUNT}?name=${account.name}`);
        return;
      }

      if (account.name === userStore.accountInfo?.name) {
        return;
      }

      try {
        setAnimationClass("");
        await userStore.switchAccount(account.index);
        toast({
          text: t(`inter_switch_account_toast`, {
            name: account.name,
          }),
          type: "success",
        });
        onDone();
      } catch (e) {
        toastErrorMsg(e);
      }
    },
    [navigate, onDone, pubKeyCheckRecord, userStore]
  );

  const handleResetBtnClick = useCallback(() => {
    if (!toResetAccount) {
      return;
    }

    userStore.removeAccount(toResetAccount);
    navigate(`${ROUTE_RECOVER_ACCOUNT}?name=${toResetAccount}`);
    setShowResetModal(false);
    return;
  }, [navigate, toResetAccount, userStore]);

  const hanldeAddOnClick = useCallback(() => {
    if (accounts.length >= LOCAL_ACCOUNT_QUANTITY_LIMIT) {
      toast({
        type: "warning",
        text: t(
          "Your local account limit is almost reached. Please consider removing some accounts to proceed."
        ),
      });
      return;
    }
    uiSettingStore.showAddAccountModal();
  }, [accounts.length, uiSettingStore]);

  const toggleVisibility = useCallback(() => {
    if (managing) {
      setAnimationClass("slide-out");
      setManaging(false);
    } else {
      setAnimationClass("slide-in");
      setManaging(true);
    }
  }, [managing]);

  const handleDelOnClick = useCallback(
    (account: iDisplayAccount) => {
      setActiveAccount(account);
      uiSettingStore.showRemoveAccountModal();
    },
    [uiSettingStore]
  );

  const handleRemoveAccount = useCallback(() => {
    uiSettingStore.closeRemoveAccountModal();
    if (activeAccount) {
      userStore.removeAccount(activeAccount.name);
      toast({
        text: `Successfully removed account ${activeAccount.name}.os`,
        type: "success",
      });
      setActiveAccount(undefined);
      return;
    }
  }, [activeAccount, uiSettingStore, userStore]);

  return (
    <Container ref={wrapperRef}>
      <Header
        title={t("Switch Account")}
        enableBack={true}
        headStyle={{ marginTop: "-20px" }}
        rightBtnText={
          accounts.length > 1
            ? managing
              ? (t("Cancel") as string)
              : (t("Manage") as string)
            : undefined
        }
        rightBtnCallBack={toggleVisibility}
      />
      <Scrollable>
        {accounts.map((account) => (
          <AccountItemWrapper key={account.name}>
            <AccountItem
              style={{
                transition: "transform 0.5s ease",
                transform:
                  managing && account.name !== userStore.accountInfo?.name
                    ? "translateX(-91px)"
                    : "translateX(0)",
              }}
              subStyle={{
                transition: "transform 0.5s ease",
                transform:
                  managing && account.name !== userStore.accountInfo?.name
                    ? "translateX(-91px)"
                    : "translateX(0)",
              }}
              recovering={account.recovering}
              pubKeyException={isAccountPubKeyException(
                account.name,
                pubKeyCheckRecord
              )}
              name={account.name}
              avatar={avatarIcon}
              balanceText={
                account.usdAmount
                  ? new KontosNumber(
                      account.usdAmount,
                      DEFAULT_DECIMAL
                    ).toFormat(DEFAULT_DISPLAY_PRECESION) + " USD"
                  : undefined
              }
              selected={account.name === userStore.accountInfo?.name}
              onClick={() => {
                handleSwitchClick(account);
              }}
            />
            {account.name !== userStore.accountInfo?.name && (
              <DeleteBtn
                className={`${animationClass} delbtn`}
                onClick={() => handleDelOnClick(account)}
              >
                <img src={deleteIcon} alt="delete" />
              </DeleteBtn>
            )}
          </AccountItemWrapper>
        ))}
      </Scrollable>

      <AddButton
        fixedIconRight={false}
        onClick={hanldeAddOnClick}
        fixedIcon={addIcon}
      >
        {t("Add Account")}
      </AddButton>

      <BottomSheet
        isOpen={uiSettingStore.isAddAccountModalOpen}
        onClose={action(() => uiSettingStore.closeAddAccountModal())}
        mountPoint={domNode}
        detent="content-height"
        customHeight={330}
      >
        <AddAccount />
      </BottomSheet>

      <BottomSheet
        isOpen={uiSettingStore.isRemoveAccountModalOpen}
        onClose={action(() => uiSettingStore.closeRemoveAccountModal())}
        mountPoint={domNode}
        detent="content-height"
        customHeight={330}
      >
        <DelAccount
          name={activeAccount?.name!}
          balanceText={
            activeAccount?.usdAmount
              ? new KontosNumber(
                  activeAccount.usdAmount,
                  DEFAULT_DECIMAL
                ).toFormat(DEFAULT_DISPLAY_PRECESION) + " USD"
              : undefined
          }
          onRemove={handleRemoveAccount}
          onCancel={action(() => uiSettingStore.closeRemoveAccountModal())}
        />
      </BottomSheet>

      {/* pub key exception modal */}
      {showResetModal && (
        <PopupModal
          type="error"
          onConfirm={handleResetBtnClick}
          onCancel={(event) => {
            setShowResetModal(false);
          }}
          onOutClick={(event) => {
            setShowResetModal(false);
          }}
          title={t("Account Warning !")}
          desc={t(
            "Some issues with your public key, the account can not make transactions any more."
          )}
          subDesc={"*Please reset and recover your account first."}
          btnTxt={t("Reset Now") as string}
          cancelBtnText={t("Later") as string}
        />
      )}
    </Container>
  );
};

export default observer(SwitchAccount);
