import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Skeleton from "react-loading-skeleton";
import { KontosButton } from "src/components/button/KontosButton";
import { getOrbImg } from "src/utils/events/ebHelper";
import { fontBold } from "@/style/style.global";

const Container = styled.div<{ lineCount: number }>`
  //flex: 0 1 32%;
  width: ${(props) => `calc((100% - 12px) / ${props.lineCount})`};
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > .eb-backpack-orbs {
    width: 100%;
    margin-top: 20px;
    border-radius: 8px;
    background: var(--Deep-25, #f5f5f6);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 14px 18px 18px 18px;

    > img {
      width: 60px;
      height: 60px;
    }

    > span {
      margin-top: 4px;
      line-height: 14px;
      color: var(--Deep-800, #1a2535);
      ${fontBold};
      font-size: 12px;
    }
  }

  .open-btn {
    margin-top: 8px;
    border-radius: 8px !important;
    padding: 13px !important;
    font-size: 14px !important;
  }
`;

interface Props {
  orbType: number;
  orbCount: number | undefined;
  orbTypeCount: number;
  loading: boolean;
  onOpenOrb: () => void;
}

export const EbBackpackOrb: React.FC<Props> = ({
  orbType,
  orbCount,
  orbTypeCount,
  loading,
  onOpenOrb,
}) => {
  const { t } = useTranslation();

  return (
    <Container lineCount={orbTypeCount}>
      <div className="eb-backpack-orbs">
        <img src={getOrbImg(orbType)} alt="" />
        <span>
          {t("Energy Orbs")} x
          {orbCount === undefined || (orbCount === 0 && loading) ? (
            <Skeleton
              count={1}
              style={{ width: "30px", height: "14px", marginLeft: "2px" }}
            />
          ) : (
            orbCount
          )}
        </span>
      </div>
      <KontosButton
        className="open-btn"
        disabled={!orbCount || orbCount < 1}
        onClick={onOpenOrb}
      >
        {t("eb.Open")}
      </KontosButton>
    </Container>
  );
};
