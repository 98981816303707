import { ReactNode, useState } from "react";
import styled, { css } from "styled-components";
import { motion } from "framer-motion";
import leftArrowIcon from "./left-arrow.svg";
import rightArrowIcon from "./right-arrow.svg";
import { CloseButton } from "@/components/button/CloseButton";
import bgImg from "src/assets/images/event/eb/eb-banner-bg.jpg";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-fade";
import { isMobileDevice } from "@/utils/helper";
import normalDotIcon from "./dot.svg";
import activeDotIcon from "./dot-active.svg";
import closeIcon from "./close.svg";

const Container = styled.div<{ $needScale: boolean }>`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  @media (hover: hover) {
    ${(props) =>
      props.$needScale &&
      css`
        &:hover .child {
          width: 90%;
        }
      `}
  }
`;

const ChildDiv = styled(motion.div)`
  width: 100%;
  height: 100%;
  transition: width 0.3s ease;
  position: relative;
  overflow: hidden;

  border-radius: 8px;
  border: 1px solid var(--Deep-25, #f5f5f6);
  background-image: url(${bgImg});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
`;

const DotGroup = styled.div`
  position: absolute;
  bottom: 8px;
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

const Dot = styled.img`
  width: 4px;
  height: 4px;
`;

const Arrow = styled(motion.div)`
  width: 16px;
  height: 100%;
  cursor: pointer;
  position: absolute;
  top: 0;
  z-index: 2;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const ArrowIcon = styled(motion.img)`
  width: 16px;
  height: 16px;
  user-select: none;
`;

const StyledCloseButton = styled(CloseButton)`
  position: absolute;
  top: 4px;
  right: 4px;
  width: 20px;
  height: 20px;
  z-index: 10;
`;

interface IProps {
  children: ReactNode | ReactNode[];
  style?: React.CSSProperties;
  className?: string;
  onClose?: () => void;
}

export const BannerContainerV2: React.FC<IProps> = ({
  children,
  style,
  className,
  onClose,
}) => {
  const [hovering, setHovering] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [controlledSwiper, setControlledSwiper] = useState<SwiperClass | null>(
    null
  );

  const handleMouseEnter = () => {
    setHovering(true);
  };

  const handleMouseLeave = () => {
    setHovering(false);
  };

  return (
    <Container
      $needScale={Array.isArray(children)}
      className={className}
      style={style}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {hovering && Array.isArray(children) && children.length > 1 && (
        <Arrow
          onClick={() => {
            controlledSwiper?.slidePrev();
          }}
          initial={{ opacity: 0, left: -10 }}
          animate={
            hovering ? { opacity: 1, left: 5 } : { opacity: 0, left: -20 }
          }
          transition={{ duration: 0.3 }}
        >
          <ArrowIcon src={leftArrowIcon} alt="prev" />
        </Arrow>
      )}

      <ChildDiv className="child">
        {Array.isArray(children) ? (
          <Swiper
            onSwiper={setControlledSwiper}
            loop
            autoplay={{
              delay: 5000,
              pauseOnMouseEnter: true,
            }}
            normalizeSlideIndex={false}
            modules={[Autoplay]}
            onSlideChange={(e) => {
              setActiveIndex(e.realIndex);
            }}
          >
            {children.map((child, index) => (
              <SwiperSlide key={index}>{child}</SwiperSlide>
            ))}
          </Swiper>
        ) : (
          children
        )}

        {(isMobileDevice() || hovering) && (
          <StyledCloseButton
            className="close-btn"
            closeIco={closeIcon}
            $lazy
            onClick={(e) => {
              onClose?.();
              e.stopPropagation();
            }}
          />
        )}
      </ChildDiv>

      {hovering && Array.isArray(children) && children.length > 1 && (
        <Arrow
          onClick={() => {
            controlledSwiper?.slideNext();
          }}
          initial={{ opacity: 0, right: -10 }}
          animate={
            hovering ? { opacity: 1, right: 5 } : { opacity: 0, right: 20 }
          }
          transition={{ duration: 0.3 }}
        >
          <ArrowIcon src={rightArrowIcon} alt="next" />
        </Arrow>
      )}

      {Array.isArray(children) && (
        <DotGroup>
          {children.map((_, index) => (
            <Dot
              key={index}
              src={activeIndex === index ? activeDotIcon : normalDotIcon}
              aria-label={`Slide ${index + 1} ${
                activeIndex === index ? "selected" : ""
              }`}
            />
          ))}
        </DotGroup>
      )}
    </Container>
  );
};
