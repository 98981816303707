import { t } from "i18next";

export class KontosError extends Error {
  constructor(
    name: EbErrorType | WcErrorType | ConnectErrorType,
    message: string
  ) {
    super(message);
    this.name = name;
  }
}

export enum EbErrorType {
  NoCli = "no-cli",
  NoEbAccount = "no-eb-account",
  NoEbInviter = "no-eb-inviter",
}

export enum WcErrorType {
  NoSessionProposal = "no-session-proposal",
  SessionProposalExpired = "session-proposal-expired",
  ApproveSessionFail = "approve-session-fail",
  NoCli = "no-cli",
  InvalidMethod = "invalid-method",
}

export enum ConnectErrorType {
  NoMsgSource = "no-msg-source",
  NoOpenerHost = "no-opener-host",
}

export const ebError = {
  noCli: new KontosError(EbErrorType.NoCli, "no cli"),
  noEbAccount: new KontosError(
    EbErrorType.NoEbAccount,
    t("Please wait for the account information to load completely")
  ),
  noEbInviter: new KontosError(
    EbErrorType.NoEbAccount,
    t("Missing inviter information")
  ),
};

export const wcError = {
  wcNoSessionProposal: new KontosError(
    WcErrorType.NoSessionProposal,
    t("Network abnormality, please try again later")
  ),
  wcSessionProposalExpired: new KontosError(
    WcErrorType.SessionProposalExpired,
    t("Link has expired")
  ),
  approveSessionFail: new KontosError(
    WcErrorType.ApproveSessionFail,
    "Connection failed, please try again later"
  ),
  noCli: new KontosError(WcErrorType.NoCli, "no cli"),
  invalidMethod: new KontosError(
    WcErrorType.InvalidMethod,
    t("Currently, we does not support this feature")
  ),
};

export const connectError = {
  noMsgSource: new KontosError(
    ConnectErrorType.NoMsgSource,
    "Unrecognized communication source"
  ),
  noOpenerHost: new KontosError(
    ConnectErrorType.NoOpenerHost,
    t("Unrecognized host source")
  ),
};
